import { Trans } from '@lingui/react/macro';
import { ReactNode, useCallback, useEffect } from 'react';
import { useBeforeUnload, useBlocker } from 'react-router';

import { Button } from '@/components/buttons';
import { Modal } from '@/components/modals';
import { Title } from '@/components/typography';

import styles from './Prompt.module.scss';

type Props = {
  message: string;
  shouldPreventNavigation: boolean;
  stayText?: ReactNode;
  leaveText?: ReactNode;
  onStay?: () => void;
  onLeave?: () => void;
  onBeforeUnload?: () => void;
};

const Prompt = ({
  message,
  shouldPreventNavigation,
  stayText = <Trans>Keep Editing</Trans>,
  leaveText = <Trans>Exit</Trans>,
  onStay,
  onLeave,
  onBeforeUnload,
}: Props) => {
  const blocker = useBlocker(shouldPreventNavigation);

  useEffect(() => {
    const handleBeforeUnload = (e: BeforeUnloadEvent) => {
      if (shouldPreventNavigation) {
        e.preventDefault();
        e.returnValue = message;
      }

      onBeforeUnload?.();
    };

    window.addEventListener('beforeunload', handleBeforeUnload);
    return () => window.removeEventListener('beforeunload', handleBeforeUnload);
  });

  const handleStay = useCallback(() => {
    onStay?.();
    blocker.reset?.();
  }, [blocker]);
  const handleLeave = useCallback(() => {
    onLeave?.();
    blocker.proceed?.();
  }, [blocker]);

  useBeforeUnload(handleStay);

  return (
    <Modal
      className={styles.modal}
      open={blocker.state === 'blocked'}
      onCancel={handleStay}
      footer={[
        <Button
          key="cancelButton"
          color="black"
          variant="secondary"
          size="large"
          onClick={handleLeave}
        >
          {leaveText}
        </Button>,
        <Button key="okButton" color="black" variant="primary" size="large" onClick={handleStay}>
          {stayText}
        </Button>,
      ]}
    >
      <Title level={4} weight="light">
        {message}
      </Title>
    </Modal>
  );
};

export default Prompt;
