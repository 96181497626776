import { Trans } from '@lingui/react/macro';

import { CampaignType } from '@/api/campaign';
import { Flex } from '@/components/Flex';
import { Tag } from '@/components/typography';
import { getChannelColor } from '@/constants/colors/channel';

type Props = {
  campaigns?: CampaignType[];
};

const CampaignTagList = ({ campaigns }: Props) => {
  return (
    <Flex justify="end" gap="small" wrap>
      {campaigns?.map((campaign) => {
        const channelColor = getChannelColor(campaign.vendor.channel, 0.5);

        return (
          <Tag key={campaign.id} size="medium" variant="solid" color={channelColor} noMargin>
            <Trans>
              {campaign.name} - {campaign.vendor.name} - {campaign.vendor.channel?.name}
            </Trans>
          </Tag>
        );
      })}
    </Flex>
  );
};

export default CampaignTagList;
