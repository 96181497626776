import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { EventLogType } from '@/api/eventLog';
import { CopyToClipboardText } from '@/components/Form';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType, FilterDropdown } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { USDateTimeViewFormat } from '@/constants/formats';
import SolGraphQLError from '@/error/SolGraphQLError';
import { truncateMiddle } from '@/helper/string-util';
import { ROUTES } from '@/router';

import EventTypeFilter from './EventTypeFilter';

type Props = {
  data?: EventLogType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: SolGraphQLError;
};

const EventLogTable = ({ data, totalResults, isLoading, error }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const sortFields = ROUTES.eventLog.searchParams.sortBy.values;

  const columns = useMemo<ColumnsType<EventLogType>>(
    () => [
      {
        title: t`Source`,
        key: 'sourceString',
        width: 200,
        sorter: sortFields.indexOf('sourceString') >= 0,
        render: (text, record) => record.source,
      },
      {
        title: t`Type`,
        key: 'typeString',
        width: 100,
        sorter: sortFields.indexOf('typeString') >= 0,
        filterDropdown: (props) => (
          <FilterDropdown {...props}>
            <EventTypeFilter startDate={startDate} endDate={endDate} />
          </FilterDropdown>
        ),
        render: (text, record) => record.type,
      },
      {
        title: t`Details`,
        key: 'detailsString',
        render: (text, record) => record.details,
      },
      {
        title: t`Log ID`,
        key: 'id',
        align: 'right',
        width: 200,
        render: (text) => (
          <CopyToClipboardText variant="caption1" value={text}>
            {truncateMiddle(text, 24)}
          </CopyToClipboardText>
        ),
      },
      {
        title: t`Date/Time`,
        key: 'occurredAt',
        align: 'right',
        sorter: sortFields.indexOf('occurredAt') >= 0,
        width: 140,
        render: (text, record) => moment(record.date).format(USDateTimeViewFormat),
      },
    ],
    [startDate, endDate],
  );

  return (
    <InstantSearchTable<EventLogType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No event log activity found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      sticky
      scroll={{ x: 850 }}
    />
  );
};

export default EventLogTable;
