import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Row, Space } from 'antd';
import { useState } from 'react';
import { useNavigate } from 'react-router';

import { mutatePixelCreate } from '@/api/pixel';
import Form from '@/components/Form';
import Prompt from '@/components/Prompt';
import { ReturnToLink } from '@/components/ReturnToLink';
import { Button } from '@/components/buttons';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import useErrorDisplay from '@/error/useErrorDisplay';
import { ROUTES } from '@/router';

import PixelForm, { FORM_FIELD, FormType } from '../PixelForm';

const PixelCreate = () => {
  const navigate = useNavigate();
  const [isCreatingPixel, setIsCreatingPixel] = useState<boolean>();
  const [form] = Form.useForm();
  const code = Form.useWatch(FORM_FIELD.CODE, form);
  const displayErrors = useErrorDisplay();

  const handleReturnToListView = () => {
    navigate(ROUTES.pixels.path);
  };

  const handleFinish = async (values: FormType) => {
    setIsCreatingPixel(true);

    try {
      const { audience, channel, vendor, ...rest } = values;

      await mutatePixelCreate({
        ...rest,
        channel_id: channel.id,
        vendor_id: vendor.id,
        audience_id: audience.id,
      });

      navigate(ROUTES.pixels.path, {
        state: {
          successMessage: t`Pixel was successfully created.`,
        },
      });
    } catch (e) {
      displayErrors({ error: e, form });
    }

    setIsCreatingPixel(false);
  };

  return (
    <Page title={t`Create New Pixel`}>
      <Form form={form} validateTrigger="onBlur" onFinish={handleFinish}>
        <Prompt
          message={t`Are you sure you want to exit without publishing your new Pixel?`}
          shouldPreventNavigation={code != null && !isCreatingPixel}
        />
        <Space size="large" direction="vertical" style={{ width: '100%' }}>
          <Row align="middle" justify="space-between">
            <ReturnToLink route={ROUTES.pixels.path} routeName={t`Pixel Overview`} />
            <Space size={16}>
              <Button color="black" variant="secondary" onClick={handleReturnToListView}>
                <Trans>Cancel</Trans>
              </Button>
              <Button
                color="green"
                variant="primary"
                isDisabled={isCreatingPixel}
                isLoading={isCreatingPixel}
                type="submit"
              >
                <Trans>Publish</Trans>
              </Button>
            </Space>
          </Row>
          <Panel size="L" title={<Trans>Create New Pixel</Trans>}>
            <PixelForm disabled={isCreatingPixel} />
          </Panel>
        </Space>
      </Form>
    </Page>
  );
};

export default PixelCreate;
