import { t } from '@lingui/core/macro';

import { INTEGRATION_PROVIDER_ID } from '@/api/integration';
import AdrollLogo from '@/assets/svg/integration/adroll.svg?react';
import DemandbaseLogo from '@/assets/svg/integration/demandbase.svg?react';
import FacebookAdsLogo from '@/assets/svg/integration/facebook-ads.svg?react';
import GoogleAdsLogo from '@/assets/svg/integration/google-ads.svg?react';
import LinkedInLogo from '@/assets/svg/integration/linkedin.svg?react';
import MicrosoftAdsLogo from '@/assets/svg/integration/microsoft-ads.svg?react';
import RedditLogo from '@/assets/svg/integration/reddit.svg?react';
import RollworksLogo from '@/assets/svg/integration/rollworks.svg?react';
import SalesforceLogo from '@/assets/svg/integration/salesforce.svg?react';
import TikTokLogo from '@/assets/svg/integration/tiktok.svg?react';
import XAdsLogo from '@/assets/svg/integration/x-ads.svg?react';

export const getIsRecognizedProvider = (providerId?: INTEGRATION_PROVIDER_ID) => {
  return providerId && Object.values(INTEGRATION_PROVIDER_ID).includes(providerId);
};

export const getProviderShortDescriptionById = (providerId?: INTEGRATION_PROVIDER_ID) => {
  if (!providerId) {
    return null;
  }

  switch (providerId) {
    case INTEGRATION_PROVIDER_ID.SALESFORCE:
      return t`Sync your Opportunities and Accounts from Salesforce.`;
    case INTEGRATION_PROVIDER_ID.LINKEDIN:
      return t`Connect your ad spend and Linkedin metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.GOOGLE_ADS:
      return t`Connect your ad spend and Google Ads metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.FACEBOOK_ADS:
      return t`Connect your Facebook ad spend and ad metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.BING_ADS:
      return t`Connect your ad spend and Microsoft Ad metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.X_ADS:
      return t`Connect your ad spend and X Ads metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.ADROLL_ADS:
      return t`Connect your ad spend and Adroll performance metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.REDDIT_ADS:
      return t`Connect your ad spend and Reddit Ads metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.ROLLWORKS_ADS:
      return t`Connect your ad spend and Rollworks performance metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.TIKTOK_ADS:
      return t`Connect your ad spend and Tiktok Ads metrics for analysis.`;
    case INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS:
      return t`Connect Demandbase advertising spend results to Channel99.`;
    default:
      return t`Unknown provider`;
  }
};

export const getProviderLongDescriptionById = (providerId?: INTEGRATION_PROVIDER_ID) => {
  if (!providerId) {
    return null;
  }

  switch (providerId) {
    case INTEGRATION_PROVIDER_ID.SALESFORCE:
      return t`Linking your Salesforce account to Channel99 provides valuable insights into the effectiveness of your channels and vendors by tying your investments to your opportunities. This means that you can track the success of your marketing efforts all the way from first engagement to closed deals, allowing you to understand which channels and vendors are most effective at driving revenue.`;
    case INTEGRATION_PROVIDER_ID.LINKEDIN:
      return t`Linking your LinkedIn account to Channel99 provides valuable insights into the effectiveness of LinkedIn by automating Vendor Spend so you always have accurate and up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.GOOGLE_ADS:
      return t`Linking your Google Ads account to Channel99 provides valuable insights into the effectiveness of Google Display and Paid Search by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.FACEBOOK_ADS:
      return t`Linking your Facebook ads account to Channel99 provides valuable insights into the effectiveness of Facebook Paid Social ads by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.BING_ADS:
      return t`Linking your Microsoft Ads account to Channel99 provides valuable insights into the effectiveness of Microsoft Ads Display and Paid Search by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.X_ADS:
      return t`Linking your X Ads account (formerly Twitter) to Channel99 provides valuable insights into the effectiveness of X Paid Social by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.ADROLL_ADS:
      return t`Linking your AdRoll Ads account to Channel99 provides valuable insights into the effectiveness of Adroll Ads Display by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.REDDIT_ADS:
      return t`Linking your Reddit Ads account to Channel99 provides valuable insights into the effectiveness of Reddit Paid Social by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.ROLLWORKS_ADS:
      return t`Linking your Rollworks Ads account to Channel99 provides valuable insights into the effectiveness of Rollworks Ads Display by automating Vendor Spend and delivery metrics for up to date ROI insights. `;
    case INTEGRATION_PROVIDER_ID.TIKTOK_ADS:
      return t`Linking your TikTok Ads account to Channel99 provides valuable insights into the effectiveness of TikTok Paid Social by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
    case INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS:
      return t`Linking your Demandbase Ads account to Channel99 provides valuable insights into the effectiveness of Demandbase Ads Display by automating Vendor Spend and delivery metrics for up to date ROI insights.`;
  }
};

export const getProviderLogoById = (providerId?: INTEGRATION_PROVIDER_ID) => {
  if (!providerId) {
    return null;
  }

  switch (providerId) {
    case INTEGRATION_PROVIDER_ID.SALESFORCE:
      return <SalesforceLogo />;
    case INTEGRATION_PROVIDER_ID.LINKEDIN:
      return <LinkedInLogo />;
    case INTEGRATION_PROVIDER_ID.GOOGLE_ADS:
      return <GoogleAdsLogo />;
    case INTEGRATION_PROVIDER_ID.FACEBOOK_ADS:
      return <FacebookAdsLogo />;
    case INTEGRATION_PROVIDER_ID.BING_ADS:
      return <MicrosoftAdsLogo />;
    case INTEGRATION_PROVIDER_ID.X_ADS:
      return <XAdsLogo />;
    case INTEGRATION_PROVIDER_ID.ADROLL_ADS:
      return <AdrollLogo />;
    case INTEGRATION_PROVIDER_ID.REDDIT_ADS:
      return <RedditLogo />;
    case INTEGRATION_PROVIDER_ID.ROLLWORKS_ADS:
      return <RollworksLogo />;
    case INTEGRATION_PROVIDER_ID.TIKTOK_ADS:
      return <TikTokLogo />;
    case INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS:
      return <DemandbaseLogo />;
    default:
      return t`No logo`;
  }
};

export const getProviderHelpLinkById = (providerId?: INTEGRATION_PROVIDER_ID) => {
  if (!providerId) {
    return 'https://support.channel99.com/';
  }

  switch (providerId) {
    case INTEGRATION_PROVIDER_ID.SALESFORCE:
      return 'https://channel99.zendesk.com/hc/en-us/articles/13534333911323';
    case INTEGRATION_PROVIDER_ID.LINKEDIN:
      return 'https://support.channel99.com/hc/en-us/articles/22584548336027';
    case INTEGRATION_PROVIDER_ID.GOOGLE_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117047557659';
    case INTEGRATION_PROVIDER_ID.FACEBOOK_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117057581211';
    case INTEGRATION_PROVIDER_ID.BING_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117020593179';
    case INTEGRATION_PROVIDER_ID.X_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117153757723';
    case INTEGRATION_PROVIDER_ID.ADROLL_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117186978331';
    case INTEGRATION_PROVIDER_ID.REDDIT_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117159512859';
    case INTEGRATION_PROVIDER_ID.TIKTOK_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/25117146326427';
    case INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS:
      return 'https://support.channel99.com/hc/en-us/articles/30509762129051';
    default:
      return 'https://support.channel99.com/';
  }
};
