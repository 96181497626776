import { msg } from '@lingui/core/macro';

import { ErrorByCodeType } from './types';

export enum INSTANCE_ERROR_CODES {
  BAD_DOMAIN = 'err:sol.instance.bad-domain',
  ADD_ADMIN_ROLE_FAILURE = 'err:sol.instance.add-admin-role-failure',
  INTERMEDIATE_SESSION_EXPIRED = 'err:sol.sign-up.intermediate-session',
  DUPLICATE_ORG = 'err:sol.sign-up.duplicate-org',
  MISSING_INSTANCE_TAM_DETAILS = 'err:sol.instance.missing-tam-details',
}

const INTERNAL_INSTANCE_FAILURE = msg`An error occurred while creating your account. Please contact support for assistance.`;

export const INSTANCE_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [INSTANCE_ERROR_CODES.BAD_DOMAIN]: msg`The domain you supplied does not appear to be properly formatted. Please double-check if it is valid and try again.`,
  [INSTANCE_ERROR_CODES.ADD_ADMIN_ROLE_FAILURE]: INTERNAL_INSTANCE_FAILURE,
  [INSTANCE_ERROR_CODES.INTERMEDIATE_SESSION_EXPIRED]: msg`We could not create your account because too much time has passed since the Sign Up flow started or the session is invalid.`,
  [INSTANCE_ERROR_CODES.DUPLICATE_ORG]: msg`Great news, your organization has already created an account with Channel99! Please contact your administrator to be added to it or contact support@channel99.com if you believe this is an error.`,
  [INSTANCE_ERROR_CODES.MISSING_INSTANCE_TAM_DETAILS]: msg`Updating TAM requires at least one region, revenue range, and industry setting.`,
};
