import { Trans } from '@lingui/react/macro';

import { Button } from '@/components/buttons';

import styles from './CompanySettingsActionBar.module.scss';

type Props = {
  isEditMode: boolean;
  isSaveEnabled: boolean;
  isSaving: boolean;
  onEditModeChange: (isEditMode: boolean) => void;
  onCancel: () => void;
};

const CompanySettingsActionBar = ({
  isEditMode,
  isSaveEnabled,
  isSaving,
  onEditModeChange,
  onCancel,
}: Props) => {
  return (
    <div className={styles.actionBar}>
      {isEditMode ? (
        <>
          <Button
            color="black"
            variant="secondary"
            isDisabled={isSaving}
            onClick={() => onCancel()}
          >
            <Trans>Cancel</Trans>
          </Button>
          <Button
            color="green"
            variant="primary"
            isLoading={isSaving}
            isDisabled={!isSaveEnabled}
            type="submit"
          >
            <Trans>Save Settings</Trans>
          </Button>
        </>
      ) : (
        <Button color="black" variant="secondary" onClick={() => onEditModeChange(true)}>
          <Trans>Edit Settings</Trans>
        </Button>
      )}
    </div>
  );
};

export default CompanySettingsActionBar;
