import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useState } from 'react';

import { useUserList } from '@/api/user';
import { InstantSearchSortOrder, QueryState, SolQueryParamsNew } from '@/components/InstantSearch';
import InstantSearch from '@/components/InstantSearch/InstantSearch';
import { getSolQueryParamsNewFromQueryState } from '@/components/InstantSearch/util/search-util';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';

import UsersListHeader from './UsersListHeader';
import UsersListTable from './UsersListTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  size: 20,
  sortBy: 'name',
  sortOrder: InstantSearchSortOrder.ASC,
};

const UsersList = () => {
  const [tableParams, setTableParams] = useState<SolQueryParamsNew>();
  const { userListData, totalResults, isLoading, error } = useUserList(tableParams);

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsNewFromQueryState(queryState);
    setTableParams(tableParams);
  };

  return (
    <Page title={t`Settings`} pageName={t`Users & Roles`}>
      <InstantSearch
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={handleQueryStateChange}
      >
        <Space direction="vertical" size="large">
          <UsersListHeader />
          <Panel size="L" title={<Trans>Account Users</Trans>} isFullHeight>
            <UsersListTable
              data={userListData}
              totalResults={totalResults}
              isLoading={isLoading}
              error={error}
            />
          </Panel>
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default UsersList;
