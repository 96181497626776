import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useEffect } from 'react';

import { FullPage } from '@/components/page';
import { Link, Text } from '@/components/typography';
import AuthError from '@/error/AuthError';
import useLocationState from '@/hooks/useLocationState';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import DiscoveryAuthenticate from './DiscoveryAuthenticate';
import LoginForm from './LoginForm';
import SignInPanel from './SignInPanel';
import TermsOfUseLink from './TermsOfUseLink';

const SignIn = () => {
  const { error } = useLocationState<{ error?: AuthError }>();
  const { pushNotification } = useNotification();

  useEffect(() => {
    if (error) {
      pushNotification({ type: 'error', message: error.errorMessage });
    }
  }, [error]);

  return (
    <FullPage title={t`Sign In`}>
      <DiscoveryAuthenticate>
        <SignInPanel
          title={t`Sign In To Channel99`}
          footer={
            <>
              <Text variant="caption1" color="grey">
                <Trans>
                  Don't have an account?{' '}
                  <Link
                    variant="caption1"
                    color="aqua"
                    to={ROUTES.signUp.path}
                  >{t`Create an Account`}</Link>
                </Trans>
              </Text>
              <Text variant="caption1" color="grey">
                <Trans>
                  By continuing to sign in I agree to the <TermsOfUseLink />
                </Trans>
              </Text>
            </>
          }
          showGap
        >
          <LoginForm type="sign-in" />
        </SignInPanel>
      </DiscoveryAuthenticate>
    </FullPage>
  );
};

export default SignIn;
