import { t } from '@lingui/core/macro';

import { useHealthCenter } from '@/api/healthCenter';
import { INTEGRATION_PROVIDER_ID } from '@/api/integration';
import BarChartIcon from '@/assets/svg/bar-chart.svg?react';
import LinkIcon from '@/assets/svg/bx-link.svg?react';
import CodeIcon from '@/assets/svg/code.svg?react';
import DollarIcon from '@/assets/svg/dollar.svg?react';
import MappingArrowIcon from '@/assets/svg/mapping-arrow.svg?react';
import PixelIcon from '@/assets/svg/pixel.svg?react';
import TargetIcon from '@/assets/svg/target.svg?react';
import UserAddIcon from '@/assets/svg/user-add.svg?react';
import UserCheckIcon from '@/assets/svg/user-check.svg?react';
import LoadingIndicator from '@/components/LoadingIndicator';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import { formatDate, formatDateFromNow } from '@/helper/dateHelper';
import { ROUTES } from '@/router';

import Fireworks from './ConfettiCanvas';
import styles from './HealthCenter.module.scss';
import HealthPod from './HealthPod';
import HealthPodDetail from './HealthPodDetails';
import HealthPodUser from './HealthPodUser';
import OnboardingProgressBar from './OnboardingProgressBar';

const HealthCenter = () => {
  const { healthCenterData, isLoading } = useHealthCenter();

  return (
    <>
      <Page title={t`Health Center`} className={styles.page}>
        <Panel className={styles.panel} title={t`Onboarding Progress and Status`} isRounded>
          <OnboardingProgressBar
            isLoading={isLoading && !healthCenterData?.onboardingProgress}
            value={healthCenterData?.onboardingProgress}
          />
        </Panel>
        <div className={styles.podContainer}>
          {isLoading ? (
            <LoadingIndicator size="large" />
          ) : (
            healthCenterData && (
              <>
                <HealthPod
                  data={healthCenterData?.tag}
                  icon={<CodeIcon />}
                  title={t`Channel99 Tag`}
                  titleInfoPopover={{
                    title: t`Channel99 Tag`,
                    body: t`Channel99 Tag will indicate "Incomplete" if we do not receive a pageview within 24 hours.`,
                  }}
                  instructions={t`Place Channel99 Tag on your website`}
                  link={ROUTES.companySettings.path}
                >
                  <HealthPodDetail label={t`Installed`}>
                    {formatDate(healthCenterData?.tag?.tagInstalledAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                  <HealthPodDetail label={t`Last Data Received`}>
                    {formatDate(healthCenterData?.tag?.lastEventAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.tam}
                  icon={<TargetIcon />}
                  title={t`Total Addressable Market`}
                  instructions={t`Define your organization's TAM (Total Addressable Market)`}
                  link={ROUTES.companySettings.path}
                >
                  <HealthPodDetail label={t`Last Updated`}>
                    {formatDate(healthCenterData?.tam?.updatedAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                  <HealthPodDetail label={t`By`}>
                    <HealthPodUser user={healthCenterData?.tam?.updatedBy} />
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.salesforce}
                  icon={<BarChartIcon />}
                  title={t`Salesforce`}
                  instructions={t`Connect your Salesforce for pipeline data and audience creation`}
                  link={ROUTES.integrationById(INTEGRATION_PROVIDER_ID.SALESFORCE)}
                >
                  <HealthPodDetail label={t`Last Sync`}>
                    {formatDateFromNow(healthCenterData?.salesforce?.lastSynced)}
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={{ label: '', isAvailable: true, isComplete: false, isFree: true }}
                  title={t`Ad Vendors`}
                  icon={<LinkIcon />}
                  instructions={t`Automated Spend and Metrics from Ad Vendors`}
                  link={ROUTES.integrations.path}
                />
                <HealthPod
                  data={healthCenterData?.channelMapping}
                  icon={<MappingArrowIcon />}
                  title={t`Channel Mapping`}
                  instructions={t`Review Channel Mapping`}
                  link={ROUTES.configureChannels.path}
                >
                  <HealthPodDetail label={t`Last Update`}>
                    {formatDate(healthCenterData?.channelMapping?.updatedAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                  <HealthPodDetail label={t`By`}>
                    <HealthPodUser user={healthCenterData?.channelMapping?.updatedBy} />
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.vendorSpend}
                  icon={<DollarIcon />}
                  title={t`Vendor Spend`}
                  instructions={t`Keep Vendor Spend up to date`}
                  link={ROUTES.configureVendors.path}
                >
                  <HealthPodDetail label={t`Spend Input Through`}>
                    {formatDate(healthCenterData?.vendorSpend?.spendInputThroughAt, 'MM/YYYY')}
                  </HealthPodDetail>
                  {healthCenterData?.vendorSpend?.lastUpdatedBy != null && (
                    <HealthPodDetail label={t`By`}>
                      <HealthPodUser user={healthCenterData?.vendorSpend?.lastUpdatedBy} />
                    </HealthPodDetail>
                  )}
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.customAudience}
                  icon={<UserCheckIcon />}
                  title={t`Custom Audience`}
                  instructions={t`Create Custom Audience for more precise insights`}
                  link={ROUTES.audiences.path}
                >
                  <HealthPodDetail label={t`Last Created`}>
                    {formatDate(healthCenterData?.customAudience?.createdAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                  <HealthPodDetail label={t`By`}>
                    <HealthPodUser user={healthCenterData?.customAudience?.createdBy} />
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.userAdmin}
                  icon={<UserAddIcon />}
                  title={t`Users and Admins`}
                  instructions={t`Invite Users and Admins to the C99 Platform`}
                  link={ROUTES.users.path}
                >
                  <HealthPodDetail label={t`Last User Added`}>
                    {formatDate(healthCenterData?.userAdmin?.lastUserAddedAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                </HealthPod>
                <HealthPod
                  data={healthCenterData?.universalPixel}
                  icon={<PixelIcon />}
                  title={t`C99 Universal Pixel`}
                  instructions={t`Publish and place your C99 Universal Pixel`}
                  link={ROUTES.pixels.path}
                >
                  <HealthPodDetail label={t`Created On`}>
                    {formatDate(healthCenterData?.universalPixel?.lastCreatedAt, 'MM/DD/YYYY')}
                  </HealthPodDetail>
                  <HealthPodDetail label={t`By`}>
                    <HealthPodUser user={healthCenterData?.universalPixel?.createdBy} />
                  </HealthPodDetail>
                </HealthPod>
              </>
            )
          )}
        </div>
      </Page>
      {healthCenterData?.onboardingProgress === 1 && <Fireworks />}
    </>
  );
};

export default HealthCenter;
