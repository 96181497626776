import { t } from '@lingui/core/macro';
import { Col, Row } from 'antd';
import { useEffect, useMemo, useRef } from 'react';
import { useSearchParams } from 'react-router';

import { AudienceGroupedOptionType } from '@/api/audience';
import { PixelType } from '@/api/pixel';
import { Flex } from '@/components/Flex';
import PageSpinner from '@/components/PageSpinner';
import { PageAudienceFilter, PageDateRangeFilter, usePageFilterContext } from '@/components/page';

import PixelDetailKPIMetrics from './PixelDetailKPIMetrics';
import PixelImpressionTrendChart from './PixelImpressionTrendChart';
import PixelOpportunityInfluenceChartTable from './PixelOpportunityInfluenceChartTable';
import PixelReachAndEngagementTrendChart from './PixelReachAndEngagementTrendChart';
import PixelTargetingEfficiencyChart from './PixelTargetingEfficiencyChart';

const GROUPED_AUDIENCE_ID = 'grouped_audience';

type Props = {
  pixelIds?: string[];
  pixels?: PixelType[];
  isLoading: boolean;
};

const PixelDetailContent = ({ pixelIds, pixels, isLoading }: Props) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { pageFilters } = usePageFilterContext();
  const hadAudience = useRef(false);
  const isGrouped = !!(pixelIds && pixelIds?.length > 1);

  useEffect(() => {
    if (pixels?.length && !searchParams.has('audience')) {
      // Put the default audience into the url if it's not already there
      if (isGrouped) {
        searchParams.set('audience', GROUPED_AUDIENCE_ID);
      } else {
        const audienceId = pixels[0].audience?.id;
        searchParams.set('audience', audienceId);
      }
      setSearchParams(searchParams, { replace: true });
    }
  }, [pixels, isGrouped]);

  const groupedAudience = useMemo<AudienceGroupedOptionType>(() => {
    const audienceIds = [...new Set(pixels?.map((pixel) => pixel.audience?.id).filter(Boolean))];
    return {
      id: GROUPED_AUDIENCE_ID,
      name: audienceIds.length ? t`Grouped Audience (${audienceIds.length})` : t`Grouped Audience`,
      audienceIds,
    };
  }, [pixels]);

  const audience = useMemo<AudienceGroupedOptionType | undefined>(() => {
    const aud = pageFilters.audience;

    if (aud) {
      if (aud.id === GROUPED_AUDIENCE_ID) {
        return groupedAudience;
      } else {
        return {
          ...aud,
          audienceIds: [aud.id],
        };
      }
    }

    return undefined;
  }, [pageFilters.audience?.id, groupedAudience]);

  const prependedAudienceOptions = useMemo(
    () =>
      isGrouped ? [{ id: GROUPED_AUDIENCE_ID, name: groupedAudience?.name || '' }] : undefined,
    [isGrouped, audience],
  );

  // Did we load an audience before? By default we should load an audience before we make the first
  // query. Then, if audience is null, it's because the user specifically removed it. This check
  // makes sure we don't quickly load null audience queries before audienceId is retrieved.
  if (audience) {
    hadAudience.current = true;
  }

  return (
    <>
      <Flex justify="space-between">
        <PageDateRangeFilter />
        <PageAudienceFilter
          isDisabled={!hadAudience.current}
          prependedOptions={prependedAudienceOptions}
        />
      </Flex>
      {hadAudience.current ? (
        <>
          <PixelDetailKPIMetrics pixelIds={pixelIds} audience={audience} isLoading={isLoading} />
          <PixelReachAndEngagementTrendChart
            pixelIds={pixelIds}
            audience={audience}
            isLoading={isLoading}
          />
          <Row gutter={[24, 24]}>
            <Col md={10} sm={24} xs={24}>
              <PixelImpressionTrendChart
                pixelIds={pixelIds}
                audience={audience}
                isLoading={isLoading}
              />
            </Col>
            <Col md={14} sm={24} xs={24}>
              <PixelTargetingEfficiencyChart
                pixelIds={pixelIds}
                audience={audience}
                isLoading={isLoading}
              />
            </Col>
          </Row>
          <PixelOpportunityInfluenceChartTable
            pixelIds={pixelIds}
            audience={audience}
            isLoading={isLoading}
          />
        </>
      ) : (
        <PageSpinner />
      )}
    </>
  );
};

export default PixelDetailContent;
