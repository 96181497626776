import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import { PipelineReturnOnSpendDataType, usePipelineReturnOnSpend } from '@/api/pipeline';
import Table, { ColumnsType } from '@/components/Table';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { Link } from '@/components/typography';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

const PipelineInfluencedTable = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const { pipelineReturnOnSpend, isLoading, error } = usePipelineReturnOnSpend(startDate, endDate);

  const columns = useMemo<ColumnsType<PipelineReturnOnSpendDataType>>(
    () => [
      {
        title: t`Channel`,
        key: 'field.channel.name',
        fixed: 'left',
        sorter: (a, b) => a.channel.name.toLowerCase().localeCompare(b.channel.name.toLowerCase()),
        render: (text, field) => (
          <Link variant="caption1" to={ROUTES.channelById(field.channel.id)}>
            {field.channel.name}
          </Link>
        ),
      },
      {
        title: t`Pipeline Influenced`,
        key: 'pipelineAmount',
        sorter: (a, b) => a.pipelineAmount - b.pipelineAmount,
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
        defaultSortOrder: 'descend',
      },
      {
        title: t`Spend`,
        key: 'spend',
        sorter: (a, b) => a.spend - b.spend,
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ],
    [],
  );

  return (
    <Panel
      title={<Trans>Pipeline Influenced and Spend</Trans>}
      size="L"
      noPadding
      style={{ height: 390 }}
    >
      <div style={{ padding: 8 }}>
        <Table<PipelineReturnOnSpendDataType>
          loading={isLoading}
          error={error}
          columns={columns}
          dataSource={pipelineReturnOnSpend}
          emptyMessage={<Trans>No data found</Trans>}
          pagination={false}
          rowKey={(record) => record.channel.id}
          sticky
        />
      </div>
    </Panel>
  );
};

export default PipelineInfluencedTable;
