import Flare, { Donut, FlareError, Tooltip, TooltipLinkFunction } from '@/components/Flare';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data: {
    id: string;
    value?: number;
    valueText?: string;
    secondaryValue?: number;
    seriesName: string;
    color: string;
  }[];
  disabledSeries?: string[];
  metricName: string;
  titleValue?: string;
  valueLink?: TooltipLinkFunction;
  tooltipTitle?: string;
  isLoading?: boolean;
  error: FlareError;
};

const MultiDonut = ({
  data,
  disabledSeries,
  metricName,
  titleValue,
  valueLink,
  tooltipTitle,
  isLoading,
  error,
}: Props) => {
  const seriesData = [
    {
      id: metricName,
      name: metricName,
      data: data.map((d) => ({
        id: d.id,
        name: d.seriesName,
        data: d.value,
        custom: { secondary: d.secondaryValue, valueText: d.valueText },
      })),
    },
  ];

  return (
    <Flare
      data={seriesData}
      title={`<b>${titleValue}</b><br />${metricName}`}
      width="auto"
      height="auto"
      colors={data.map((d) => d.color)}
      noPadding
      isLoading={isLoading}
      error={error}
      disabledSeriesIds={disabledSeries}
    >
      <Donut y="data" hideDataLabels noBorder noHoverEffect />
      <Tooltip
        titleFormat={(point) => (tooltipTitle ? tooltipTitle : point?.key)}
        rowLabelFormat={(point) => point?.key}
        rowValueFormat={(point) => {
          if (point?.custom?.valueText) {
            return point.custom.valueText;
          }
          return numberFormat(point?.y);
        }}
        rowSecondaryValueFormat={(point) =>
          point?.custom?.secondary &&
          point.custom.secondary > 0 &&
          numberFormat(point.custom.secondary, { isPercent: true, precision: 1 })
        }
        valueLink={valueLink}
        showTotalRow
        unstable_hasRouterContext={false}
        shared
      />
    </Flare>
  );
};

export default MultiDonut;
