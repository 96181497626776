import { Trans } from '@lingui/react/macro';

import { Results } from '@/components/Results';
import { Button } from '@/components/buttons';
import { Link } from '@/components/typography';
import { ROUTES } from '@/router';

const CampaignOverviewNoData = () => {
  return (
    <Results
      extra={
        <Button to={ROUTES.integrations.path} color="green">
          <Trans>Connect Vendors</Trans>
        </Button>
      }
      subtitle={
        <Trans>
          Campaign reporting requires a connection to your ad vendors. Please visit our Integrations
          page and connect your vendors.{' '}
          <Link to={ROUTES.integrations.path} color="static-grey">
            Learn More Here.
          </Link>
        </Trans>
      }
    />
  );
};

export default CampaignOverviewNoData;
