import { t } from '@lingui/core/macro';
import { useMemo, useState } from 'react';

import { AudienceOptionType, useAudienceOptions } from '@/api/audience';
import { FilterOperatorMulti, FilterOperatorValue } from '@/components/InstantSearch';
import { InfiniteScrollCategoryFilter } from '@/components/filters';

const PAGE_SIZE = 50;

type Props = {
  value?: FilterOperatorMulti;
  onChange?: (value: FilterOperatorValue) => void;
};

const AudienceFilter = (props: Props) => {
  const [search, setSearch] = useState<string>('');
  const { audienceOptions, error, hasMore, isLoading, isLoadingMore, pageIndex, loadMore } =
    useAudienceOptions({ search, pageSize: PAGE_SIZE });

  const audienceWithBlanksOption = useMemo<AudienceOptionType[] | undefined>(() => {
    if (!audienceOptions) return undefined;

    const extraOptions = [];
    if (search.length === 0) {
      extraOptions.push({ id: '', name: t`(Blanks)` });
    }

    return extraOptions.concat(audienceOptions);
  }, [audienceOptions, search]);

  return (
    <InfiniteScrollCategoryFilter
      {...props}
      options={audienceWithBlanksOption}
      isLoading={isLoading}
      isLoadingMore={isLoadingMore}
      hasMore={hasMore}
      error={error}
      loadMore={() => loadMore(pageIndex + 1)}
      searchText={search}
      onSearch={setSearch}
    />
  );
};

export default AudienceFilter;
