import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import moment from 'moment';

import { WebTrafficCompanySummaryDataType } from '@/api/activity';
import Tooltip from '@/components/Tooltip';
import { Link, Text } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import { ROUTES } from '@/router';

import styles from './CompanyVisitChartCaption.module.scss';

type Props = {
  data?: WebTrafficCompanySummaryDataType;
};

const CompanyVisitChartCaption = ({ data }: Props) => {
  if (!data) {
    return null;
  }

  return (
    <div className={styles.statsCaption}>
      {data.metrics.lastActivityDate && (
        <div className={styles.statsSection}>
          <Text variant="footnote">
            <Trans>Last Visit</Trans>
          </Text>
          <Text variant="caption1">
            {moment(data.metrics.lastActivityDate).format(USDateViewFormat)}
          </Text>
        </div>
      )}
      {data.audiences && data.audiences.length > 0 && (
        <div className={styles.statsSection}>
          <Text variant="footnote">
            <Trans>Audience Membership</Trans>
          </Text>
          {data.audiences?.map((audience) => (
            <Tooltip key={audience.id} body={audience.name} pointerEvents={false}>
              <Link
                className={styles.statsValue}
                variant="caption1"
                to={ROUTES.audienceById(audience.id)}
              >
                {audience.name}
              </Link>
            </Tooltip>
          ))}
        </div>
      )}
      {data.webActivityPages && data.webActivityPages.length > 0 && (
        <div className={styles.statsSection}>
          <Text variant="footnote">
            <Trans>Web Activity</Trans>
          </Text>
          {data.webActivityPages.map((path) => {
            const urlPath = path === '' ? '/' : path;
            return (
              <Tooltip key={urlPath} body={urlPath} pointerEvents={false}>
                <Text className={styles.statsValue} variant="caption1">
                  {urlPath}
                </Text>
              </Tooltip>
            );
          })}
        </div>
      )}
      {data.opportunities && data.opportunities.length > 0 && (
        <div className={styles.statsSection}>
          <Text variant="footnote">
            <Trans>Opportunities</Trans>
          </Text>
          {data.opportunities?.map((opportunity) => (
            <Tooltip
              key={opportunity.id}
              title={t`Opportunities`}
              body={opportunity.name}
              pointerEvents={false}
            >
              {opportunity.isRecentDeal ? (
                <Link
                  className={styles.statsValue}
                  variant="caption1"
                  to={ROUTES.closedWonById(opportunity.id)}
                >
                  {opportunity.name}
                </Link>
              ) : (
                <Text className={styles.statsValue} variant="caption1">
                  {opportunity.name}
                </Text>
              )}
            </Tooltip>
          ))}
        </div>
      )}
    </div>
  );
};

export default CompanyVisitChartCaption;
