import { t } from '@lingui/core/macro';
import classNames from 'classnames';
import { useEffect, useState } from 'react';

import Check from '@/assets/svg/check.svg?react';
import Clone from '@/assets/svg/clone-regular.svg?react';
import Tooltip from '@/components/Tooltip';
import { ActionButton } from '@/components/buttons';

import { TextArea, TextAreaProps } from '../TextArea';
import styles from './CopyToClipboardTextArea.module.scss';

const CopyToClipboardTextArea = ({
  className,
  value,
  isDisabled,
  size = 'large',
  onFocus,
  ...rest
}: TextAreaProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleFocus: TextAreaProps['onFocus'] = (e) => {
    // Select the text so it can be easily copied
    e.target.select();
    onFocus?.(e);
  };

  const handleCopy = () => {
    if (value) {
      setCopied(true);
      navigator.clipboard.writeText(value + '');
    }
  };

  return (
    <div className={styles.wrapper}>
      <TextArea
        {...rest}
        className={classNames(styles.textarea, className)}
        value={value}
        rows={4}
        size={size}
        isDisabled={isDisabled}
        onFocus={handleFocus}
        readOnly
      />
      <Tooltip
        open={copied ? copied : undefined}
        body={isDisabled ? undefined : copied ? t`Copied!` : t`Copy to clipboard`}
      >
        <ActionButton
          className={styles.button}
          color="black"
          aria-label={t`Copy text to clipboard`}
          size={size}
          icon={copied ? <Check /> : <Clone />}
          isDisabled={isDisabled}
          onClick={handleCopy}
        />
      </Tooltip>
    </div>
  );
};

export default CopyToClipboardTextArea;
