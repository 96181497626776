import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router';

import { useChannelsTrafficBreakdown } from '@/api/channel';
import Flare, {
  Axis,
  Legend,
  Tooltip,
  linkifyAxisLabels,
  tooltipPositionBelow,
} from '@/components/Flare';
import AxisLabel from '@/components/Flare/guides/AxisLabel';
import Bar from '@/components/Flare/marks/Bar';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { AUDIENCE_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

const ChannelsSpendPerVisitChart = () => {
  const navigate = useNavigate();
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const {
    channelsTrafficBreakdown: data,
    isLoading,
    error,
  } = useChannelsTrafficBreakdown(startDate, endDate, audience?.id);

  const filteredData = useMemo(
    () =>
      data
        ?.filter(
          (d) => d.spendPerVisit.all > 0 || d.spendPerVisit.audience > 0 || d.spendPerVisit.tam > 0,
        )
        // sort descending by total spend
        .sort((a, b) => b.spendPerVisit.all - a.spendPerVisit.all),
    [data],
  );

  const sanitizedData = useMemo(() => {
    if (!filteredData) {
      return undefined;
    }

    const standardColumns = [
      {
        id: 'visits',
        name: t`Visits`,
        zIndex: 1,
        data: filteredData.map((d) => ({ amount: d.spendPerVisit.all })),
      },
      {
        id: 'tam',
        name: t`TAM`,
        zIndex: 2,
        data: filteredData.map((d) => ({ amount: d.spendPerVisit.tam })),
      },
    ];

    if (audience?.id) {
      return standardColumns.concat({
        id: 'audience',
        name: audience?.name,
        zIndex: 3,
        data: filteredData.map((d) => ({ amount: d.spendPerVisit.audience })),
      });
    } else {
      return standardColumns;
    }
  }, [filteredData, audience]);

  return (
    <Panel
      size="L"
      title={<Trans>$ per Visit</Trans>}
      noPadding
      style={{ height: Math.max(440, (filteredData?.length ?? 0) * 125) }}
      verifyC99Tag
    >
      <Flare
        data={sanitizedData}
        description={t`Amount spent per visit broken down by channel`}
        colors={[VISITS_PRIMARY, TAM_PRIMARY, AUDIENCE_PRIMARY]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend reversed />
        <Bar
          y="amount"
          position="dodge"
          showDataLabels
          dataLabelFormat={(label) =>
            label.y === 0 ? '' : numberFormat(label.y, { isCurrency: true })
          }
          skeletonLoaderOptions={{
            numSeries: audience?.id ? 3 : 2,
            numCategories: 3,
            sortDescending: true,
          }}
        />
        <Axis
          position="right"
          labelFormat={(item) => numberFormat(item.value, { isCurrency: true, precision: 0 })}
          gridlineWidth={0}
          baselineWidth={1}
          tickAmount={5}
        />
        <Axis
          position="bottom"
          categories={filteredData?.map((d) => d.channel.name) || []}
          labelFormat={(item) => {
            const { channel } = data?.[item.pos] || {};
            if (!channel?.id || !channel?.name) {
              return t`Unknown`;
            }
            return renderToStaticMarkup(
              <AxisLabel
                align="right"
                label={channel?.name}
                linkUrl={ROUTES.channelById(channel.id)}
              />,
            );
          }}
          useHTML
          crosshair="rect"
          baselineWidth={0}
        />
        <Tooltip
          titleFormat={(point) => point?.key}
          rowValueFormat={(point) => numberFormat(point?.y, { isCurrency: true })}
          positioner={tooltipPositionBelow}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default ChannelsSpendPerVisitChart;
