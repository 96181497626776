/* eslint-disable lingui/no-unlocalized-strings */
import { useStytchB2BClient } from '@stytch/react/dist/StytchB2BContext-ce9e254c';
import useWebSocket, { ReadyState } from 'react-use-websocket';

import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';

export type WSMessageType = {
  type: 'message' | 'complete';
  content: string;
  sessionId: string;
};

type ClientMessageType = {
  type: 'message';
  token?: string;
  content: string;
  sessionId?: string; // no sessionId will cause the server to create a new session
};

const useAuthWebSocket = (onMessageReceived?: (message: WSMessageType) => void) => {
  const canUseWebsockets = useFeatureFlag(FEATURE_FLAG.websockets);
  const { session } = useStytchB2BClient();

  const jwtToken = session.getTokens()?.session_jwt;
  const socket = useWebSocket<WSMessageType>(
    canUseWebsockets ? import.meta.env.VITE_WEBSOCKET_URL : null,
    {
      protocols: jwtToken,
      onOpen: () => console.log('socket opened'),
      onClose: () => console.log('socket closed'),
      onError: (event) => console.error('socket error:', event),
      shouldReconnect: () => true,
      onMessage: (event: MessageEvent) => {
        const eventData: WSMessageType = JSON.parse(event.data);
        if (eventData) {
          onMessageReceived?.(eventData);
        }
      },
    },
  );

  const handleSendMessage = (message: string, sessionId?: string) => {
    const payload: ClientMessageType = {
      type: 'message',
      token: jwtToken,
      content: message,
      sessionId,
    };

    socket?.sendJsonMessage(payload);
  };

  const connectionStatus = {
    [ReadyState.CONNECTING]: 'Connecting',
    [ReadyState.OPEN]: 'Open',
    [ReadyState.CLOSING]: 'Closing',
    [ReadyState.CLOSED]: 'Closed',
    [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
  }[socket?.readyState ?? ReadyState.UNINSTANTIATED];

  return {
    onSendMessage: handleSendMessage,
    connectionStatus,
  };
};

export default useAuthWebSocket;
