import { Trans } from '@lingui/react/macro';

import { useVendorTrafficOverviewKPIs } from '@/api/vendor';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  vendorId?: string;
  isLoading?: boolean;
};

const VendorDetailKPIMetrics = ({ vendorId, isLoading: isVendorLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const {
    vendorTrafficOverviewKPIs: data,
    isLoading: isVendorTrafficKPIsLoading,
    error,
  } = useVendorTrafficOverviewKPIs(vendorId, startDate, endDate, audience?.id);

  const isLoading = isVendorLoading || isVendorTrafficKPIsLoading;

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>All Visits</Trans>}
        value={data && numberFormat(data.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Total Spend</Trans>}
        value={data && numberFormat(data.spend, { isCurrency: true, precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>All $/Visit</Trans>}
        value={data && numberFormat(data.spendPerVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM Visits</Trans>}
        value={data && numberFormat(data.tamVisits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM $/Visit</Trans>}
        value={data && numberFormat(data.spendPerTamVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default VendorDetailKPIMetrics;
