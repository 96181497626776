import { Trans } from '@lingui/react/macro';

import { Text } from '@/components/typography';

type Props = {
  orgId?: string;
  orgName?: string;
};

const getContent = ({ orgId, orgName }: Props) => {
  if (!orgId && !orgName) {
    return null;
  }

  if (orgId && orgName) {
    return (
      <Trans>
        {orgName} - {orgId}
      </Trans>
    );
  }

  return orgName ?? orgId;
};

const ConnectionOrg = ({ orgId, orgName }: Props) => {
  const content = getContent({ orgId, orgName });

  if (!content) {
    return null;
  }

  return (
    <Text variant="body2" color="grey">
      {content}
    </Text>
  );
};

export default ConnectionOrg;
