import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import LoginForm from '@/app/sign-in/LoginForm';
import SignInPanel from '@/app/sign-in/SignInPanel';
import TermsOfUseLink from '@/app/sign-in/TermsOfUseLink';
import { FullPage } from '@/components/page';
import { Link, Text } from '@/components/typography';
import { ROUTES } from '@/router';

const SignUp = () => {
  return (
    <FullPage title={t`Sign Up`}>
      <SignInPanel
        title={t`Create An Account with Channel99`}
        footer={
          <>
            <Text variant="caption1" color="grey">
              <Trans>
                Already have an account?{' '}
                <Link
                  variant="caption1"
                  color="aqua"
                  to={ROUTES.signIn.path}
                >{t`Sign In Here`}</Link>
              </Trans>
            </Text>
            <Text variant="caption1" color="grey">
              <Trans>
                By continuing to sign up I agree to the <TermsOfUseLink />
              </Trans>
            </Text>
          </>
        }
        showGap
      >
        <LoginForm type="sign-up" />
      </SignInPanel>
    </FullPage>
  );
};

export default SignUp;
