import { t } from '@lingui/core/macro';
import { Navigate, useParams } from 'react-router';

import PageSpinner from '@/components/PageSpinner';
import { FullPage } from '@/components/page';
import { ROUTES } from '@/router';

import Organization404 from '../Organization404';
import { getAllowedAuthMethods } from '../helpers';
import useOrganizationMetadataBySlug from '../useOrganizationMetadataBySlug';
import MagicLinkForm from './MagicLinkForm';

const TenantMagicLinkSignIn = () => {
  const { orgId } = useParams();
  const { data, isLoading, error } = useOrganizationMetadataBySlug({ slug: orgId });

  const authMethods = getAllowedAuthMethods(data);
  const supportsMagicLinkAuth = authMethods?.indexOf('magic_link') >= 0;

  if (data && supportsMagicLinkAuth === false) {
    return <Navigate to={ROUTES.tenantSignInByOrgSlug(data.organization_slug)} replace />;
  }

  return (
    <FullPage title={t`Channel99`}>
      {isLoading && <PageSpinner />}
      {error && <Organization404 />}
      {data && <MagicLinkForm org={data} />}
    </FullPage>
  );
};

export default TenantMagicLinkSignIn;
