import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useState } from 'react';

import { AudienceType, mutateAudienceDelete, useAudienceList } from '@/api/audience';
import InstantSearch, {
  InstantSearchSortOrder,
  QueryState,
  SolQueryParams,
} from '@/components/InstantSearch';
import { getSolQueryParamsFromQueryState } from '@/components/InstantSearch/util/search-util';
import { Page } from '@/components/page';
import { Panel } from '@/components/panels';
import useErrorDisplay from '@/error/useErrorDisplay';
import useDisplayNotificationOnLoad from '@/hooks/useDisplayNotificationOnLoad';
import useEffectOnceWhenDepsExist from '@/hooks/useEffectOnceWhenDepsExist';
import useLocationState from '@/hooks/useLocationState';
import { useNotification } from '@/providers/Notification';

import AudiencesListHeader from './AudiencesListHeader';
import AudiencesListTable from './AudiencesListTable';

const DEFAULT_QUERY_STATE: Partial<QueryState> = {
  sortBy: 'created_at',
  sortOrder: InstantSearchSortOrder.DESC,
};

const AudiencesList = () => {
  const [tableParams, setTableParams] = useState<SolQueryParams>();
  const { successMessage, optimisticUpdate } = useLocationState<{
    successMessage: string;
    optimisticUpdate: AudienceType;
  }>();
  const {
    audienceListData,
    totalResults,
    isLoading,
    error,
    mutate: mutateListData,
  } = useAudienceList(tableParams);
  const displayError = useErrorDisplay();
  const { pushNotification } = useNotification();

  useDisplayNotificationOnLoad({ type: 'success', message: successMessage });
  useEffectOnceWhenDepsExist(() => {
    if (audienceListData && totalResults) {
      mutateListData({
        audiences: {
          get: {
            edges: audienceListData?.map((audience) =>
              audience.id === optimisticUpdate.id
                ? { node: { ...audience, ...optimisticUpdate } }
                : { node: audience },
            ),
            totalEdges: totalResults,
          },
        },
      });
    }
  }, [optimisticUpdate, audienceListData, mutateListData]);

  const sendErrorNotification = (message: string) => {
    pushNotification({ type: 'error', message });
  };

  const removeAudienceFromList = (audienceId: string) => {
    return audienceListData?.filter((audience) => audience.id !== audienceId);
  };

  const handleQueryStateChange = (queryState: Required<QueryState>) => {
    const tableParams = getSolQueryParamsFromQueryState(queryState);
    setTableParams(tableParams);
  };

  const handleAudienceDelete = async (audienceId: string) => {
    try {
      // Optimistic Update on the pagination data
      const filteredList = removeAudienceFromList(audienceId);
      const success = await mutateAudienceDelete(audienceId);

      if (filteredList && totalResults) {
        mutateListData(
          {
            audiences: {
              get: {
                edges: filteredList?.map((audience) => ({ node: audience })),
                totalEdges: totalResults,
              },
            },
          },
          { revalidate: false },
        );
      }

      if (!success) {
        sendErrorNotification(t`Failed to delete the audience. Please try again.`);
        return;
      }
    } catch (e) {
      displayError({ error: e });
    }
  };

  return (
    <Page title={t`Configure - Audiences`}>
      <InstantSearch
        defaultQueryState={DEFAULT_QUERY_STATE}
        onQueryStateChange={handleQueryStateChange}
      >
        <Space direction="vertical" size="large">
          <AudiencesListHeader />
          <Panel
            size="L"
            title={<Trans>Audiences Overview</Trans>}
            data-testid="audiences-table-panel"
            isFullHeight
          >
            <AudiencesListTable
              data={audienceListData}
              totalResults={totalResults}
              isLoading={isLoading}
              error={error}
              onAudienceDelete={handleAudienceDelete}
            />
          </Panel>
        </Space>
      </InstantSearch>
    </Page>
  );
};

export default AudiencesList;
