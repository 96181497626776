import classNames from 'classnames';
import {
  Chart,
  DataLabelsOptions,
  Options,
  Point,
  PointOptionsObject,
  SeriesColumnOptions,
} from 'highcharts';
import { useEffect } from 'react';

import { COLOR_GREEN_400 } from '@/styles/palette';

import { useFlareContext } from '../FlareContext';
import ColumnLoader from '../loaders/ColumnLoader';
import {
  ColumnPoint,
  ColumnSeries,
  FlareChart,
  FlareDataPointOptions,
  FlareOptionsPositionValue,
  FlareSeriesOptions,
  SanitizedFlareProps,
} from '../types';
import { getSelectionRect, pointStyleHelper } from '../utils/mark-util';
import { getGroupingFromPosition, getStackingFromPosition } from '../utils/position-utils';
import styles from './Column.module.scss';

type ColumnProps = {
  y?: string;
  groupPadding?: number;
  pointPadding?: number;
  colorByPoint?: boolean;
  position: FlareOptionsPositionValue;
  showDataLabels?: boolean;
  dataLabelFormat?: (
    label: Point,
    options: DataLabelsOptions,
  ) => number | string | null | undefined;
  skeletonLoaderOptions?: {
    numSeries?: number;
    numCategories?: number;
    sortDescending?: boolean;
  };
  onPointSelectionChange?: (point: Point | null) => void;
};

const stylePoint = pointStyleHelper({ muteClassName: styles.inactive });

const highlightSelectedAndHoveredPoints = (chart: FlareChart) => {
  const selectedPoints = chart.getSelectedPoints();
  const hoveredPoints = chart.hoverPoints ?? [];
  const hasPointsOfInterest = selectedPoints.length > 0 || hoveredPoints.length > 0;

  (chart.series as ColumnSeries[]).forEach((series) => {
    series.points.forEach((point) => {
      if (hasPointsOfInterest) {
        const isPointSelected = selectedPoints.indexOf(point) !== -1;
        const isPointHovered = hoveredPoints.indexOf(point) !== -1;
        stylePoint(point, { mute: !isPointSelected && !isPointHovered });
      } else {
        stylePoint(point, { mute: false });
      }
    });
  });
};

const buildColumnOptions = (
  options: Options,
  parentProps: SanitizedFlareProps,
  props: ColumnProps,
): Options => {
  const { data, colors, parseY } = parentProps;
  const {
    y = 'y',
    groupPadding = 0.2,
    pointPadding = 0.1,
    colorByPoint = false,
    position = 'stack',
    showDataLabels = false,
    dataLabelFormat,
    onPointSelectionChange,
  } = props;

  const isSelectable = !!onPointSelectionChange;

  const series: SeriesColumnOptions[] | undefined = data?.map(
    (series: FlareSeriesOptions, seriesIndex: number) => {
      const { name, data: seriesData, ...rest } = series;

      const newSeriesData = seriesData.map((item: FlareDataPointOptions) => {
        const newItem: PointOptionsObject = {
          ...item,
          y: parseY(item[y]),
          colorIndex: seriesIndex,
        };
        delete newItem[y as keyof PointOptionsObject];

        return newItem;
      });

      return {
        type: 'column',
        ...rest,
        name,
        color: colors[seriesIndex],
        colorByPoint,
        lineColor: colors[seriesIndex],
        fillOpacity: 0.25,
        data: newSeriesData,
        marker: {
          enabled: false,
          fillOpacity: 1,
          fillColor: colors[seriesIndex],
          states: {
            hover: {
              enabled: true,
              radius: 5,
            },
            select: {
              enabled: true,
            },
          },
        },
      };
    },
  );

  return {
    ...options,
    chart: {
      ...options.chart,
      type: 'column',
      events: {
        ...options.chart?.events,
        render: function (event) {
          options.chart?.events?.render?.call<Chart, Event[], void>(this, event);

          highlightSelectedAndHoveredPoints(this as FlareChart);
        },
        redraw: function (event) {
          options.chart?.events?.redraw?.call<Chart, Event[], void>(this, event);

          const chart = this as FlareChart;
          if (chart.getSelectedPoints().length && chart.selectedBackground) {
            chart.selectedBackground.graphic.attr(getSelectionRect(chart));
          }
        },
        click: function () {
          if (isSelectable) {
            let pointSelected = false;
            this.series.forEach((series) => {
              series.points.forEach((point) => {
                if (!pointSelected && point.selected) {
                  pointSelected = true;
                }

                // Defer setting selection until the next frame
                setTimeout(() => {
                  point.select(false);
                }, 0);
              });
            });

            // Only dispatch this event if a point was selected
            if (pointSelected) {
              onPointSelectionChange(null);
            }
          }
        },
      },
    },
    series,
    plotOptions: {
      ...options.plotOptions,
      column: {
        borderRadius: 0,
        borderWidth: 0,
        pointPadding,
        groupPadding,
        stacking: getStackingFromPosition(position),
        grouping: getGroupingFromPosition(position),
        dataLabels: {
          enabled: showDataLabels,
          className: styles.columnDataLabel,
          style: {
            fontSize: '12',
            fontWeight: '400',
          },
          formatter:
            dataLabelFormat &&
            function (options) {
              return dataLabelFormat(this, options);
            },
        },
      },
      series: {
        className: classNames(styles.column, { [styles.columnSeriesSelectable]: isSelectable }),
        allowPointSelect: isSelectable,
        stickyTracking: true,
        point: {
          events: {
            select: function () {
              const point = this as ColumnPoint;
              const chart = this.series.chart as FlareChart;

              stylePoint(point, { mute: false });

              setTimeout(() => {
                if (
                  chart.getSelectedPoints().length &&
                  chart.selectedBackground?.xValue !== point.x
                ) {
                  const rect = this.series.chart.renderer
                    .rect()
                    .attr({
                      ...getSelectionRect(chart),
                      fill: COLOR_GREEN_400,
                      opacity: 0.3,
                    })
                    .add();

                  chart.selectedBackground = {
                    xValue: point.x,
                    graphic: rect,
                  };
                }

                onPointSelectionChange?.(point);
              }, 0);
            },
            unselect: function () {
              const chart = this.series.chart as FlareChart;
              highlightSelectedAndHoveredPoints(chart);

              const { graphic: rect } = chart?.selectedBackground ?? {};
              if (rect) {
                rect.destroy();
              }
              chart.selectedBackground = undefined;
            },
            mouseOver: function () {
              highlightSelectedAndHoveredPoints(this.series.chart as FlareChart);
            },
            mouseOut: function () {
              // Need a timeout because `chart.hoverPoints` is updated after the mouseOut event
              setTimeout(() => {
                highlightSelectedAndHoveredPoints(this.series.chart as FlareChart);
              }, 0);
            },
          },
        },
        states: {
          hover: {
            brightness: 0,
            animation: {
              duration: 200,
            },
          },
          normal: {
            animation: {
              duration: 200,
            },
          },
          select: {
            enabled: true,
            color: undefined,
            borderColor: undefined,
            animation: {
              duration: 200,
            },
          },
        },
      },
    },
  };
};

const Column = (props: ColumnProps) => {
  const { id, registerChild, isLoading } = useFlareContext();

  useEffect(() => {
    registerChild(id, (options: Options, parentProps: SanitizedFlareProps) =>
      buildColumnOptions(options, parentProps, props),
    );
  }, [props]);

  if (isLoading) {
    return <ColumnLoader {...props.skeletonLoaderOptions} position={props.position} />;
  }

  return null;
};

export default Column;
