import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { PropsWithChildren, useCallback, useEffect, useState } from 'react';
import { Navigate, useNavigate, useSearchParams } from 'react-router';

import PageSpinner from '@/components/PageSpinner';
import { sessionDurationMinutes } from '@/constants/numbers';
import { getStytchAPIErrorMessage } from '@/error/StytchError';
import { getLastVisitedOrgSlug } from '@/helper/common';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import { DiscoveryInfo } from './type';

/**
 * Wraps the Login page and looks for any search params on the URL placed by Stytch after a
 * redirect. If it finds them, it can either redirect to a different url or render the contents of
 * the resulting form.
 *
 * If no Stytch search params are found on the URL, this component passes through `children`
 * instead.
 */
const DiscoveryAuthenticate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const { pushNotification } = useNotification();
  const [searchParams] = useSearchParams();
  const stytch = useStytchB2BClient();
  const [discoveryInfo, setDiscoveryInfo] = useState<DiscoveryInfo | undefined>();
  const [isLoading, setIsLoading] = useState(false);

  const orgSlug = searchParams.get('slug');
  const tokenType = searchParams.get('stytch_token_type');
  const token = searchParams.get('token');
  const didComeFromSignUp = searchParams.get('signup') === 'true';
  const lastVisitedOrgSlug = getLastVisitedOrgSlug();

  const clearURLSearchParams = useCallback(() => {
    navigate(location.pathname, { replace: true });
  }, [navigate]);

  const redirectAuthenticatedUser = () => {
    navigate(ROUTES.root.path, { replace: true });
  };

  const handleDiscovery = useCallback(
    async (token: string) => {
      try {
        const { intermediate_session_token, email_address, discovered_organizations } =
          await stytch.magicLinks.discovery.authenticate({
            discovery_magic_links_token: token,
          });

        setDiscoveryInfo({
          intermediateToken: intermediate_session_token,
          email: email_address,
          discoveredOrgs: discovered_organizations,
          isDiscoverySignUp: didComeFromSignUp,
        });
      } catch (e) {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e),
        });
      }
    },
    [stytch, pushNotification, didComeFromSignUp],
  );

  const handleOAuthDiscovery = useCallback(
    async (token: string) => {
      try {
        const { intermediate_session_token, email_address, discovered_organizations } =
          await stytch.oauth.discovery.authenticate({
            discovery_oauth_token: token,
          });
        setDiscoveryInfo({
          intermediateToken: intermediate_session_token,
          email: email_address,
          discoveredOrgs: discovered_organizations,
          isDiscoverySignUp: didComeFromSignUp,
        });
      } catch (e) {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e, ROUTES.signIn.path),
        });
      }
    },
    [stytch, pushNotification, didComeFromSignUp],
  );

  const handleMultiTenantMagicLinks = useCallback(
    async (token: string) => {
      try {
        await stytch.magicLinks.authenticate({
          magic_links_token: token,
          session_duration_minutes: sessionDurationMinutes,
        });

        redirectAuthenticatedUser();
      } catch (e) {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e),
        });
      }
    },
    [stytch, redirectAuthenticatedUser],
  );

  const handleNonMatchingTokenType = useCallback(() => {
    //=============================================================================================
    // DO NOT ENABLE tenant auth workflow redirects in production until we are ready to support it.
    //=============================================================================================
    if (import.meta.env.VITE_ENVIRONMENT !== 'production' && orgSlug && token && tokenType) {
      // This is a tenant-specific workflow, we'll redirect to the tenant-specific sign-in page
      // which can handle these multi-tenant tokens.
      navigate(
        `${ROUTES.tenantSignInByOrgSlug(orgSlug)}?stytch_token_type=${tokenType}&token=${token}`,
        { replace: true },
      );
    } else if (lastVisitedOrgSlug) {
      // navigate(DYNAMIC_ROUTES.signInByTenant(lastVisitedOrgSlug), { replace: true });
    }
  }, []);

  const authenticate = useCallback(async () => {
    setIsLoading(true);

    switch (tokenType) {
      case 'discovery':
        clearURLSearchParams();
        await handleDiscovery(token!);
        break;
      case 'discovery_oauth':
        clearURLSearchParams();
        await handleOAuthDiscovery(token!);
        break;
      case 'multi_tenant_magic_links':
        clearURLSearchParams();
        await handleMultiTenantMagicLinks(token!);
        break;
      default:
        handleNonMatchingTokenType();
        break;
    }

    setIsLoading(false);
  }, [clearURLSearchParams, handleDiscovery, handleOAuthDiscovery, token, tokenType]);

  useEffect(() => {
    if (token && tokenType && !isLoading && !discoveryInfo) {
      authenticate();
    }
  }, [authenticate, orgSlug, isLoading, discoveryInfo]);

  if (discoveryInfo) {
    return <Navigate to={ROUTES.signInDiscovery.path} state={{ ...discoveryInfo }} replace />;
  }

  return <>{isLoading ? <PageSpinner /> : children}</>;
};

export default DiscoveryAuthenticate;
