import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import {
  INTEGRATION_PROVIDER_ID,
  IntegrationConnectionType,
  IntegrationProviderType,
  mutateIntegrationConnectionDelete,
} from '@/api/integration';
import IntegrationConnectionListItemStatus from '@/app/integrations/:provider/IntegrationConnectionListItemStatus';
import Tooltip from '@/components/Tooltip';
import { ConfirmModal } from '@/components/modals';
import { Link, Text, Title } from '@/components/typography';

import ConnectionOrg from './ConnectionOrg';
import styles from './IntegrationConnectionListItem.module.scss';

type Props = {
  provider: IntegrationProviderType;
  connection: IntegrationConnectionType;
  onDisconnect: (connection: IntegrationConnectionType) => void;
};

const IntegrationConnectionListItem = ({ provider, connection, onDisconnect }: Props) => {
  const [confirmDisconnect, setConfirmDisconnect] = useState<boolean>(false);
  const [disconnectLoading, setDisconnectLoading] = useState<boolean>(false);
  const author = connection.createdBy;
  const orgName = connection?.organizationName;
  const orgId = connection?.organizationId;

  const handleDisconnect = async () => {
    // don't worry about closing the modal. This component (including the modal) will be unmounted
    // when the refresh happens and the connection no longer exists.
    setDisconnectLoading(true);
    await mutateIntegrationConnectionDelete(connection.id!);
    onDisconnect(connection);
    setDisconnectLoading(false);
    setConfirmDisconnect(false);
  };

  return (
    <div data-testid="integration-detail-connectionItem" className={styles.container}>
      <Text variant="label" color="dark-grey">
        {author ? author.name : t`Unknown user`}
      </Text>
      <Tooltip body={author?.email}>
        <Text variant="body1" className={styles.email}>
          {author ? author.email : t`Unknown email address`}
        </Text>
      </Tooltip>
      <ConnectionOrg orgId={orgId} orgName={orgName} />
      {connection.status === 'active' ? (
        <IntegrationConnectionListItemStatus provider={provider} lastJob={connection.lastJob} />
      ) : (
        <Text variant="footnote" color="red" weight="regular">
          <Trans>There is a problem with this account, please refresh.</Trans>
        </Text>
      )}
      {provider.id !== INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS && (
        <Link variant="body2" color="static-grey" onClick={() => setConfirmDisconnect(true)}>
          <Trans>Disconnect {provider.name}</Trans>
        </Link>
      )}
      <ConfirmModal
        open={confirmDisconnect}
        okText={t`Yes, Disconnect`}
        isLoading={disconnectLoading}
        onCancel={() => setConfirmDisconnect(false)}
        onOk={handleDisconnect}
      >
        <Trans>
          Do you really want to disconnect{' '}
          <Title level="4" weight="medium" inline>
            {author?.email || t`this account`}
          </Title>
          ? {provider.name} data will not be available if you continue.
        </Trans>
      </ConfirmModal>
    </div>
  );
};

export default IntegrationConnectionListItem;
