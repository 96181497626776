import { t } from '@lingui/core/macro';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import useSWR from 'swr';

import SolGraphQLError from '@/error/SolGraphQLError';
import { getRevenueRangeName } from '@/messages/revenue-range-name-messages';
import { ROUTES } from '@/router';

import { AudienceOptionType } from '../audience';
import { REVENUE_RANGE_ID, TRAFFIC_TYPES } from '../common';
import { fetcherSol } from '../swr-fetcher';
import {
  WebTrafficBreakdownByTypeGQLResponse,
  WebTrafficIndustrySummaryGQLResponse,
  WebTrafficKPIGQLResponse,
  WebTrafficRevRangeSummaryDataType,
  WebTrafficRevRangeSummaryGQLResponse,
  WebTrafficVisitSummaryDataType,
} from './webTrafficType';

export const useWebTrafficKPIs = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficKPIGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficKPIs($startDate: DateTime!, $endDate: DateTime!, $audienceId: String) {
            visualization {
              trafficKpi(startDate: $startDate, endDate: $endDate, audienceId: $audienceId) {
                audienceVisits
                audienceVisitsLastPeriod
                audienceVisitsPercentChange
                closedWonInfluenced
                closedWonInfluencedLastPeriod
                closedWonInfluencedPercentChange
                engagedCompanies
                engagedCompaniesLastPeriod
                engagedCompaniesPercentChange
                pipelineInfluenced
                pipelineInfluencedLastPeriod
                pipelineInfluencedPercentChange
                tamVisits
                tamVisitsLastPeriod
                tamVisitsPercentChange
                visits
                visitsLastPeriod
                visitsPercentChange
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId: audience?.id,
      },
    },
    fetcherSol,
    {},
  );

  const webTrafficKPIs = useMemo(() => {
    const {
      visits,
      visitsLastPeriod,
      visitsPercentChange,
      tamVisits,
      tamVisitsLastPeriod,
      tamVisitsPercentChange,
      audienceVisits,
      audienceVisitsLastPeriod,
      audienceVisitsPercentChange,
      engagedCompanies,
      engagedCompaniesLastPeriod,
      engagedCompaniesPercentChange,
      pipelineInfluenced,
      pipelineInfluencedLastPeriod,
      pipelineInfluencedPercentChange,
      closedWonInfluenced,
      closedWonInfluencedLastPeriod,
      closedWonInfluencedPercentChange,
    } = data?.visualization.trafficKpi ?? {};

    const secondItem = audience?.id
      ? {
          id: 'targetVisits',
          label: t`${audience.name} Visits`,
          value: audienceVisits,
          lastPeriod: audienceVisitsLastPeriod,
          percentChange: audienceVisitsPercentChange,
          valueFormat: {},
        }
      : {
          id: 'targetVisits',
          label: t`TAM Visits`,
          value: tamVisits,
          lastPeriod: tamVisitsLastPeriod,
          percentChange: tamVisitsPercentChange,
          valueFormat: {},
        };

    return [
      {
        id: 'visits',
        label: t`Total Visits`,
        value: visits,
        lastPeriod: visitsLastPeriod,
        percentChange: visitsPercentChange,
        valueFormat: {},
      },
      secondItem,
      {
        id: 'engagedCompanies',
        label: audience?.name ? t`${audience.name} Engaged Companies` : t`TAM Engaged Companies`,
        value: engagedCompanies,
        valueLink:
          engagedCompanies != null &&
          engagedCompanies > 0 &&
          ROUTES.webTrafficActivityWithParams({
            audienceIds: audience?.id,
            inTam: audience?.id ? undefined : 'true',
            'metrics.visits': '[1,]',
          }),
        lastPeriod: engagedCompaniesLastPeriod,
        percentChange: engagedCompaniesPercentChange,
        valueFormat: {},
      },
      {
        id: 'pipeline',
        label: t`Pipeline Influence`,
        value: pipelineInfluenced,
        lastPeriod: pipelineInfluencedLastPeriod,
        percentChange: pipelineInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
      {
        id: 'closedWon',
        label: t`Closed Won Influence`,
        value: closedWonInfluenced,
        lastPeriod: closedWonInfluencedLastPeriod,
        percentChange: closedWonInfluencedPercentChange,
        valueFormat: { isCurrency: true, precision: 0 },
      },
    ];
  }, [data, audience]);

  return {
    webTrafficKPIs,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownByTrafficType = (
  startDate?: string,
  endDate?: string,
  audience?: AudienceOptionType,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficBreakdownByTypeGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficVisitBreakdown2(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping2!
            $audienceIds: [String]
          ) {
            visualization {
              trafficVisitBreakdown2(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceIds: $audienceIds
              ) {
                groupingField
                count
                total
                percentOfTotal
                companies
                percentOfTotalCompanies
                engagedCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceIds: audience?.id ? [audience.id] : [],
        groupingField: 'traffic_type',
      },
    },
    fetcherSol,
    {},
  );

  const trafficGroupingName: Record<string, string | undefined> = {
    [TRAFFIC_TYPES.TARGET]: audience?.name || t`TAM`,
    [TRAFFIC_TYPES.OTHER]: t`Other Companies`,
    [TRAFFIC_TYPES.BOT]: t`Bot`,
    [TRAFFIC_TYPES.UNRESOLVED]: t`Unknown`,
  };

  const trafficOrder = [
    TRAFFIC_TYPES.TARGET,
    TRAFFIC_TYPES.OTHER,
    TRAFFIC_TYPES.BOT,
    TRAFFIC_TYPES.UNRESOLVED,
  ];

  const webTrafficSummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown2) {
      return undefined;
    }

    const targetObj = data.visualization.trafficVisitBreakdown2.find(
      (groupingItem) =>
        groupingItem.groupingField === TRAFFIC_TYPES.AUDIENCE ||
        groupingItem.groupingField === TRAFFIC_TYPES.TAM,
    );

    if (targetObj) {
      targetObj.groupingField = TRAFFIC_TYPES.TARGET;
    }

    return data.visualization.trafficVisitBreakdown2
      .sort(
        (a, b) =>
          trafficOrder.indexOf(a.groupingField as TRAFFIC_TYPES) -
          trafficOrder.indexOf(b.groupingField as TRAFFIC_TYPES),
      )
      .map(({ groupingField, ...rest }) => ({
        id: groupingField,
        name: trafficGroupingName[groupingField],
        ...rest,
      })) as WebTrafficVisitSummaryDataType[];
  }, [data, audience?.id]);

  return {
    webTrafficSummary,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownBySector = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficIndustrySummaryGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficBreakdownBySector(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping!
            $audienceId: String
          ) {
            visualization {
              trafficVisitBreakdown(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceId: $audienceId
              ) {
                sector {
                  id
                  name
                }
                count
                total
                percentOfTotal
                engagedCompanies
                companies
                percentOfTotalCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
        groupingField: 'sector',
      },
    },
    fetcherSol,
    {},
  );

  const webTrafficIndustrySummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown) {
      return undefined;
    }

    return data.visualization.trafficVisitBreakdown.sort(
      (a, b) => b.engagedCompanies - a.engagedCompanies,
    );
  }, [data]);

  return {
    webTrafficIndustrySummary,
    isLoading,
    error,
  };
};

export const useWebTrafficBreakdownByCompanySize = (
  startDate?: string,
  endDate?: string,
  audienceId?: string,
) => {
  const canExecuteQuery = startDate?.length && endDate?.length;

  const { data, isLoading, error } = useSWR<WebTrafficRevRangeSummaryGQLResponse, SolGraphQLError>(
    {
      query:
        canExecuteQuery &&
        gql`
          query WebTrafficBreakdownByRevRange(
            $startDate: DateTime!
            $endDate: DateTime!
            $groupingField: TrafficVisitBreakdownGrouping!
            $audienceId: String
          ) {
            visualization {
              trafficVisitBreakdown(
                startDate: $startDate
                endDate: $endDate
                groupingField: $groupingField
                audienceId: $audienceId
              ) {
                revRange {
                  id
                  name
                }
                count
                total
                percentOfTotal
                engagedCompanies
                companies
                percentOfTotalCompanies
              }
            }
          }
        `,
      variables: {
        startDate: startDate + 'T00:00:00Z',
        endDate: endDate + 'T23:59:59Z',
        audienceId,
        groupingField: 'revrange',
      },
    },
    fetcherSol,
    {},
  );

  const trafficOrder = [
    REVENUE_RANGE_ID.ENTERPRISE,
    REVENUE_RANGE_ID.MID_MARKET,
    REVENUE_RANGE_ID.SMALL_MEDIUM,
    REVENUE_RANGE_ID.VERY_SMALL,
  ];

  const webTrafficRevRangeSummary = useMemo(() => {
    if (!data?.visualization.trafficVisitBreakdown) {
      return undefined;
    }

    return data.visualization.trafficVisitBreakdown
      .sort(
        (a, b) =>
          trafficOrder.indexOf(a.revRange.id as REVENUE_RANGE_ID) -
          trafficOrder.indexOf(b.revRange.id as REVENUE_RANGE_ID),
      )
      .map(({ revRange, ...rest }) => ({
        revRange: {
          ...revRange,
          shortName: getRevenueRangeName(revRange.id),
        },
        ...rest,
      })) as WebTrafficRevRangeSummaryDataType[];
  }, [data]);

  return {
    webTrafficRevRangeSummary,
    isLoading,
    error,
  };
};
