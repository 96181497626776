import { t } from '@lingui/core/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { useRecentDealVisitTrends } from '@/api/opportunity';
import Flare, { Area, Axis, Legend, Tooltip } from '@/components/Flare';
import { Marker, MarkerDataType, Notes } from '@/components/Flare/annotation';
import { NotesPanel } from '@/components/panels';
import { getChannelColor } from '@/constants/colors/channel';
import { DateBEFormat, DateFormatMD } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

import ClosedWonMarkerTooltip from './ClosedWonMarkerTooltip';
import ClosedWonTrendSummaryColumn from './ClosedWonTrendSummaryColumn';

type Props = {
  opportunityId?: string;
};

const ClosedWonTrendChart = ({ opportunityId }: Props) => {
  const { recentDealVisitTrends: data, isLoading, error } = useRecentDealVisitTrends(opportunityId);

  const chartData = useMemo(() => {
    if (!data?.channelData) {
      return undefined;
    }

    return data?.channelData.map((series) => {
      // Add one final data point to extend the area to the close date of the opportunity. This
      // prevent a gap from showing at the end of the area series.
      const lastDataPoint = series.data.at(-1);
      if (lastDataPoint?.sundayOfWeek !== data.opportunity.closeDate) {
        series.data.push({
          sundayOfWeek: data.opportunity.closeDate,
          runningTotalVisits: lastDataPoint?.runningTotalVisits ?? 0,
        });
      }

      return {
        id: series.channel.id,
        name: series.channel.name,
        data: series.data,
      };
    });
  }, [data]);

  const minDate = moment(chartData?.[0].data[0].sundayOfWeek).utc();

  const markerData = useMemo<MarkerDataType[]>(
    () => [
      {
        id: 'open',
        label: t`Pipeline`,
        align: 'center',
        date: data?.opportunity.openDate ?? '',
      },
      {
        id: 'close',
        label: t`Closed`,
        align: 'right',
        date: data?.opportunity.closeDate ?? '',
      },
    ],
    [data],
  );

  return (
    <NotesPanel
      startDate={
        data?.opportunity.openDate && moment(data.opportunity.openDate).format(DateBEFormat)
      }
      endDate={
        data?.opportunity.closeDate && moment(data.opportunity.closeDate).format(DateBEFormat)
      }
      size="L"
      title={t`Visit Trends`}
      verifyC99Tag
      noPadding
      style={{ height: 664 }}
    >
      <Flare
        key={opportunityId}
        data={chartData}
        width="auto"
        height="auto"
        colors={data?.channelData.map((channel) => getChannelColor(channel.channel))}
        parseX={getUTCTime}
        description={t`A trend chart showing a running total of visits by channel stacked on top of each other`}
        isLoading={isLoading}
        error={error}
        marginTop={60}
        marginRight={132}
      >
        <Legend />
        <Area x="sundayOfWeek" y="runningTotalVisits" position="stack" />
        <Marker
          data={markerData}
          renderLabel={(marker: MarkerDataType) => (
            <ClosedWonMarkerTooltip data={marker} opportunity={data?.opportunity} />
          )}
        />
        <Axis
          position="left"
          labelFormat={(item) => numberFormat(item.value)}
          tickPixelInterval={125}
        />
        <Axis
          type="datetime"
          position="bottom"
          crosshair="line"
          labelFormat={(item) => moment(item.value).format(DateFormatMD)}
        />
        <Tooltip
          shared
          titleFormat={(point) =>
            t`${minDate.format('MMM D')} - ${moment(point?.x).format('MMM D, YYYY')} Visits`
          }
          rowValueFormat={(point) => numberFormat(point?.y)}
          showTotalRow
        />
        <Notes />
        <ClosedWonTrendSummaryColumn />
      </Flare>
    </NotesPanel>
  );
};

export default ClosedWonTrendChart;
