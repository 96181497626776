import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { TRAFFIC_TYPES } from '@/api/common';
import { PixelTargetingEfficiencyDataType } from '@/api/pixel';
import MultiDonut from '@/components/charts/MultiDonut';
import { ALL_PRIMARY, BOT_PRIMARY, TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  pixelIds?: string[];
  data: PixelTargetingEfficiencyDataType;
  tooltipTitle: string;
  disabledSeries?: string[];
  isLoading?: boolean;
};

const PixelTargetingEfficiencyTAMDonut = ({
  pixelIds,
  data,
  tooltipTitle,
  disabledSeries,
  isLoading,
}: Props) => {
  const seriesData = useMemo(() => {
    let total = data?.all;

    if (disabledSeries?.includes(TRAFFIC_TYPES.UNRESOLVED)) {
      total -= data?.unresolved;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.OTHER)) {
      total -= data?.otherNonTam;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.BOT)) {
      total -= data?.bot;
    }

    if (disabledSeries?.includes(TRAFFIC_TYPES.TAM)) {
      total -= data?.tam;
    }

    return [
      {
        id: TRAFFIC_TYPES.TAM,
        value: data?.tam,
        secondaryValue: (data?.tam || 0) / total,
        seriesName: t`TAM`,
        color: TAM_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.OTHER,
        value: data?.otherNonTam,
        secondaryValue: (data?.otherNonTam || 0) / total,
        seriesName: t`Other Companies`,
        color: VISITS_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.BOT,
        value: data?.bot,
        secondaryValue: (data?.bot || 0) / total,
        seriesName: t`Bot`,
        color: BOT_PRIMARY,
      },
      {
        id: TRAFFIC_TYPES.UNRESOLVED,
        value: data?.unresolved,
        secondaryValue: (data?.unresolved || 0) / total,
        seriesName: t`Unknown`,
        color: ALL_PRIMARY,
      },
    ];
  }, [data, disabledSeries]);

  return (
    <MultiDonut
      data={seriesData}
      disabledSeries={disabledSeries}
      metricName={t`TAM<br/>Impressions`}
      titleValue={numberFormat(data?.tam, { compact: true })}
      tooltipTitle={tooltipTitle}
      isLoading={isLoading}
      error={null}
      valueLink={(point) => {
        if (point?.id === TRAFFIC_TYPES.TAM) {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            'metrics.impressions': '[1,]',
            inTam: 'true',
          });
        }

        if (point?.id === TRAFFIC_TYPES.OTHER) {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            'metrics.impressions': '[1,]',
            inTam: 'false',
          });
        }

        if (point?.key === 'total') {
          return ROUTES.webTrafficActivityWithParams({
            pixelIds,
            'metrics.impressions': '[1,]',
          });
        }
      }}
    />
  );
};

export default PixelTargetingEfficiencyTAMDonut;
