import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import classNames from 'classnames';
import { PropsWithChildren, useState } from 'react';
import { DropEvent, FileRejection, useDropzone } from 'react-dropzone-esm';

import { uploadAudienceFile } from '@/api/audience';
import UploadIcon from '@/assets/svg/cloudUpload.svg?react';
import FileIcon from '@/assets/svg/file.svg?react';
import TrashIcon from '@/assets/svg/trashcan.svg?react';
import ProgressBar from '@/components/ProgressBar';
import { ActionIcon } from '@/components/buttons';
import { Link, Text } from '@/components/typography';
import getSolStandardError from '@/error/sol-standard-errors';
import { numberFormat, numberFormatBytes } from '@/helper/numberFormatter';
import { useNotification } from '@/providers/Notification';

import styles from './AudienceUpload.module.scss';

enum VIEW_STATE {
  DEFAULT,
  UPLOADING,
  UPLOADED,
  ERROR,
}

type Props = {
  disabled?: boolean;
  maxFileSize?: number;
  onChange?: (value: string | null) => void;
  onFileAccepted?: (file: File, event: DropEvent) => void;
  onFileRejected?: (fileRejections: FileRejection[], event: DropEvent) => void;
  onUploadError?: (errorMessage: string) => void;
};

const AudienceUpload = ({
  children,
  disabled = false,
  maxFileSize,
  onChange,
  onFileAccepted,
  onFileRejected,
  onUploadError,
  ...props
}: PropsWithChildren<Props>) => {
  const { pushNotification } = useNotification();
  const [viewState, setViewState] = useState<VIEW_STATE>(VIEW_STATE.DEFAULT);
  const [uploadProgress, setUploadProgress] = useState<number>(0);

  const {
    getRootProps,
    getInputProps,
    open,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles,
  } = useDropzone({
    accept: {
      'application/vnd.ms-excel': ['.xls'],
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'text/csv': ['.csv'],
    },
    disabled,
    noClick: true,
    noKeyboard: true,
    maxFiles: 1,
    minSize: 0,
    maxSize: maxFileSize,
    onDropRejected: (fileRejections, event) => {
      if (onFileRejected) {
        onFileRejected(fileRejections, event);
      }
    },
    onDropAccepted: async (files, event) => {
      const file = files[0];

      setViewState(VIEW_STATE.UPLOADING);
      setUploadProgress(0);

      if (onFileAccepted) {
        onFileAccepted(file, event);
      }

      try {
        const datasetId = await uploadAudienceFile(file, setUploadProgress);
        handleChange(datasetId);
        setViewState(VIEW_STATE.UPLOADED);
      } catch (e) {
        if (onUploadError) {
          const solError = getSolStandardError(e);
          onUploadError(solError.message);
        }

        pushNotification({
          type: 'error',
          message: (
            <Text variant="caption1" weight="semi-bold" color="white">
              <Trans>
                Custom Audience failed to upload. Click{' '}
                <Link
                  variant="caption1"
                  color="static-white"
                  to="https://support.channel99.com/hc/en-us/articles/19099326570779/"
                  target="_blank"
                >
                  {t`HERE`}
                </Link>{' '}
                for troubleshooting guidance or try uploading again.
              </Trans>
            </Text>
          ),
        });
        setViewState(VIEW_STATE.DEFAULT);
        handleChange(null);
      }
    },
  });

  const handleChange = (value: string | null) => {
    if (onChange) {
      onChange(value);
    }
  };

  const handleDeleteFile = () => {
    handleChange(null);
    setViewState(VIEW_STATE.DEFAULT);
  };

  return (
    <div {...props}>
      <input {...getInputProps()} />
      {viewState === VIEW_STATE.DEFAULT ? (
        <div
          className={classNames(styles.dropzone, {
            [styles.focus]: isFocused,
            [styles.accept]: isDragAccept,
            [styles.reject]: isDragReject,
          })}
          {...getRootProps()}
        >
          <Text variant="body2" className={styles.instructions}>
            {children}
          </Text>
          <UploadIcon />
          <div>
            <Trans>
              or <br />
              <Link variant="body2" onClick={open}>{t`Browse Files`}</Link>
            </Trans>
          </div>
        </div>
      ) : (
        <div>
          {acceptedFiles.map((file) => (
            <div key={file.name} className={styles.fileContainer}>
              <FileIcon
                className={classNames(styles.fileIcon, {
                  [styles.green]: viewState === VIEW_STATE.UPLOADED,
                })}
              />
              <div className={styles.uploadedFileDetailsContainer}>
                <div className={styles.fileNameContainer}>
                  <Text variant="caption1" className={styles.fileName}>
                    {file.name}
                  </Text>
                  <Text variant="caption1">
                    <Trans>
                      {numberFormat(Math.round(uploadProgress * 100) / 100, { isPercent: true })}{' '}
                      Uploaded
                    </Trans>
                  </Text>
                </div>
                <ProgressBar
                  size="S"
                  value={uploadProgress}
                  green={viewState === VIEW_STATE.UPLOADED}
                />
                <Text variant="caption2" color="grey">
                  <Trans>Filesize: {numberFormatBytes(file.size)}</Trans>
                </Text>
              </div>
              <ActionIcon
                color="red"
                isDisabled={disabled}
                icon={<TrashIcon />}
                onClick={handleDeleteFile}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default AudienceUpload;
