import { t } from '@lingui/core/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { PipelineWeeklyTrendsDataType } from '@/api/pipeline';
import { DateFormatMD, MonthDateYearFormat } from '@/constants/formats';
import { getUTCTime } from '@/helper/dateHelper';
import { numberFormat } from '@/helper/numberFormatter';

import Flare, {
  Area,
  Axis,
  FlareError,
  FlareSeriesOptions,
  Legend,
  Notes,
  Tooltip,
} from '../../components/Flare';

type Props = {
  data?: PipelineWeeklyTrendsDataType[];
  isLoading?: boolean;
  error: FlareError;
};

const PipelineTrendsChart = ({ data, isLoading = false, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'ros',
        zIndex: 2,
        name: t`Return on Spend`,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.returnOnSpend })),
      },
      {
        id: 'cpo',
        zIndex: 1,
        name: t`Cost per Opportunity`,
        data: data.map((d) => ({ date: d.sundayOfWeek, amount: d.costPerOpportunity })),
        yAxis: 1,
      },
    ];
  }, [data]);

  const rosFormatterShort = (value: number | string | undefined | null) =>
    numberFormat(value, { isMultiplier: true, compact: true });
  const rosFormatter = (value: number | string | undefined | null) =>
    numberFormat(value, { isMultiplier: true });
  const cpoFormatter = (value: number | string | undefined | null) =>
    numberFormat(value, { isCurrency: true, precision: 0 });

  return (
    <Flare
      data={seriesData}
      width="auto"
      height="auto"
      parseX={getUTCTime}
      description={t`Trends showing return on spend and cost per opportunity for your pipeline.`}
      isLoading={isLoading}
      error={error}
    >
      <Legend />
      <Area x="date" y="amount" position="overlap" />
      <Axis dualAxisId="0" position="left" labelFormat={(item) => rosFormatterShort(item.value)} />
      <Axis dualAxisId="1" position="right" labelFormat={(item) => cpoFormatter(item.value)} />
      <Axis
        type="datetime"
        crosshair="line"
        position="bottom"
        labelFormat={(point) => moment(point.value).format(DateFormatMD)}
      />
      <Tooltip
        shared
        titleFormat={(point) => moment(point?.x).format(MonthDateYearFormat)}
        rowValueFormat={(item) =>
          item?.series?.name === t`Return on Spend` ? rosFormatter(item?.y) : cpoFormatter(item?.y)
        }
      />
      <Notes />
    </Flare>
  );
};

export default PipelineTrendsChart;
