import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import { ChatMessageType, UserChatMessageType, mutateAIChat } from '@/api/ai';
import AIStarsIcon from '@/assets/svg/ai-stars.svg?react';
import { AISearchInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { FEATURE_FLAG, useFeatureFlag } from '@/constants/featureFlags';
import useAuthWebSocket, { WSMessageType } from '@/hooks/useAuthWebSocket';

import AIDrawer from './AIDrawer';
import styles from './AISearch.module.scss';

const AISearch = () => {
  const canUseWebsockets = useFeatureFlag(FEATURE_FLAG.websockets);
  const [chatSessionId, setChatSessionId] = useState<string>();
  const [isDrawerOpen, setDrawerOpen] = useState(false);
  const [messages, setMessages] = useState<ChatMessageType[]>([]);
  const [isChatLoading, setChatLoading] = useState(false);
  const { onSendMessage } = useAuthWebSocket((wsMessage: WSMessageType) => {
    setChatSessionId(wsMessage.sessionId);
    setChatLoading(false);
    setMessages((prevMessages) => {
      if (!prevMessages.length) {
        return [
          {
            content: wsMessage.content,
            role: 'ai',
            isComplete: wsMessage.type === 'complete',
            sessionId: wsMessage.sessionId,
          },
        ];
      } else {
        const lastMessage = prevMessages[prevMessages.length - 1];

        if (lastMessage.role === 'ai' && !lastMessage.isComplete) {
          return [
            ...prevMessages.slice(0, -1),
            {
              ...lastMessage,
              content: lastMessage.content + wsMessage.content,
              isComplete: wsMessage.type === 'complete',
              sessionId: wsMessage.sessionId,
            },
          ];
        } else {
          return [
            ...prevMessages,
            {
              content: wsMessage.content,
              role: 'ai',
              isComplete: wsMessage.type === 'complete',
              sessionId: wsMessage.sessionId,
            },
          ];
        }
      }
    });
  });

  const sendMessage = async (message: string) => {
    const userMessage: UserChatMessageType = {
      content: message,
      role: 'user',
      sessionId: chatSessionId,
    };
    setMessages((prev) => [...prev, userMessage]);
    setChatLoading(true);

    if (canUseWebsockets) {
      onSendMessage?.(message, chatSessionId);
    } else {
      const aiResponse = await mutateAIChat(message);
      setMessages((prev) => [
        ...prev,
        {
          content: aiResponse,
          role: 'ai',
          isComplete: true,
          sessionId: '', // The deprecated Sol chat doesn't have a session ID, only Nebula's does
        },
      ]);
      setChatLoading(false);
    }
  };

  const handleSearch = (value: string) => {
    setDrawerOpen(true);
    sendMessage(value);
  };

  const handleSendMessage = (message: string) => {
    sendMessage(message);
  };

  return (
    <div className={styles.container}>
      {messages.length === 0 ? (
        <AISearchInput className={styles.searchInput} size="medium" round onSearch={handleSearch} />
      ) : (
        <Button
          className={styles.openButton}
          variant="secondary"
          color="black"
          icon={<AIStarsIcon />}
          onClick={() => setDrawerOpen(true)}
        >
          <Trans>Open Conversation</Trans>
        </Button>
      )}
      <AIDrawer
        open={isDrawerOpen}
        messages={messages}
        isChatLoading={isChatLoading}
        onClose={() => setDrawerOpen(false)}
        onSendMessage={handleSendMessage}
      />
    </div>
  );
};

export default AISearch;
