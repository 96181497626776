import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';

import { VendorActivityDataType } from '@/api/vendor';
import CloseIcon from '@/assets/svg/plus.svg?react';
import { Flex } from '@/components/Flex';
import { Tag, Text } from '@/components/typography';
import { COLOR_GREEN_300 } from '@/styles/palette';

import styles from './VendorFitScoringTableActions.module.scss';

type Props = {
  titleWidth: number;
  removedVendors: VendorActivityDataType[];
  onAddVendor?: (vendor: VendorActivityDataType) => void;
};

const VendorFitScoringTableActions = ({ titleWidth, removedVendors, onAddVendor }: Props) => {
  return (
    <Flex gap="small" align="center">
      <Text className={styles.label} style={{ width: titleWidth }} variant="body2">
        <Trans>Add Vendor:</Trans>
      </Text>
      <Space direction="horizontal" size={[0, 12]} wrap>
        {removedVendors.map((vendor) => (
          <Tag
            key={vendor.vendor.id}
            className={styles.tag}
            variant="outlined"
            color={COLOR_GREEN_300}
            size="large"
            onClick={() => onAddVendor?.(vendor)}
          >
            <Flex gap={6} align="center">
              <Trans>
                {vendor.vendor.name} - {vendor.vendor.channel?.name}
              </Trans>
              <CloseIcon width={10} height={10} />
            </Flex>
          </Tag>
        ))}
      </Space>
    </Flex>
  );
};

export default VendorFitScoringTableActions;
