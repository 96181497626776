import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import { AudienceGroupedOptionType } from '@/api/audience';
import { OPPORTUNITY_TYPE } from '@/api/opportunity';
import { usePixelOpportunitiesInfluenced, usePixelOpportunitiesInfluencedList } from '@/api/pixel';
import { Radio } from '@/components/Form';
import { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import { DateRangeType, usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { OpportunityInfluenceChartTableCombo } from '@/features/OpportunityInfluenceChartTableCombo';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  isLoading: boolean;
};

const PixelOpportunityInfluenceChartTable = ({
  pixelIds,
  audience,
  isLoading: isPixelLoading,
}: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const audienceIds = audience?.audienceIds;

  const [tableState, setTableState] = useState<Omit<QueryState, 'search' | 'filters'>>({
    page: 1,
    size: 10,
    sortBy: 'date',
    sortOrder: InstantSearchSortOrder.DESC,
  });

  const [opportunityType, setOpportunityType] = useState<OPPORTUNITY_TYPE>(
    OPPORTUNITY_TYPE.PIPELINE,
  );
  const [dateRangeOverride, setDateRangeOverride] = useState<DateRangeType>();

  const {
    pixelOpportunitiesInfluenced,
    isLoading: isPixelOpportunitiesInfluencedLoading,
    error,
  } = usePixelOpportunitiesInfluenced(pixelIds, startDate, endDate, audienceIds);

  const {
    opportunitiesListData,
    totalResults: opportunitiesListTotalResults,
    isLoading: opportunitiesListIsLoading,
    error: opportunitiesListError,
  } = usePixelOpportunitiesInfluencedList(
    tableState,
    opportunityType,
    pixelIds,
    dateRangeOverride?.startDate ?? startDate,
    dateRangeOverride?.endDate ?? endDate,
    audienceIds,
  );

  const options = [
    { value: OPPORTUNITY_TYPE.PIPELINE, label: t`Pipeline` },
    { value: OPPORTUNITY_TYPE.CLOSED_WON, label: t`Closed Won` },
  ];

  return (
    <Panel
      size="L"
      title={<Trans>Opportunity Influence</Trans>}
      noPadding
      actions={
        <Radio.Group
          value={opportunityType}
          optionType="button"
          onChange={(e) => setOpportunityType(e.target.value)}
          options={options}
        />
      }
    >
      <OpportunityInfluenceChartTableCombo
        tableState={tableState}
        opportunityType={opportunityType}
        opportunitiesTimeseriesData={
          opportunityType === OPPORTUNITY_TYPE.PIPELINE
            ? pixelOpportunitiesInfluenced?.pipeline.data
            : pixelOpportunitiesInfluenced?.recentDeals.data
        }
        opportunitiesTimeseriesIsLoading={isPixelLoading || isPixelOpportunitiesInfluencedLoading}
        opportunitiesTimeseriesError={error}
        opportunitiesListData={opportunitiesListData}
        opportunitiesListTotalResults={opportunitiesListTotalResults}
        opportunitiesListIsLoading={isPixelLoading || opportunitiesListIsLoading}
        opportunitiesListError={opportunitiesListError}
        onSelectedDateRangeChange={setDateRangeOverride}
        onTableStateChange={setTableState}
      />
    </Panel>
  );
};

export default PixelOpportunityInfluenceChartTable;
