import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { RuleObject } from 'antd/es/form';

import Form, { TextInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { maxDomainLength } from '@/constants/numbers';
import { isDomainValid } from '@/helper/validator';

import styles from './ConfigureOrgForm.module.scss';

type Props = {
  domainFieldName: string;
  isSaving: boolean;
};

const ConfigureOrgForm = ({ domainFieldName, isSaving }: Props) => {
  return (
    <>
      <Form.Item
        label={<Trans>Company Domain</Trans>}
        name={domainFieldName}
        initialValue=""
        extra={
          <Trans>
            Only use your top level domain without subdomains
            <br />
            (example channel99.com or yourdomain.io)
          </Trans>
        }
        rules={[
          { required: true, message: <Trans>Domain is Required</Trans> },
          {
            message: (
              <Trans>
                Values should be valid domain names (e.g. example.com), with no subdomains (e.g.
                invalid.com/subdomain), and must not start with "http" or "www".
              </Trans>
            ),
            validator: (_: RuleObject, value: string) =>
              isDomainValid(value) === false ? Promise.reject() : Promise.resolve(),
          },
        ]}
        required={false}
      >
        <TextInput placeholder={t`example.com`} maxLength={maxDomainLength} isDisabled={isSaving} />
      </Form.Item>
      <Button
        className={styles.button}
        color="green"
        variant="primary"
        size="large"
        type="submit"
        isLoading={isSaving}
        block
      >
        <Trans>Create account</Trans>
      </Button>
    </>
  );
};

export default ConfigureOrgForm;
