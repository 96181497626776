import { Trans } from '@lingui/react/macro';
import { PropsWithChildren } from 'react';

import { Text } from '@/components/typography';

type Props = {
  label: string;
};

const HealthPodDetail = ({ label, children }: PropsWithChildren<Props>) => (
  <Text variant="body2">
    <Trans>
      {label}: {children}
    </Trans>
  </Text>
);

export default HealthPodDetail;
