import { Trans } from '@lingui/react/macro';
import { ReactNode } from 'react';

import { useCampaignsByIds } from '@/api/campaign';
import Error404 from '@/components/errors/Error404';
import PageError from '@/components/errors/PageError';
import SolGraphQLError from '@/error/SolGraphQLError';

type Props = {
  campaignIds?: string[];
  minLimit?: number;
  maxLimit: number;
  children: ReactNode;
};

const CampaignDetailErrorOrChildren = ({
  campaignIds,
  maxLimit,
  minLimit = 1,
  children,
}: Props) => {
  const { error } = useCampaignsByIds(campaignIds);

  if (campaignIds?.length && campaignIds.length > maxLimit) {
    return (
      <PageError
        message={<Trans>Maximum limit of campaigns reached</Trans>}
        detail={
          <Trans>
            Only {maxLimit} campaigns can be viewed in this report. Please remove some campaigns to
            continue.
          </Trans>
        }
      />
    );
  }

  if (campaignIds?.length && campaignIds.length < minLimit) {
    return (
      <PageError
        message={<Trans>Minimum limit of campaigns has not been reached</Trans>}
        detail={
          <Trans>
            At least {minLimit} campaigns must be chosen to view this report. Please add more
            campaigns to continue.
          </Trans>
        }
      />
    );
  }

  if (error instanceof SolGraphQLError && error.is404()) {
    if (campaignIds?.length && campaignIds.length === 1) {
      return <Error404 message={<Trans>The campaign you're looking for does not exist</Trans>} />;
    }
    return (
      <Error404
        message={<Trans>One or more of the campaigns you're looking for do not exist</Trans>}
      />
    );
  }

  return children;
};

export default CampaignDetailErrorOrChildren;
