import { Trans } from '@lingui/react/macro';
import { MenuProps } from 'antd';

import MoreIcon from '@/assets/svg/bx-dots-vertical-rounded.svg?react';
import MetricSelectorIcon from '@/assets/svg/bx-layout.svg?react';
import { Dropdown } from '@/components/Dropdown';
import { Flex } from '@/components/Flex';
import { ActionIcon } from '@/components/buttons';
import { csvDownloadRowLimit } from '@/constants/numbers';
import { ExportCSVAction } from '@/features/ExportCSVAction';

type Props = {
  totalResults?: number;
  isLoading?: boolean;
  isDownloadingCSV?: boolean;
  onDownloadCSV?: () => void;
  onOpenMetricSelector?: () => void;
};

const ActivityActionMenu = ({
  totalResults,
  isLoading,
  isDownloadingCSV,
  onDownloadCSV,
  onOpenMetricSelector,
}: Props) => {
  const downloadRowLimitReached = totalResults != null && totalResults > csvDownloadRowLimit;

  const menuProps: MenuProps = {
    items: [
      {
        key: 'export',
        label: <ExportCSVAction downloadRowLimitReached={downloadRowLimitReached} />,
        disabled: downloadRowLimitReached || isLoading || isDownloadingCSV,
      },
      {
        key: 'selectMetrics',
        label: (
          <Flex gap="small">
            <MetricSelectorIcon />
            <Trans>Metrics Selection</Trans>
          </Flex>
        ),
      },
    ],
    onClick: ({ key }) => {
      if (key === 'export') {
        onDownloadCSV?.();
      }

      if (key === 'selectMetrics') {
        onOpenMetricSelector?.();
      }
    },
  };

  return (
    <Dropdown menu={menuProps} placement="bottomRight" trigger={['click']}>
      <ActionIcon size="large" color="black" icon={<MoreIcon />} />
    </Dropdown>
  );
};

export default ActivityActionMenu;
