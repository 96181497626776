import { t } from '@lingui/core/macro';

import { Flex } from '@/components/Flex';
import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';

import BenchmarksByChannel from './BenchmarksByChannel';

const BenchmarksOverview = () => {
  return (
    <Page title={t`Web Traffic`} pageName={t`Benchmarks`}>
      <PageFilterProvider>
        <Flex vertical gap="large" fullWidth>
          <PageDateRangeFilter />
          <BenchmarksByChannel />
        </Flex>
      </PageFilterProvider>
    </Page>
  );
};

export default BenchmarksOverview;
