import { msg } from '@lingui/core/macro';

import { ErrorByCodeType } from './types';

export enum INTEGRATION_ERROR_CODES {
  INVALID_OAUTH_CONNECTION_TYPE = 'err:sol.oauth.invalid-oauth-connection-type',
  TOKEN_CREATION_FAILURE = 'err:sol.oauth.token-creation-failure',
  OAUTH_ALREADY_CONNECTED = 'err:sol.oauth.alreadyConnected',
  OAUTH_MISSING_FIELD = 'err:sol.oauth.missing-field',
  OAUTH_NO_ORGS = 'err:sol.oauth.no-orgs',
  OAUTH_TOO_MANY_ORGS = 'err:sol.oauth.too-many-orgs',

  SALESFORCE_INVALID_URL = 'err:sol.audiences.invalid-sf-url',
  SALESFORCE_EXPIRED = 'err:sol.salesforce-expired',
  SALESFORCE_MISSING_CURRENCY_FIELD = 'err:sol.sf-missing-currency-field',
  SALESFORCE_MISSING_DETAILS = 'err:sol.salesforce-missing-details',
  SALESFORCE_INVALID_FIELD = 'err:sol.salesforce-invalid-field',
  SALESFORCE_CANNOT_FETCH_REPORT = 'err:sol.instance.cant-fetch-sf-report',
}

export const INTERNAL_OAUTH_FAILURE = msg`An error occurred while connecting your account. Please contact support for assistance.`;

export const INTEGRATION_ERROR_MESSAGES_BY_CODE: ErrorByCodeType = {
  [INTEGRATION_ERROR_CODES.INVALID_OAUTH_CONNECTION_TYPE]: INTERNAL_OAUTH_FAILURE,
  [INTEGRATION_ERROR_CODES.TOKEN_CREATION_FAILURE]: INTERNAL_OAUTH_FAILURE,
  [INTEGRATION_ERROR_CODES.OAUTH_ALREADY_CONNECTED]: msg`This account has already been connected to your organization.`,
  [INTEGRATION_ERROR_CODES.OAUTH_MISSING_FIELD]: msg`The integration provider authentication succeeded, but no valid accounts were found.`,
  [INTEGRATION_ERROR_CODES.OAUTH_NO_ORGS]: msg`Oops, we were unable to find any accounts. Please check to ensure you are an admin of the account you are trying to connect and try again.`,
  [INTEGRATION_ERROR_CODES.OAUTH_TOO_MANY_ORGS]: INTERNAL_OAUTH_FAILURE,

  [INTEGRATION_ERROR_CODES.SALESFORCE_INVALID_URL]: msg`The Salesforce link is incorrect. Valid URLs should resemble the following pattern: 'https://<your-subdomain>.lightning.force.com/lightning/r/Report/<report-id>/view'.`,
  [INTEGRATION_ERROR_CODES.SALESFORCE_EXPIRED]: msg`Channel99's connection to your Salesforce connection has expired. Please reconnect your Salesforce account first.`,
  [INTEGRATION_ERROR_CODES.SALESFORCE_MISSING_CURRENCY_FIELD]: msg`The Salesforce Pipeline Report does not contain a currency field. Please check that a currency field representing the opportunity amount is present in the report.`,
  [INTEGRATION_ERROR_CODES.SALESFORCE_MISSING_DETAILS]: msg`The Pipeline Report Link was specified, but the Opportunity Amount Field was not given. Either add an amount field to the Salesforce report and choose it or remove the link to continue.`,
  [INTEGRATION_ERROR_CODES.SALESFORCE_INVALID_FIELD]: msg`The Salesforce report does not contain the required fields. Please check that "{validFieldNames}" is present in the report.`,
  [INTEGRATION_ERROR_CODES.SALESFORCE_CANNOT_FETCH_REPORT]: msg`The Salesforce report link is unavailable, does not exist, or does not contain the required information.`,
};
