import { Trans } from '@lingui/react/macro';

import { useChannelOverviewTrafficOverviewKPIs } from '@/api/channel';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

const ChannelOverviewKPIMetrics = () => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate, audience } = pageFilters;
  const {
    channelTrafficOverviewKPIs: data,
    isLoading,
    error,
  } = useChannelOverviewTrafficOverviewKPIs(startDate, endDate, audience?.id);

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={<Trans>All Visits</Trans>}
        value={data && numberFormat(data.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>Total Spend</Trans>}
        value={data && numberFormat(data.spend, { isCurrency: true, precision: 0 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>All $/Visit</Trans>}
        value={data && numberFormat(data.spendPerVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM Visits</Trans>}
        value={data && numberFormat(data.tamVisits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={<Trans>TAM $/Visit</Trans>}
        value={data && numberFormat(data.spendPerTamVisit, { isCurrency: true })}
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default ChannelOverviewKPIMetrics;
