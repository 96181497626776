import { t } from '@lingui/core/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { DiscoveredOrganization } from '@stytch/vanilla-js';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Navigate } from 'react-router';

import { GlobalLoader } from '@/components/GlobalLoader';
import PageSpinner from '@/components/PageSpinner';
import { FullPage } from '@/components/page';
import { sessionDurationMinutes } from '@/constants/numbers';
import AuthError, { AUTH_ERROR_TYPE } from '@/error/AuthError';
import { isBusinessEmail } from '@/helper/validator';
import useLocationState from '@/hooks/useLocationState';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import DiscoveryForm from './DiscoveryForm';

const createSimpleError = (type: AUTH_ERROR_TYPE, email: string) => {
  return new AuthError({ type, details: { email } });
};

/**
 * Redirected to this page from `/sign-in` if the user belongs to one or more organizations. Here
 * we will display a list of organizations the user belongs to and allow them to choose one. When
 * this happens, we will exchange the intermediate session token for a session token and redirect
 * them to the app.
 *
 * If the user only belongs to a single org, we will automatically exchange the intermediate
 * session token and redirect them to the app.
 *
 * If the user belongs to no orgs, we will redirect them to the configure org page.
 */
const DiscoverOrgs = () => {
  const state = useLocationState<{
    discoveredOrgs?: DiscoveredOrganization[];
    email: string;
    isDiscoverySignUp: boolean;
  }>();
  const stytch = useStytchB2BClient();
  const { pushNotification } = useNotification();
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [authError, setAuthError] = useState<AuthError | null>(null);
  const exchangedToken = useRef(false);

  const { discoveredOrgs, email, isDiscoverySignUp } = state;

  const needsToConfigureOrg = !!(discoveredOrgs?.length === 0);
  const belongsToOneOrg = !!(discoveredOrgs?.length === 1);
  const belongsToMultipleOrgs = !!(discoveredOrgs && discoveredOrgs.length > 1);

  const exchangeIntermediateSession = useCallback(
    async (orgId: string) => {
      try {
        exchangedToken.current = true;
        await stytch.discovery.intermediateSessions.exchange({
          organization_id: orgId,
          session_duration_minutes: sessionDurationMinutes,
        });

        setIsAuthenticated(true);
      } catch {
        setAuthError(createSimpleError(AUTH_ERROR_TYPE.UNKNOWN, email));
      }
    },
    [stytch, exchangedToken.current, pushNotification],
  );

  useEffect(() => {
    if (belongsToOneOrg && discoveredOrgs[0].member_authenticated && !exchangedToken.current) {
      exchangeIntermediateSession(discoveredOrgs[0].organization.organization_id);
    }
  }, [belongsToOneOrg, discoveredOrgs, exchangeIntermediateSession]);

  // This can only be undefined if the user typed this route in manually into their browser.
  if (!discoveredOrgs) {
    return <Navigate to={ROUTES.signIn.path} replace />;
  }

  if (needsToConfigureOrg) {
    try {
      if (isDiscoverySignUp) {
        if (isBusinessEmail(email)) {
          // If the user doesn't belong to an org and came here from sign-up, take them to go
          // configure one
          return <Navigate to={ROUTES.configureOrg.path} state={{ ...state }} replace />;
        } else {
          throw createSimpleError(AUTH_ERROR_TYPE.CANNOT_SIGN_UP_WITH_PERSONAL_EMAIL, email);
        }
      } else {
        // User cannot create an org from sign-in, so take them back with an error
        throw createSimpleError(AUTH_ERROR_TYPE.CANNOT_CREATE_ORG_FROM_SIGN_IN, email);
      }
    } catch (e) {
      return <Navigate to={ROUTES.signIn.path} state={{ error: e }} replace />;
    }
  }

  if (authError) {
    return <Navigate to={ROUTES.signIn.path} state={{ error: authError }} replace />;
  }

  if (isAuthenticated) {
    return <Navigate to={ROUTES.app.path} replace />;
  }

  return (
    <FullPage title={t`Sign In`}>
      {needsToConfigureOrg && <PageSpinner />}
      {belongsToOneOrg && <GlobalLoader />}
      {belongsToMultipleOrgs && (
        <DiscoveryForm
          email={email}
          discoveredOrgs={discoveredOrgs}
          onOrgSelect={exchangeIntermediateSession}
        />
      )}
    </FullPage>
  );
};

export default DiscoverOrgs;
