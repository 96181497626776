import { t } from '@lingui/core/macro';

import { Page, PageDateRangeFilter, PageFilterProvider } from '@/components/page';

import styles from './Dashboard.module.scss';
import DashboardContent from './DashboardContent';

const Dashboard = () => {
  return (
    <Page title={t`Dashboard`} className={styles.dashboardPage}>
      <PageFilterProvider>
        <PageDateRangeFilter />
        <DashboardContent />
      </PageFilterProvider>
    </Page>
  );
};

export default Dashboard;
