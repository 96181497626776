import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo, useState } from 'react';
import { renderToStaticMarkup } from 'react-dom/server';
import { useNavigate } from 'react-router';

import { useMultiplePixelCostBreakdownVisualizationData } from '@/api/pixel';
import Flare, {
  Axis,
  FlareSeriesOptions,
  Legend,
  Tooltip,
  linkifyAxisLabels,
  tooltipPositionBelow,
} from '@/components/Flare';
import AxisLabel from '@/components/Flare/guides/AxisLabel';
import Bar from '@/components/Flare/marks/Bar';
import { Radio } from '@/components/Form';
import { usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import { ALL_PRIMARY, AUDIENCE_PRIMARY, TAM_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  pixelIds?: string[];
};

const PixelMultiCostPerVisitChart = ({ pixelIds }: Props) => {
  const navigate = useNavigate();
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const [chartMode, setChartMode] = useState('cpm');
  const { pixelCostBreakdownData, isLoading, error } =
    useMultiplePixelCostBreakdownVisualizationData(pixelIds, startDate, endDate);

  const options = [
    { value: 'cpm', label: t`CPM` },
    { value: 'cpv', label: t`Cost Per Visit` },
  ];

  const sanitizedData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!pixelCostBreakdownData) {
      return undefined;
    }

    const data = pixelCostBreakdownData.map((d) => (chartMode === 'cpm' ? d.cpm : d.cpv));

    return [
      {
        id: 'all',
        name: t`All`,
        zIndex: 1,
        data: data.map((d) => ({ amount: d.all })),
      },
      {
        id: 'tam',
        name: t`TAM`,
        zIndex: 2,
        data: data.map((d) => ({ amount: d.tam })),
      },
      {
        id: 'audience',
        name: t`Target Audience`,
        zIndex: 3,
        data: data.map((d) => ({ amount: d.audience })),
      },
    ];
  }, [pixelCostBreakdownData, chartMode]);

  return (
    <Panel
      size="L"
      title={<Trans>Cost</Trans>}
      actions={
        <Radio.Group
          value={chartMode}
          optionType="button"
          onChange={(e) => setChartMode(e.target.value)}
          options={options}
        />
      }
      noPadding
      style={{ height: 477 }}
    >
      <Flare
        data={sanitizedData}
        description={t`A comparison between Cost per visit of an optional Audience, Total Addressable Market (TAM), and everything else.`}
        colors={[ALL_PRIMARY, TAM_PRIMARY, AUDIENCE_PRIMARY]}
        isLoading={isLoading}
        error={error}
        postRender={(chart) => {
          linkifyAxisLabels(chart.xAxis[0], (url) => navigate(url));
        }}
      >
        <Legend reversed />
        <Bar
          y="amount"
          position="dodge"
          showDataLabels
          dataLabelFormat={(label) =>
            label.y === 0 ? '' : numberFormat(label.y, { isCurrency: true })
          }
          skeletonLoaderOptions={{ numSeries: 3, numCategories: pixelIds?.length || 3 }}
        />
        <Axis
          position="right"
          labelFormat={(item) => numberFormat(item.value, { isCurrency: true, precision: 0 })}
          gridlineWidth={0}
          baselineWidth={1}
          tickAmount={5}
        />
        <Axis
          position="bottom"
          categories={pixelCostBreakdownData?.map((d) => d.pixel.name || '') || []}
          labelFormat={(item) => {
            const { pixel } = pixelCostBreakdownData?.[item.pos] || {};
            if (!pixel?.id || !pixel?.name) {
              return '';
            }
            return renderToStaticMarkup(
              <AxisLabel
                align="right"
                label={pixel?.name}
                linkUrl={ROUTES.pixelById(pixel.id)}
                secondaryLabel={pixel?.audience?.name}
              />,
            );
          }}
          useHTML
          crosshair="rect"
          baselineWidth={0}
        />
        <Tooltip
          titleFormat={(point) => point?.key}
          rowValueFormat={(point) => numberFormat(point?.y, { isCurrency: true })}
          positioner={tooltipPositionBelow}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default PixelMultiCostPerVisitChart;
