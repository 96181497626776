import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { Organization } from '@stytch/vanilla-js';

import { Divider } from '@/components/Divider';
import { Link, Text } from '@/components/typography';
import { clearLastVisitedOrgSlug } from '@/helper/common';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import OAuthLogin from '../OAuthLogin';
import SignInPanel from '../SignInPanel';
import TermsOfUseLink from '../TermsOfUseLink';
import { OAuthProviderType } from '../type';
import styles from './TenantLoginForm.module.scss';
import TenantPasswordForm from './TenantPasswordForm';
import { getAllowedAuthMethods } from './helpers';

type Props = {
  org: Organization;
};

const TenantLoginForm = ({ org }: Props) => {
  const stytch = useStytchB2BClient();
  const { pushNotification } = useNotification();

  const authMethods = getAllowedAuthMethods(org);

  const supportsPasswordAuth = authMethods.indexOf('password') >= 0;
  const supportsMagicLinkAuth = authMethods.indexOf('magic_link') >= 0;
  const supportsGoogleOAuth = authMethods.indexOf('google_oauth') >= 0;
  const supportsMicrosoftOAuth = authMethods.indexOf('microsoft_oauth') >= 0;

  const supportsOAuth = supportsGoogleOAuth || supportsMicrosoftOAuth;

  const handleChangeOrg = () => {
    clearLastVisitedOrgSlug();
  };

  const handleOAuthProviderClick = async (provider: OAuthProviderType) => {
    try {
      await stytch.oauth[provider].start({
        organization_id: org.organization_id,
        login_redirect_url: `${location.origin}/sign-in`,
        signup_redirect_url: `${location.origin}/sign-in`,
      });
    } catch {
      pushNotification({
        type: 'error',
        message: (
          <Trans>
            We were unable to sign you into this account. Please refresh the page and try again.
          </Trans>
        ),
      });
    }
  };

  return (
    <SignInPanel
      title={<Trans>Welcome Back, {org.organization_name}!</Trans>}
      footer={
        <>
          {supportsMagicLinkAuth ? (
            <Text variant="caption1" className={styles.forgotPasswordInstructions}>
              <Trans>
                Sign in with email link{' '}
                <Link
                  variant="caption1"
                  color="aqua"
                  to={ROUTES.tenantSignInMagicLinkByOrgSlug(org.organization_slug)}
                >
                  {t`Here`}
                </Link>{' '}
                or{' '}
                <Link
                  variant="caption1"
                  color="aqua"
                  to={ROUTES.tenantForgotPasswordByOrgSlug(org.organization_slug)}
                >
                  {t`Reset your password`}
                </Link>
              </Trans>
            </Text>
          ) : (
            <Text variant="caption1" className={styles.forgotPasswordInstructions}>
              <Trans>
                Forgot your password?{' '}
                <Link
                  variant="caption1"
                  color="aqua"
                  to={ROUTES.tenantForgotPasswordByOrgSlug(org.organization_slug)}
                >
                  {t`Reset your password`}
                </Link>
              </Trans>
            </Text>
          )}
          <Text variant="caption1">
            <Trans>
              Don't want to sign in to {org.organization_name}?{' '}
              <Link
                variant="caption1"
                color="aqua"
                onClick={handleChangeOrg}
                to={ROUTES.signIn.path}
              >
                {t`Sign in to another account`}
              </Link>
            </Trans>
          </Text>
          <Text variant="caption1" color="grey">
            <Trans>
              By continuing to sign in I agree to the <TermsOfUseLink />
            </Trans>
          </Text>
        </>
      }
    >
      {supportsOAuth && (
        <OAuthLogin
          type="sign-in"
          supportsGoogleOAuth={supportsGoogleOAuth}
          supportsMicrosoftOAuth={supportsMicrosoftOAuth}
          onOAuthProviderClick={handleOAuthProviderClick}
        />
      )}
      {supportsOAuth && supportsPasswordAuth && (
        <Divider className={styles.divider} orientation="center">
          <Trans>or</Trans>
        </Divider>
      )}
      {supportsPasswordAuth && <TenantPasswordForm org={org} />}
    </SignInPanel>
  );
};

export default TenantLoginForm;
