import { Trans } from '@lingui/react/macro';
import { MenuProps } from 'antd';

import MoreIcon from '@/assets/svg/bx-dots-vertical-rounded.svg?react';
import MetricSelectorIcon from '@/assets/svg/bx-layout.svg?react';
import { Dropdown } from '@/components/Dropdown';
import { Flex } from '@/components/Flex';
import { ActionIcon } from '@/components/buttons';

type Props = {
  onOpenMetricSelector?: () => void;
};

const VendorActivityTableActionMenu = ({ onOpenMetricSelector }: Props) => {
  const menuProps: MenuProps = {
    items: [
      {
        key: 'selectMetrics',
        label: (
          <Flex gap="small">
            <MetricSelectorIcon />
            <Trans>Metrics Selection</Trans>
          </Flex>
        ),
      },
    ],
    onClick: ({ key }) => {
      if (key === 'selectMetrics') {
        onOpenMetricSelector?.();
      }
    },
  };

  return (
    <Dropdown menu={menuProps} placement="bottomRight" trigger={['click']}>
      <ActionIcon size="large" color="black" icon={<MoreIcon />} />
    </Dropdown>
  );
};

export default VendorActivityTableActionMenu;
