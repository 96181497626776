import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { DefaultOptionType } from 'antd/es/select';
import { ReactNode, useState } from 'react';

import { AudienceOptionType, useAudienceOptions } from '@/api/audience';
import { InfiniteScrollSelect, SelectProps } from '@/components/Form';

const PAGE_SIZE = 50;

export type InfiniteScrollItemType = {
  id?: string;
  name?: string;
};

interface Props extends Omit<SelectProps, 'value' | 'options' | 'onChange'> {
  value?: AudienceOptionType;
  isLoading?: boolean;
  prependedOptions?: AudienceOptionType[];
  onChange?: (
    val: AudienceOptionType | undefined,
    option: DefaultOptionType | DefaultOptionType[],
  ) => void;
  renderOption?: (option: AudienceOptionType) => ReactNode;
}

type Value = {
  label: string;
  value: string;
};

const AudienceSelect = ({
  value,
  isLoading: isLoadingProp,
  prependedOptions,
  onChange,
  ...rest
}: Props) => {
  const [search, setSearch] = useState<string>('');
  const { audienceOptions, hasMore, isLoading, isLoadingMore, pageIndex, loadMore } =
    useAudienceOptions({ search, pageSize: PAGE_SIZE, prependedOptions });

  const handleChange = (
    selectedValue: Value | null | undefined,
    option: DefaultOptionType | DefaultOptionType[],
  ) => {
    if (!selectedValue) {
      onChange?.(undefined, option);
    } else {
      const selectedOption = Array.isArray(option)
        ? option.find((opt) => opt.value === selectedValue.value)
        : option;

      onChange?.(
        {
          id: selectedValue.value,
          name:
            (selectedOption?.label as string) || (selectedOption?.children as unknown as string),
          metrics: selectedOption?.metrics,
        },
        option,
      );
    }
  };

  return (
    <InfiniteScrollSelect<AudienceOptionType>
      placeholder={t`Select Audience...`}
      {...rest}
      options={audienceOptions}
      hasMore={hasMore}
      loadMore={() => hasMore && loadMore(pageIndex + 1)}
      isLoading={isLoadingProp || isLoading || isLoadingMore}
      value={value?.name}
      onChange={handleChange}
      onSearch={setSearch}
      labelInValue
      notFoundContent={<Trans>No audiences were found...</Trans>}
      data-testid="audience-dropdown"
    />
  );
};

export default AudienceSelect;
