import { Trans } from '@lingui/react/macro';
import { Space } from 'antd';
import { useMemo } from 'react';

import { PixelType } from '@/api/pixel';
import { Flex } from '@/components/Flex';
import { Button } from '@/components/buttons';
import { Tag } from '@/components/typography';
import {
  PIXEL_COMPARE_MAX_LIMIT,
  PIXEL_COMPARE_MIN_LIMIT,
  PIXEL_GROUP_MAX_LIMIT,
  PIXEL_GROUP_MIN_LIMIT,
} from '@/constants/numbers';
import { ROUTES } from '@/router';

type Props = {
  selectedPixels: PixelType[];
  onRemoveSelectedPixel: (pixel: PixelType) => void;
};

const PixelTableActionContent = ({ selectedPixels, onRemoveSelectedPixel }: Props) => {
  const selectedPixelIds = useMemo(() => selectedPixels.map((pixel) => pixel.id), [selectedPixels]);

  const disableCompare =
    selectedPixels.length < PIXEL_COMPARE_MIN_LIMIT ||
    selectedPixels.length > PIXEL_COMPARE_MAX_LIMIT;
  const disableGroup =
    selectedPixels.length < PIXEL_GROUP_MIN_LIMIT || selectedPixels.length > PIXEL_GROUP_MAX_LIMIT;

  return (
    <Space direction="horizontal" size="middle" align="start">
      <Flex vertical gap="small">
        <Button
          color="black"
          variant="secondary"
          isDisabled={disableCompare}
          to={ROUTES.pixelCompareByIds(selectedPixelIds)}
        >
          <Trans>
            Compare
            {disableCompare ? '' : ` (${selectedPixels.length})`}
          </Trans>
        </Button>
        <Button
          color="black"
          variant="secondary"
          isDisabled={disableGroup}
          to={ROUTES.pixelGroupByIds(selectedPixelIds)}
        >
          <Trans>
            Group
            {disableGroup ? '' : ` (${selectedPixels.length})`}
          </Trans>
        </Button>
      </Flex>
      <Space direction="horizontal" size={[0, 12]} wrap>
        {selectedPixels.map((pixel) => (
          <Tag key={pixel.id} closable onClose={() => onRemoveSelectedPixel(pixel)}>
            {pixel.name}
          </Tag>
        ))}
      </Space>
    </Space>
  );
};

export default PixelTableActionContent;
