import { Trans } from '@lingui/react/macro';
import { Row, Space } from 'antd';

import LoadingIndicator from '@/components/LoadingIndicator';
import { Button } from '@/components/buttons';
import { Modal } from '@/components/modals';
import { Title } from '@/components/typography';

type Props = {
  open: boolean;
  onAbortDownload: () => void;
};

const FileDownloadModal = ({ open, onAbortDownload }: Props) => {
  return (
    <Modal
      open={open}
      closable={false}
      footer={
        <Row justify="center" style={{ width: '100%' }}>
          <Button size="large" color="black" variant="primary" onClick={onAbortDownload}>
            <Trans>Cancel Download</Trans>
          </Button>
        </Row>
      }
    >
      <Space direction="vertical" size={30} align="center" style={{ textAlign: 'center' }}>
        <Title level={4} weight="light">
          <Trans>
            Your file is being downloaded which may take a minute or two. Please don't navigate away
            from this screen before the download is complete.
          </Trans>
        </Title>
        <LoadingIndicator size="large" />
      </Space>
    </Modal>
  );
};

export default FileDownloadModal;
