import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import moment from 'moment';
import { useMemo } from 'react';

import { OPPORTUNITY_TYPE, OpportunityType } from '@/api/opportunity';
import { InstantSearchSortOrder, QueryState } from '@/components/InstantSearch';
import Table, { ColumnsType, TableProps } from '@/components/Table';
import { Link } from '@/components/typography';
import { USDateViewFormat } from '@/constants/formats';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  tableState: Omit<QueryState, 'search' | 'filters'>;
  data?: OpportunityType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: Error;
  opportunityType: OPPORTUNITY_TYPE;
  onTableStateChange?: (tableState: Omit<QueryState, 'search' | 'filters'>) => void;
};

const OpportunityInfluenceTable = ({
  tableState,
  data,
  totalResults,
  isLoading,
  error,
  opportunityType,
  onTableStateChange,
}: Props) => {
  const columns: ColumnsType<OpportunityType> = useMemo(() => {
    return [
      {
        title: t`Company Name`,
        key: 'account.company.name',
        fixed: 'left',
        sorter: true,
        render: (text, field) =>
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON &&
          field.metrics &&
          field.metrics.visits > 0 ? (
            <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
              {field.account?.company.name ?? ''}
            </Link>
          ) : (
            field.account?.company.name
          ),
      },
      {
        title: t`Opportunity`,
        key: 'name',
        sorter: true,
        render: (text, field) =>
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON &&
          field.metrics &&
          field.metrics.visits > 0 ? (
            <Link variant="caption1" to={ROUTES.closedWonById(field.id)}>
              {text}
            </Link>
          ) : (
            text
          ),
      },
      {
        title: opportunityType === OPPORTUNITY_TYPE.PIPELINE ? t`Create Date` : t`Close Date`,
        key: 'date',
        sorter: true,
        defaultSortOrder: 'descend',
        render: (text, field) =>
          moment(
            opportunityType === OPPORTUNITY_TYPE.PIPELINE ? field.openDate : field.closeDate,
          ).format(USDateViewFormat),
      },
      {
        title: t`Impressions`,
        key: 'metrics.impressions',
        sorter: true,
        render: (text, field) => numberFormat(field.metrics?.impressions),
      },
      {
        title: t`Visits`,
        key: 'metrics.visits',
        sorter: true,
        render: (text, field) => numberFormat(field.metrics?.visits),
      },
      {
        title: t`Opportunity Amount`,
        key: 'amount',
        sorter: true,
        render: (text) => numberFormat(text, { isCurrency: true, precision: 0 }),
      },
    ];
  }, [opportunityType]);

  const handleTableChange: TableProps<OpportunityType>['onChange'] = (
    pagination,
    filters,
    sorter,
  ) => {
    const sort = Array.isArray(sorter) ? sorter[0] : sorter;

    onTableStateChange?.({
      page: pagination.current || tableState.page,
      size: pagination.pageSize || tableState.size,
      sortBy: Array.isArray(sort?.field) ? sort?.field[0] : sort.field,
      sortOrder:
        sort.order === 'descend' ? InstantSearchSortOrder.DESC : InstantSearchSortOrder.ASC,
    });
  };

  return (
    <div style={{ padding: '20px 32px' }}>
      <Table<OpportunityType>
        columns={columns}
        dataSource={data}
        loading={isLoading}
        emptyMessage={
          opportunityType === OPPORTUNITY_TYPE.CLOSED_WON ? (
            <Trans>No Closed Won opportunities found</Trans>
          ) : (
            <Trans>No Pipeline opportunities found</Trans>
          )
        }
        error={error}
        pagination={{ current: tableState.page, pageSize: tableState.size, total: totalResults }}
        onChange={handleTableChange}
      />
    </div>
  );
};

export default OpportunityInfluenceTable;
