import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { useState } from 'react';

import { Divider } from '@/components/Divider';
import Form, { TextInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { Text } from '@/components/typography';
import { maxEmailLength } from '@/constants/numbers';
import { getStytchAPIErrorMessage } from '@/error/StytchError';
import { EMAIL_STANDARD_RULES } from '@/helper/ant-rule';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import styles from './LoginForm.module.scss';
import OAuthLogin from './OAuthLogin';
import RateLimit from './RateLimitButton';
import { OAuthProviderType } from './type';

enum FORM_FIELD {
  EMAIL = 'email',
}

type FormType = {
  [FORM_FIELD.EMAIL]: string;
};

type Props = {
  type: 'sign-in' | 'sign-up';
};

const LoginForm = ({ type }: Props) => {
  const [form] = Form.useForm();
  const stytch = useStytchB2BClient();
  const { pushNotification, removeAllNotifications } = useNotification();
  const [isLoading, setLoading] = useState<boolean>(false);
  const isSignUp = type === 'sign-up';

  const handleFinish = async (values: FormType) => {
    const email = values[FORM_FIELD.EMAIL];

    if (email) {
      setLoading(true);
      try {
        await stytch.magicLinks.email.discovery.send({
          email_address: email,
          discovery_redirect_url: `${location.origin}/sign-in?signup=${isSignUp}`,
          login_template_id: isSignUp ? 'c99_discovery_signup' : undefined,
        });

        removeAllNotifications();
        pushNotification({
          type: 'success',
          message: (
            <Trans>
              A one-time access link was sent to&nbsp;
              <Text variant="caption1" weight="extra-bold">
                {email}
              </Text>
              .{' '}
              {isSignUp
                ? t`You'll be able to log in and change your password after verification.`
                : ''}{' '}
              If you don't receive an email, reach out to support@channel99.com or try again.
            </Trans>
          ),
        });
      } catch (e) {
        console.error(e);
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e, ROUTES.signIn.path),
        });
      } finally {
        setLoading(false);
      }
    }
  };

  const handleOAuthProviderClick = async (provider: OAuthProviderType) => {
    try {
      await stytch.oauth[provider].discovery.start({
        discovery_redirect_url: `${location.origin}/sign-in?signup=${isSignUp}`,
      });
    } catch (e) {
      pushNotification({
        type: 'error',
        message: getStytchAPIErrorMessage(e, ROUTES.signIn.path),
      });
    }
  };

  return (
    <Form
      form={form}
      className={styles.form}
      validateTrigger="onSubmit"
      onFinish={handleFinish}
      size="large"
    >
      <OAuthLogin type={type} onOAuthProviderClick={handleOAuthProviderClick} />
      <Divider className={styles.divider} orientation="center">
        <Trans>or</Trans>
      </Divider>
      <Form.Item
        label={<Trans>Business email</Trans>}
        name={FORM_FIELD.EMAIL}
        initialValue=""
        rules={EMAIL_STANDARD_RULES}
        required={false}
      >
        <TextInput
          inputMode="email"
          placeholder={t`name@work-email.com`}
          autoComplete="email"
          spellCheck="false"
          autoCapitalize="none"
          autoFocus
          maxLength={maxEmailLength}
        />
      </Form.Item>
      <RateLimit>
        <Button
          color="green"
          variant="primary"
          size="large"
          type="submit"
          isLoading={isLoading}
          block
        >
          {isSignUp ? <Trans>Sign up</Trans> : <Trans>Sign in</Trans>}
        </Button>
      </RateLimit>
    </Form>
  );
};

export default LoginForm;
