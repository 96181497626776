import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';

import { CampaignActivityDataType } from '@/api/campaign';
import { Flex } from '@/components/Flex';
import { Button } from '@/components/buttons';
import { Tag } from '@/components/typography';
import {
  CAMPAIGN_GROUP_MAX_LIMIT,
  CAMPAIGN_GROUP_MIN_LIMIT,
  CAMPAIGN_SCORE_MAX_LIMIT,
  CAMPAIGN_SCORE_MIN_LIMIT,
} from '@/constants';
import { ROUTES } from '@/router';

type Props = {
  selectedCampaigns: CampaignActivityDataType[];
  onRemoveSelectedCampaign: (campaign: CampaignActivityDataType) => void;
  onScoreCampaigns: (campaigns: string[]) => void;
};

const CampaignTableActionContent = ({
  selectedCampaigns,
  onRemoveSelectedCampaign,
  onScoreCampaigns,
}: Props) => {
  const selectedCampaignIds = useMemo(
    () => selectedCampaigns.map((campaign) => campaign.ad.id),
    [selectedCampaigns],
  );

  const disableScore =
    selectedCampaigns.length < CAMPAIGN_SCORE_MIN_LIMIT ||
    selectedCampaigns.length > CAMPAIGN_SCORE_MAX_LIMIT;
  const disableGroup =
    selectedCampaigns.length < CAMPAIGN_GROUP_MIN_LIMIT ||
    selectedCampaigns.length > CAMPAIGN_GROUP_MAX_LIMIT;

  return (
    <Flex gap="medium" align="start">
      <Flex vertical gap="small">
        <Button
          color="black"
          variant="secondary"
          isDisabled={disableScore}
          onClick={() => onScoreCampaigns(selectedCampaignIds)}
        >
          <Trans>Score {disableScore ? '' : ` (${selectedCampaigns.length})`}</Trans>
        </Button>
        <Button
          color="black"
          variant="secondary"
          isDisabled={disableGroup}
          to={ROUTES.campaignGroupByIds(selectedCampaignIds)}
        >
          <Trans>Group {disableGroup ? '' : ` (${selectedCampaigns.length})`}</Trans>
        </Button>
      </Flex>
      <Flex gap={12} wrap>
        {selectedCampaigns.map((campaign) => (
          <Tag key={campaign.ad.id} closable onClose={() => onRemoveSelectedCampaign(campaign)}>
            {campaign.ad.name}
          </Tag>
        ))}
      </Flex>
    </Flex>
  );
};

export default CampaignTableActionContent;
