import { t } from '@lingui/core/macro';
import { useMemo } from 'react';

import { AudienceOptionType } from '@/api/audience';
import { WebTrafficRevRangeSummaryDataType } from '@/api/webTraffic';
import Flare, { Donut, FlareSeriesOptions, Legend, Tooltip } from '@/components/Flare';
import SolGraphQLError from '@/error/SolGraphQLError';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import {
  COLOR_GRAY_600,
  COLOR_GREEN_400,
  COLOR_GREEN_500,
  COLOR_GREEN_600,
  COLOR_WHITE,
} from '@/styles/palette';

type Props = {
  data?: WebTrafficRevRangeSummaryDataType[];
  audience?: AudienceOptionType;
  isLoading?: boolean;
  error?: SolGraphQLError;
};

const WebTrafficCompanySizeChart = ({ data, audience, isLoading, error }: Props) => {
  const seriesData: FlareSeriesOptions[] | undefined = useMemo(() => {
    if (!data) {
      return undefined;
    }

    return [
      {
        id: 'companies',
        name: t`Companies`,
        data: data.map((slice) => ({
          id: slice.revRange.id,
          name: slice.revRange.shortName,
          data: slice.percentOfTotalCompanies,
          custom: {
            revRangeId: slice.revRange.id,
            visitCount: slice.count,
            companyCount: slice.engagedCompanies,
            visitPercent: slice.percentOfTotal,
          },
        })),
      },
    ];
  }, [data]);

  const totalCompanies = data && data.length > 0 ? data[0].companies : 0;

  return (
    <Flare
      data={seriesData}
      title={`<b>${numberFormat(totalCompanies)}</b><br/>${t`Companies`}`}
      width="auto"
      height="auto"
      colors={[COLOR_GREEN_400, COLOR_GREEN_600, COLOR_GREEN_500, COLOR_GRAY_600]}
      isLoading={isLoading}
      error={error}
    >
      <Legend showByCategory />
      <Donut
        y="data"
        dataLabelColor={COLOR_WHITE}
        dataLabelFormat={(label) => {
          if (label.y && label.y >= 0.02) {
            return numberFormat(label.y, { isPercent: true, precision: 0 });
          }
          return '';
        }}
        hideConnector
        hiddenDataLabelThreshold={0}
      />
      <Tooltip
        titleFormat={(point) => point?.name}
        rowLabelFormat={(point) => `${point?.series?.name}<br/>${t`Visits`}`}
        rowSecondaryValueFormat={(point) =>
          `(${numberFormat(point?.y, { isPercent: true })})<br/>(${numberFormat(point?.custom?.visitPercent, { isPercent: true })})`
        }
        rowValueFormat={(point) =>
          `${numberFormat(point?.custom?.companyCount)}<br/>${numberFormat(point?.custom?.visitCount)}`
        }
        valueLink={(point) => {
          return ROUTES.webTrafficActivityWithParams({
            'revRange.id': point?.custom?.revRangeId,
            'metrics.visits': '[1,]',
            audienceIds: audience?.id,
          });
        }}
        unstable_hasRouterContext={false}
      />
    </Flare>
  );
};

export default WebTrafficCompanySizeChart;
