import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useNavigate } from 'react-router';

import TableActions from '@/components/TableActions';
import { MenuButton } from '@/components/buttons';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';

enum AUDIENCE_TYPE {
  CUSTOM = 'CUSTOM',
  CRM = 'CRM',
}

const AudiencesListHeader = () => {
  const navigate = useNavigate();
  const user = useMe();

  const handleCreateAudience = (key: string) => {
    if (key === AUDIENCE_TYPE.CRM) {
      navigate(ROUTES.audienceImport.path);
      return;
    }

    // default is custom audience
    navigate(ROUTES.audienceCreate.path);
  };

  return (
    <TableActions
      actions={
        user?.isAdmin && (
          <MenuButton
            menuItems={[
              {
                key: AUDIENCE_TYPE.CUSTOM,
                label: t`CSV/File`,
              },
              {
                key: AUDIENCE_TYPE.CRM,
                label: t`CRM`,
              },
            ]}
            onChange={handleCreateAudience}
          >
            <Trans>Create Audience</Trans>
          </MenuButton>
        )
      }
    />
  );
};

export default AudiencesListHeader;
