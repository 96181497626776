import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useMemo } from 'react';
import { useState } from 'react';

import { useCampaignEfficiency } from '@/api/campaign';
import { TRAFFIC_TYPES } from '@/api/common';
import Flare, { Donut, Legend, Tooltip } from '@/components/Flare';
import { getPeriodTime, usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import {
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  UNKNOWN_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';

type Props = {
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignEfficiencyChart = ({ campaignIds, isLoading: isCampaignLoading }: Props) => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();
  const {
    efficiencyData,
    isLoading: isEfficiencyLoading,
    error,
  } = useCampaignEfficiency(campaignIds, startDate, endDate, audience?.id);
  const [disabledSeries, setDisabledSeries] = useState<string[]>([TRAFFIC_TYPES.UNRESOLVED]);

  const isLoading = isCampaignLoading || isEfficiencyLoading;
  const titleValue = audience?.name ? t`Target Audience<br/>Visits` : t`TAM<br/>Visits`;

  const handleSeriesToggle = (series: string, enabled: boolean) => {
    if (enabled) {
      setDisabledSeries(disabledSeries.filter((s) => s !== series));
    } else {
      setDisabledSeries([...disabledSeries, series]);
    }
  };

  const totalEnabledVisits = useMemo(() => {
    let total = 0;

    if (efficiencyData) {
      if (!disabledSeries.includes(TRAFFIC_TYPES.AUDIENCE)) {
        total += efficiencyData.audienceVisits;
      }
      if (!disabledSeries.includes(TRAFFIC_TYPES.OTHER)) {
        total += efficiencyData.otherVisits;
      }
      if (!disabledSeries.includes(TRAFFIC_TYPES.BOT)) {
        total += efficiencyData.botVisits;
      }
      if (!disabledSeries.includes(TRAFFIC_TYPES.UNRESOLVED)) {
        total += efficiencyData.unresolvedVisits;
      }
    }
    return total;
  }, [efficiencyData, audience?.id, disabledSeries]);

  const seriesData = useMemo(() => {
    if (!efficiencyData) {
      return undefined;
    }

    return [
      {
        id: 'visits',
        name: t`Visits`,
        data: [
          {
            id: TRAFFIC_TYPES.AUDIENCE,
            value: efficiencyData.audienceVisits,
            name: audience?.name ? audience.name : t`TAM`,
            color: audience?.name ? AUDIENCE_PRIMARY : TAM_PRIMARY,
            custom: { secondary: efficiencyData.audienceVisits / totalEnabledVisits },
          },
          {
            id: TRAFFIC_TYPES.OTHER,
            value: efficiencyData.otherVisits,
            name: t`Other Companies`,
            color: VISITS_PRIMARY,
            custom: { secondary: efficiencyData.otherVisits / totalEnabledVisits },
          },
          {
            id: TRAFFIC_TYPES.BOT,
            value: efficiencyData.botVisits,
            name: t`Bot`,
            color: BOT_PRIMARY,
            custom: { secondary: efficiencyData.botVisits / totalEnabledVisits },
          },
          {
            id: TRAFFIC_TYPES.UNRESOLVED,
            value: efficiencyData.unresolvedVisits,
            name: t`Unknown`,
            color: UNKNOWN_PRIMARY,
            custom: {
              secondary: efficiencyData.unresolvedVisits / totalEnabledVisits,
            },
          },
        ],
      },
    ];
  }, [efficiencyData, audience?.name, totalEnabledVisits]);

  return (
    <Panel title={<Trans>Visit Efficiency</Trans>} style={{ height: 465 }} noPadding>
      <Flare
        data={seriesData}
        title={`<b>${numberFormat(efficiencyData?.audienceVisits, { compact: true })}</b><br />${titleValue}`}
        width="auto"
        height="auto"
        colors={seriesData ? seriesData?.[0].data.map((d) => d.color) : []}
        noPadding
        isLoading={isLoading}
        error={error}
        disabledSeriesIds={disabledSeries}
      >
        <Legend showByCategory onSeriesToggle={handleSeriesToggle} />
        <Donut y="value" hideDataLabels noBorder noHoverEffect />
        <Tooltip
          titleFormat={() => t`${getPeriodTime(startDate, endDate)} (Visits)`}
          rowLabelFormat={(item) => item?.key}
          rowValueFormat={(item) => {
            return numberFormat(item?.y);
          }}
          rowSecondaryValueFormat={(item) => {
            if (item?.custom?.secondary && item.custom.secondary > 0) {
              return numberFormat(item?.custom.secondary, { isPercent: true, precision: 1 });
            }
          }}
          valueLink={(point) => {
            if (point?.id === TRAFFIC_TYPES.AUDIENCE) {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                audienceIds: audience?.id,
                inTam: audience?.id ? undefined : 'true',
                'metrics.visits': '[1,]',
              });
            }
            if (point?.id === TRAFFIC_TYPES.OTHER) {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                'audienceIds!': audience?.id,
                inTam: audience?.id ? undefined : 'false',
                'metrics.visits': '[1,]',
              });
            }
            if (point?.key === 'total') {
              return ROUTES.webTrafficActivityWithParams({
                campaignIds,
                'metrics.visits': '[1,]',
              });
            }
          }}
          showTotalRow
          unstable_hasRouterContext={false}
          shared
        />
      </Flare>
    </Panel>
  );
};

export default CampaignEfficiencyChart;
