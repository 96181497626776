import { t } from '@lingui/core/macro';
import { Plural, Trans } from '@lingui/react/macro';

import { useCampaignKPIs } from '@/api/campaign';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  campaignIds?: string[];
  isLoading?: boolean;
};

const CampaignDetailKPIMetrics = ({ campaignIds, isLoading: isCampaignsLoading }: Props) => {
  const {
    pageFilters: { start_date: startDate, end_date: endDate, audience },
  } = usePageFilterContext();
  const {
    campaignKPIs,
    isLoading: isKPIsLoading,
    error,
  } = useCampaignKPIs(campaignIds, startDate, endDate, audience?.id);

  const isLoading = isCampaignsLoading || isKPIsLoading;

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={t`Total Visits`}
        value={numberFormat(campaignKPIs?.visits)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Visit Efficiency`}
        infoPopover={{
          body: t`Total visits for the given campaign over all visits.`,
        }}
        value={numberFormat(campaignKPIs?.percentEfficiency, { isPercent: true, precision: 1 })}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Engaged Companies`}
        value={numberFormat(campaignKPIs?.engagedCompanies)}
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`ROMS`}
        infoPopover={{
          body: t`Return on marketing spend is calculated by calculating Pipeline divided by spend.`,
        }}
        value={numberFormat(campaignKPIs?.roms, { isMultiplier: true, precision: 1 })}
        caption={
          <Trans>
            {numberFormat(campaignKPIs?.pipelineInfluenced, { isCurrency: true, precision: 0 })} /{' '}
            {numberFormat(campaignKPIs?.spend, { isCurrency: true, precision: 0 })}
          </Trans>
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Pipeline Influence`}
        value={numberFormat(campaignKPIs?.pipelineInfluenced, { isCurrency: true, precision: 0 })}
        caption={
          campaignKPIs?.pipelineOpportunities != null && (
            <Plural
              value={campaignKPIs?.pipelineOpportunities + ''}
              one="# Opportunity"
              other="# Opportunities"
            />
          )
        }
        isLoading={isLoading}
        error={error}
      />
      <KpiMetric
        label={t`Closed Won Influence`}
        value={numberFormat(campaignKPIs?.closedWonInfluenced, { isCurrency: true, precision: 0 })}
        caption={
          campaignKPIs?.closedWonOpportunities != null && (
            <Plural
              value={campaignKPIs?.closedWonOpportunities + ''}
              one="# Opportunity"
              other="# Opportunities"
            />
          )
        }
        isLoading={isLoading}
        error={error}
      />
    </KpiMetricGroup>
  );
};

export default CampaignDetailKPIMetrics;
