import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';

import Form, { PasswordInput } from '@/components/Form';
import { Button } from '@/components/buttons';
import { maxPasswordLength, sessionDurationMinutes } from '@/constants/numbers';
import {
  STYTCH_API_ERROR_TYPE,
  getStytchAPIErrorMessage,
  isStytchAPIError,
} from '@/error/StytchError';
import useUpdatePassword from '@/hooks/useUpdatePassword';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import RateLimit from './RateLimitButton';
import styles from './ResetPasswordForm.module.scss';
import SignInPanel from './SignInPanel';

type Props = {
  orgSlug: string;
  token: string;
};

enum FORM_FIELD {
  NEW_PASSWORD = 'password',
  NEW_PASSWORD_CONFIRM = 'passwordConfirm',
}

type FormType = {
  [FORM_FIELD.NEW_PASSWORD]: string;
};

const ResetPasswordForm = ({ orgSlug, token }: Props) => {
  const [form] = Form.useForm();
  const { pushNotification } = useNotification();
  const stytch = useStytchB2BClient();
  const { validatePassword, validateConfirmPassword } = useUpdatePassword({
    form,
    newPasswordFieldName: FORM_FIELD.NEW_PASSWORD,
    confirmPasswordFieldName: FORM_FIELD.NEW_PASSWORD_CONFIRM,
  });

  const handleFinish = async (values: FormType) => {
    try {
      await stytch.passwords.resetByEmail({
        password: values[FORM_FIELD.NEW_PASSWORD],
        password_reset_token: token,
        session_duration_minutes: sessionDurationMinutes,
      });
    } catch (e) {
      if (isStytchAPIError(e) && e.error_type === STYTCH_API_ERROR_TYPE.UNABLE_TO_AUTH_MAGIC_LINK) {
        // We want the error message to be a bit different for password resets
        e.error_type = 'unable_to_auth_reset_password';
      }
      pushNotification({
        type: 'error',
        message: getStytchAPIErrorMessage(e, ROUTES.tenantForgotPasswordByOrgSlug(orgSlug)),
      });
    }
  };

  return (
    <Form form={form} size="large" validateTrigger={['onSubmit', 'onBlur']} onFinish={handleFinish}>
      <SignInPanel title={t`Reset your password`}>
        <Form.Item
          className={styles.passwordFormItem}
          label={<Trans>Password</Trans>}
          name={FORM_FIELD.NEW_PASSWORD}
          initialValue=""
          required={false}
          rules={[{ validator: validatePassword }]}
        >
          <PasswordInput autoComplete="new-password" maxLength={maxPasswordLength} autoFocus />
        </Form.Item>
        <Form.Item
          label={<Trans>Confirm Password</Trans>}
          name={FORM_FIELD.NEW_PASSWORD_CONFIRM}
          initialValue=""
          required={false}
          rules={[{ validator: validateConfirmPassword }]}
        >
          <PasswordInput autoComplete="new-password" maxLength={maxPasswordLength} />
        </Form.Item>
        <RateLimit>
          <Button color="green" variant="primary" size="large" type="submit" block>
            <Trans>Continue</Trans>
          </Button>
        </RateLimit>
      </SignInPanel>
    </Form>
  );
};

export default ResetPasswordForm;
