import { t } from '@lingui/core/macro';

import { PipelineVendorInfluenceDataType } from '@/api/pipeline';
import TooltipRow from '@/components/Flare/guides/TooltipRow';
import MultiSeriesTooltip from '@/components/MultiSeriesTooltip';
import { TAM_PRIMARY, VISITS_PRIMARY } from '@/constants/colors';
import { numberFormat } from '@/helper/numberFormatter';

type Props = {
  data: PipelineVendorInfluenceDataType;
};

const PipelineVendorInfluenceCellTooltip = ({ data }: Props) => {
  return (
    <MultiSeriesTooltip title={data.vendor.name}>
      <TooltipRow
        color={TAM_PRIMARY}
        name={t`Opportunity Influence`}
        secondaryValue={`(${numberFormat(data.opportunitiesInfluencedPercent, {
          isPercent: true,
          precision: 1,
        })})`}
        value={numberFormat(data.opportunitiesInfluenced)}
      />
      <TooltipRow
        color={VISITS_PRIMARY}
        name={t`Pipeline $ Influence`}
        secondaryValue={`(${numberFormat(data.pipelineRevenueInfluencePercent, {
          isPercent: true,
          precision: 1,
        })})`}
        value={numberFormat(data.pipelineRevenueInfluence, {
          isCurrency: true,
          precision: 0,
        })}
      />
    </MultiSeriesTooltip>
  );
};

export default PipelineVendorInfluenceCellTooltip;
