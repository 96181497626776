import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { useState } from 'react';

import { AudienceGroupedOptionType } from '@/api/audience';
import { TRAFFIC_TYPES } from '@/api/common';
import { usePixelTargetingEfficiency } from '@/api/pixel';
import { Legend, LegendItem } from '@/components/Legend';
import DonutContainer from '@/components/charts/DonutContainer';
import { getPeriodTime, usePageFilterContext } from '@/components/page';
import { Panel } from '@/components/panels';
import {
  ALL_PRIMARY,
  AUDIENCE_PRIMARY,
  BOT_PRIMARY,
  TAM_PRIMARY,
  VISITS_PRIMARY,
} from '@/constants/colors';

import PixelTargetingEfficiencyAudienceDonut from './PixelTargetingEfficiencyAudienceDonut';
import PixelTargetingEfficiencyTAMDonut from './PixelTargetingEfficiencyTAMDonut';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  isLoading: boolean;
};

const PixelTargetingEfficiencyChart = ({ pixelIds, audience, isLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const [disabledSeries, setDisabledSeries] = useState<string[]>([TRAFFIC_TYPES.UNRESOLVED]);
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const audienceIds = audience?.audienceIds;

  const {
    pixelTargetingEfficiencyData: data,
    isLoading: isDonutDataLoading,
    error,
  } = usePixelTargetingEfficiency(pixelIds, startDate, endDate, audienceIds);

  const dateRangeLabel = getPeriodTime(startDate, endDate);

  return (
    <Panel
      size="L"
      title={<Trans>Targeting Efficiency</Trans>}
      infoPopover={{
        body: <Trans>% of Impressions served to your audience.</Trans>,
      }}
      style={{ height: 465 }}
      noPadding
    >
      {data?.all != null && (
        <Legend defaultDisabledValues={disabledSeries} onDisabledSeriesChange={setDisabledSeries}>
          {audience && (
            <LegendItem
              value={TRAFFIC_TYPES.AUDIENCE}
              color={AUDIENCE_PRIMARY}
              name={audience.name}
            />
          )}
          <LegendItem value={TRAFFIC_TYPES.TAM} color={TAM_PRIMARY} name={t`TAM`} />
          <LegendItem
            value={TRAFFIC_TYPES.OTHER}
            color={VISITS_PRIMARY}
            name={t`Other Companies`}
          />
          <LegendItem value={TRAFFIC_TYPES.BOT} color={BOT_PRIMARY} name={t`Bot`} />
          <LegendItem value={TRAFFIC_TYPES.UNRESOLVED} color={ALL_PRIMARY} name={t`Unknown`} />
        </Legend>
      )}
      <DonutContainer error={error}>
        {audience && data?.all != null && (
          <PixelTargetingEfficiencyAudienceDonut
            pixelIds={pixelIds}
            audienceIds={audienceIds}
            data={data}
            audience={audience}
            tooltipTitle={t`${dateRangeLabel} (Impressions)`}
            disabledSeries={disabledSeries}
            isLoading={isDonutDataLoading || isLoading}
          />
        )}
        {data?.all != null && (
          <PixelTargetingEfficiencyTAMDonut
            pixelIds={pixelIds}
            data={data}
            tooltipTitle={t`${dateRangeLabel} (Impressions)`}
            disabledSeries={disabledSeries}
            isLoading={isDonutDataLoading || isLoading}
          />
        )}
      </DonutContainer>
    </Panel>
  );
};

export default PixelTargetingEfficiencyChart;
