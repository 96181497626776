import { Trans } from '@lingui/react/macro';
import { Children, PropsWithChildren, cloneElement, isValidElement, useState } from 'react';

import SortDownIcon from '@/assets/svg/bx-sort-down.svg?react';
import SortUpIcon from '@/assets/svg/bx-sort-up.svg?react';
import { ActionButton, Button } from '@/components/buttons';

import styles from './FilterDropdown.module.scss';
import { FilterDropdownProps } from './types';

const FilterDropdown = ({
  children,
  isSortable,
  filter,
  sortDirection,
  onClose,
  onConfirm,
  onFilterChange,
  onSortChange,
}: PropsWithChildren<FilterDropdownProps>) => {
  const [isValid, setIsValid] = useState(true);

  const handleCancel = () => {
    onClose();
  };

  const handleCommitChanges = () => {
    // Close the modal and commit the changes
    onConfirm?.({ closeDropdown: true });
  };

  const childrenWithProps = Children.map(children, (child) => {
    if (isValidElement(child)) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      return cloneElement<any>(child, {
        value: filter,
        onChange: onFilterChange,
        onValidChange: setIsValid,
      });
    }
    return child;
  });

  return (
    <div data-testid="filter-dropdown" className={styles.dropdownBody}>
      {isSortable && (
        <div className={styles.dropdownHeader}>
          <ActionButton
            color="green"
            icon={<SortDownIcon />}
            isSelected={sortDirection === 'ascend'}
            onClick={() => onSortChange?.('ascend')}
          >
            <Trans>Sort A to Z</Trans>
          </ActionButton>
          <ActionButton
            color="green"
            icon={<SortUpIcon />}
            isSelected={sortDirection === 'descend'}
            onClick={() => onSortChange?.('descend')}
          >
            <Trans>Sort Z to A</Trans>
          </ActionButton>
        </div>
      )}
      <div className={styles.dropdownContent}>{childrenWithProps}</div>
      <div className={styles.dropdownFooter}>
        <Button color="black" variant="secondary" onClick={handleCancel}>
          <Trans>Cancel</Trans>
        </Button>
        <Button color="black" variant="primary" isDisabled={!isValid} onClick={handleCommitChanges}>
          <Trans>Apply</Trans>
        </Button>
      </div>
    </div>
  );
};

export default FilterDropdown;
