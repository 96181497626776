import { t } from '@lingui/core/macro';
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router';

import PageSpinner from '@/components/PageSpinner';
import { FullPage } from '@/components/page';
import { useMe } from '@/providers/User';
import { ROUTES } from '@/router';

import Organization404 from './Organization404';
import TenantAuthenticate from './TenantAuthenticate';
import TenantLoginForm from './TenantLoginForm';
import useOrganizationMetadataBySlug from './useOrganizationMetadataBySlug';

const TenantSignIn = () => {
  const { orgId } = useParams();
  const navigate = useNavigate();
  const user = useMe();
  const { data, isLoading, error } = useOrganizationMetadataBySlug({ slug: orgId });

  useEffect(() => {
    if (user) {
      navigate(ROUTES.app.path);
    }
  }, [user]);

  return (
    <TenantAuthenticate>
      <FullPage title={t`Account Sign In`}>
        {isLoading && <PageSpinner />}
        {error && <Organization404 />}
        {data && <TenantLoginForm org={data} />}
      </FullPage>
    </TenantAuthenticate>
  );
};

export default TenantSignIn;
