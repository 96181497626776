import { plural, t } from '@lingui/core/macro';

import { AudienceGroupedOptionType } from '@/api/audience';
import {
  usePixelImpressionTrend,
  usePixelOpportunitiesInfluenced,
  usePixelReachEngagementTrend,
  usePixelTargetingEfficiency,
} from '@/api/pixel';
import { KpiMetric, KpiMetricGroup } from '@/components/kpi';
import { usePageFilterContext } from '@/components/page';
import { numberFormat } from '@/helper/numberFormatter';
import { ROUTES } from '@/router';
import { WebTrafficSearchParamType } from '@/router/searchParams/webTrafficSearchParams';

type Props = {
  pixelIds?: string[];
  audience?: AudienceGroupedOptionType;
  isLoading: boolean;
};

const PixelDetailKPIMetrics = ({ pixelIds, audience, isLoading: isPixelLoading }: Props) => {
  const { pageFilters } = usePageFilterContext();
  const { start_date: startDate, end_date: endDate } = pageFilters;
  const audienceIds = audience?.audienceIds;

  const {
    pixelReachEngagementTrend,
    isLoading: isReachEngagementLoading,
    error: reachEngagementError,
  } = usePixelReachEngagementTrend(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelImpressionTrend,
    isLoading: isImpressionTrendLoading,
    error: pixelImpressionError,
  } = usePixelImpressionTrend(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelTargetingEfficiencyData,
    isLoading: isTargetingEfficiencyLoading,
    error: targetingEfficiencyError,
  } = usePixelTargetingEfficiency(pixelIds, startDate, endDate, audienceIds);
  const {
    pixelOpportunitiesInfluenced,
    isLoading: isOpportunitiesInfluencedLoading,
    error: oppInfluencedError,
  } = usePixelOpportunitiesInfluenced(pixelIds, startDate, endDate, audienceIds);

  const lastTrendItem = pixelReachEngagementTrend?.at(-1);
  const reached = lastTrendItem?.reach;
  const engaged = lastTrendItem?.engagement;
  const totalSize = lastTrendItem?.audienceSize;

  const defaultFilters: Partial<WebTrafficSearchParamType> = {
    pixelIds,
    audienceIds: audience?.audienceIds,
  };

  return (
    <KpiMetricGroup>
      <KpiMetric
        label={t`Total Impressions`}
        value={pixelImpressionTrend && numberFormat(pixelImpressionTrend.totalImpressions)}
        isLoading={isImpressionTrendLoading || isPixelLoading}
        error={pixelImpressionError}
      />
      <KpiMetric
        label={t`Efficiency`}
        value={
          pixelTargetingEfficiencyData &&
          numberFormat(
            pixelTargetingEfficiencyData.audience /
              Math.max(
                (pixelTargetingEfficiencyData.all ?? 0) -
                  (pixelTargetingEfficiencyData.unresolved ?? 0),
                1, // avoid division by zero
              ),
            { isPercent: true, precision: 1 },
          )
        }
        isLoading={isTargetingEfficiencyLoading || isPixelLoading}
        error={targetingEfficiencyError}
      />
      <KpiMetric
        label={t`Reached Companies`}
        value={reached && numberFormat(reached)}
        valueLink={
          !!reached &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'metrics.impressions': '[1,]',
          })
        }
        caption={
          reached &&
          totalSize &&
          t`${numberFormat(reached / totalSize, { isPercent: true, precision: 1 })} of ${numberFormat(totalSize)}`
        }
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Engaged Companies`}
        value={engaged && numberFormat(engaged)}
        valueLink={
          !!engaged &&
          ROUTES.webTrafficActivityWithParams({
            ...defaultFilters,
            'metrics.impressions': '[1,]',
            isEngaged: 'true',
          })
        }
        caption={
          engaged &&
          totalSize &&
          t`${numberFormat(engaged / totalSize, { isPercent: true, precision: 1 })} of ${numberFormat(totalSize)}`
        }
        isLoading={isReachEngagementLoading || isPixelLoading}
        error={reachEngagementError}
      />
      <KpiMetric
        label={t`Pipeline Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.pipeline.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.pipeline.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
      <KpiMetric
        label={t`Closed Won Influence`}
        value={
          pixelOpportunitiesInfluenced &&
          numberFormat(pixelOpportunitiesInfluenced.recentDeals.totalRevenue, {
            isCurrency: true,
            precision: 0,
          })
        }
        caption={plural(pixelOpportunitiesInfluenced?.recentDeals.totalOpportunities || 0, {
          one: '# Opportunity',
          other: '# Opportunities',
        })}
        isLoading={isOpportunitiesInfluencedLoading || isPixelLoading}
        error={oppInfluencedError}
      />
    </KpiMetricGroup>
  );
};

export default PixelDetailKPIMetrics;
