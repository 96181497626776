import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Col, Row } from 'antd';
import { useState } from 'react';

import { RegionType, RevenueRangeType, SectorType } from '@/api/common';
import {
  InstanceUpdateVariablesType,
  mutateCurrentInstanceUpdate,
  useCurrentInstance,
  useCurrentInstanceSettings as useCurrentInstanceDetails,
} from '@/api/organization';
import Form from '@/components/Form';
import PageSpinner from '@/components/PageSpinner';
import Prompt from '@/components/Prompt';
import PageError from '@/components/errors/PageError';
import { Page } from '@/components/page';
import useErrorDisplay from '@/error/useErrorDisplay';
import { useNotification } from '@/providers/Notification';
import { useMe } from '@/providers/User';

import CompanyOpportunitySettings from './CompanyOpportunitySettings';
import styles from './CompanySettings.module.scss';
import CompanySettingsActionBar from './CompanySettingsActionBar';
import CompanySettingsGeneralInfo from './CompanySettingsGeneralInfo';
import CompanySettingsTAM from './CompanySettingsTAM';
import CompanySettingsTag from './CompanySettingsTag';
import CompanyVisitExclusions from './CompanyVisitExclusions';

export enum FORM_FIELD {
  COMPANY_NAME = 'name',
  SECTORS = 'sectors',
  REGIONS = 'regions',
  REVENUE_RANGES = 'revRanges',
  PIPELINE_REPORT_LINK = 'pipelineReportLink',
  OPPORTUNITY_INFLUENCE_WINDOW = 'opportunityInfluenceWindow',
  OPPORTUNITY_AMOUNT_FIELD = 'opportunityAmountField',
  VISIT_EXCLUSIONS = 'visitExclusions',
}

export type FormType = {
  [FORM_FIELD.COMPANY_NAME]: string;
  [FORM_FIELD.SECTORS]: SectorType[];
  [FORM_FIELD.REGIONS]: RegionType[];
  [FORM_FIELD.REVENUE_RANGES]: RevenueRangeType[];
  [FORM_FIELD.PIPELINE_REPORT_LINK]: string;
  [FORM_FIELD.OPPORTUNITY_INFLUENCE_WINDOW]: number;
  [FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD]: string;
  [FORM_FIELD.VISIT_EXCLUSIONS]: string[];
};

const CompanySettings = () => {
  const { pushNotification, removeNotification } = useNotification();
  const user = useMe();
  const { instanceDetails, isLoading, error, mutate: mutateDetails } = useCurrentInstanceDetails();
  const { instance, mutate } = useCurrentInstance();
  const [form] = Form.useForm<FormType>();
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isFormDirty, setFormDirty] = useState<boolean>(false);
  const displayErrors = useErrorDisplay();

  const handleEditChange = (desiresEditMode: boolean) => {
    setIsEditMode(desiresEditMode);

    if (desiresEditMode) {
      removeNotification({ type: 'success' });
    }
  };

  const handleCancel = () => {
    handleEditChange(false);
    setFormDirty(false);
    form.resetFields();
  };

  const handleError = () => {
    pushNotification({
      type: 'error',
      message: t`Sorry, we were unable to update your settings due to an error. Please review your changes and retry.`,
    });
  };

  const handleFinish = async (values: FormType) => {
    setIsSaving(true);
    removeNotification({ type: 'error' });

    try {
      const name = values[FORM_FIELD.COMPANY_NAME];
      const regions = values[FORM_FIELD.REGIONS];
      const sectors = values[FORM_FIELD.SECTORS];
      const revRanges = values[FORM_FIELD.REVENUE_RANGES];
      const newTAM = { regions, sectors, revRanges };

      const accountSettings: InstanceUpdateVariablesType = {
        instance: {
          name,
          tam: {
            regions: regions.map((region) => region.id),
            revRanges: revRanges.map((revRange) => revRange.id),
            sectors: sectors.map((sector) => sector.id),
          },
          pipelineReport: {
            url: values[FORM_FIELD.PIPELINE_REPORT_LINK],
            fieldName: values[FORM_FIELD.OPPORTUNITY_AMOUNT_FIELD],
            influenceWindow: values[FORM_FIELD.OPPORTUNITY_INFLUENCE_WINDOW],
          },
          visitExclusions: values[FORM_FIELD.VISIT_EXCLUSIONS],
        },
      };

      await mutateCurrentInstanceUpdate(accountSettings);

      pushNotification({
        type: 'success',
        message: t`Success! Company settings have been saved.`,
      });
      removeNotification({ type: 'error' });

      setIsSaving(false);
      handleEditChange(false);
      setFormDirty(false);

      await mutateDetails({
        instances: {
          current: {
            ...instanceDetails!,
            name,
            tam: newTAM,
          },
        },
      });

      if (instance && instance.name !== name) {
        // Update the name on the cached instance in case it changed
        mutate({ instances: { current: { ...instance, name } } }, { revalidate: false });
      }
    } catch (e) {
      displayErrors({ error: e });
      setIsSaving(false);
    }
  };

  return (
    <Page title={t`Settings`} pageName={t`Your Company`}>
      {isLoading ? (
        <PageSpinner />
      ) : error ? (
        <PageError
          message={<Trans>An error occurred while loading your Company Settings</Trans>}
          detail={<Trans>Please refresh the page and try again</Trans>}
        />
      ) : (
        instanceDetails && (
          <Form
            form={form}
            validateTrigger="onSubmit"
            className={styles.content}
            onFieldsChange={() => setFormDirty(true)}
            onFinishFailed={handleError}
            onFinish={handleFinish}
          >
            <Prompt
              message={t`Are you sure you want to exit without saving your TAM Settings?`}
              shouldPreventNavigation={isEditMode && isFormDirty}
            />
            {user?.isAdmin && (
              <CompanySettingsActionBar
                isEditMode={isEditMode}
                isSaveEnabled={isFormDirty}
                isSaving={isSaving}
                onEditModeChange={handleEditChange}
                onCancel={handleCancel}
              />
            )}
            <Row gutter={[24, 32]}>
              <Col md={12} sm={24} xs={24}>
                <CompanySettingsGeneralInfo
                  instance={instanceDetails}
                  isEditMode={isEditMode}
                  isSaving={isSaving}
                />
              </Col>
              <Col md={12} sm={24} xs={24}>
                <CompanySettingsTAM
                  tam={instanceDetails.tam}
                  isEditMode={isEditMode}
                  isSaving={isSaving}
                />
              </Col>
              <Col xs={24}>
                <CompanyOpportunitySettings
                  isEditMode={isEditMode}
                  isSaving={isSaving}
                  data={instanceDetails.pipelineReport}
                />
              </Col>
              <Col xs={24}>
                <CompanyVisitExclusions
                  isEditMode={isEditMode}
                  isSaving={isSaving}
                  data={instanceDetails.visitExclusions}
                />
              </Col>
              <Col xs={24}>
                <CompanySettingsTag instance={instanceDetails} />
              </Col>
            </Row>
          </Form>
        )
      )}
    </Page>
  );
};

export default CompanySettings;
