import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import { TAMDataType } from '@/api/organization';
import Form from '@/components/Form';
import { Panel } from '@/components/panels';
import { Text } from '@/components/typography';

import { FORM_FIELD } from './CompanySettings';
import IndustryMultiSelect from './IndustryMultiSelect';
import RegionMultiSelect from './RegionMultiSelect';
import RevRangeMultiSelect from './RevRangeMultiSelect';

type Props = {
  tam: TAMDataType;
  isEditMode: boolean;
  isSaving: boolean;
};

const CompanySettingsTAM = ({ tam, isEditMode, isSaving }: Props) => {
  return (
    <Panel title={t`Total Addressable Market (TAM)`} style={{ height: '100%' }}>
      <Form.Item
        label={<Trans>Industries</Trans>}
        name={FORM_FIELD.SECTORS}
        initialValue={tam.sectors ?? []}
        rules={[{ required: true, type: 'array', message: t`Please select at least one industry` }]}
        required={false}
      >
        {isEditMode ? (
          <IndustryMultiSelect isDisabled={isSaving} />
        ) : (
          <Text variant="body1">{tam.sectors.map((d) => d.name).join(', ')}</Text>
        )}
      </Form.Item>
      <Form.Item
        label={<Trans>Regions</Trans>}
        name={FORM_FIELD.REGIONS}
        initialValue={tam.regions ?? []}
        rules={[{ required: true, type: 'array', message: t`Please select at least one region` }]}
        required={false}
      >
        {isEditMode ? (
          <RegionMultiSelect isDisabled={isSaving} />
        ) : (
          <Text variant="body1">{tam.regions.map((d) => d.name).join(', ')}</Text>
        )}
      </Form.Item>
      <Form.Item
        label={<Trans>Company Size</Trans>}
        name={FORM_FIELD.REVENUE_RANGES}
        initialValue={tam.revRanges ?? []}
        rules={[
          { required: true, type: 'array', message: t`Please select at least one company size` },
        ]}
        required={false}
      >
        {isEditMode ? (
          <RevRangeMultiSelect isDisabled={isSaving} />
        ) : (
          <Text variant="body1">{tam.revRanges.map((d) => d.name).join(', ')}</Text>
        )}
      </Form.Item>
    </Panel>
  );
};

export default CompanySettingsTAM;
