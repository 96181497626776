import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';
import { Fragment } from 'react/jsx-runtime';

import Delete from '@/assets/svg/trashcan.svg?react';
import { Flex } from '@/components/Flex';
import Form, { TextInput } from '@/components/Form';
import { ActionIcon, Button } from '@/components/buttons';
import { Panel } from '@/components/panels';
import { Text } from '@/components/typography';

import { FORM_FIELD } from './CompanySettings';
import styles from './CompanyVisitExclusions.module.scss';

type Props = {
  isEditMode: boolean;
  isSaving: boolean;
  data?: string[];
};

const CompanyVisitExclusions = ({ isEditMode, isSaving, data }: Props) => {
  return (
    <Panel
      title={t`Visit Exclusions (Optional)`}
      infoPopover={{
        title: t`Visit Exclusions`,
        body: (
          <Trans>
            Visit exclusions are used to remove visits from reporting if the visitor views specific
            URLs. This will reduce the number of total visits reported within the platform. Any URL
            containing your input will match. To add another URL exclusion click the Add button
            while editing.
          </Trans>
        ),
      }}
    >
      <Form.List name={FORM_FIELD.VISIT_EXCLUSIONS} initialValue={data}>
        {(fields, { add, remove }) => (
          <>
            <Text variant="subhead">
              <Trans>URL Exclusions</Trans>
            </Text>
            <Flex vertical align="start" gap="small">
              {fields.length === 0 && (
                <Text variant="body1" color="grey" italic weight="extra-light">
                  <Trans>no current URL visit exclusions</Trans>
                </Text>
              )}
              {fields.map(({ key, ...field }, index) => (
                <Fragment key={key}>
                  {index !== 0 && (
                    <Text className={styles.orSeparator} variant="caption1">
                      <Trans>or</Trans>
                    </Text>
                  )}
                  <Flex gap="small" align="start" fullWidth>
                    <Form.Item
                      {...field}
                      className={styles.field}
                      layout="horizontal"
                      validateTrigger={['onChange', 'onBlur']}
                      style={{ width: '100%' }}
                      rules={[
                        {
                          required: true,
                          whitespace: true,
                          message: t`URL exclusion path is required (e.g. /careers)`,
                        },
                      ]}
                      required={false}
                    >
                      {isEditMode ? (
                        <TextInput
                          placeholder={t`Type URL Path`}
                          size="medium"
                          isDisabled={isSaving}
                        />
                      ) : (
                        <Text variant="body1" />
                      )}
                    </Form.Item>
                    {isEditMode && (
                      <ActionIcon
                        aria-label={t`Delete`}
                        color="red"
                        icon={<Delete />}
                        isDisabled={isSaving}
                        size="large"
                        onClick={() => remove(index)}
                      />
                    )}
                  </Flex>
                </Fragment>
              ))}
              {isEditMode && (
                <Button
                  className={styles.addButton}
                  color="green"
                  variant="primary"
                  isDisabled={isSaving}
                  onClick={() => add('')}
                >
                  <Trans>Add +</Trans>
                </Button>
              )}
            </Flex>
          </>
        )}
      </Form.List>
    </Panel>
  );
};

export default CompanyVisitExclusions;
