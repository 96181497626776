import { Trans } from '@lingui/react/macro';
import { useState } from 'react';
import { Fragment } from 'react/jsx-runtime';

import {
  INTEGRATION_PROVIDER_ID,
  IntegrationProviderType,
  useIntegrationProviderDetails,
} from '@/api/integration';
import DemandbaseInstructionsModal from '@/app/integrations/:provider/DemandbaseInstructionsModal';
import PlusIcon from '@/assets/svg/plus.svg?react';
import { Divider } from '@/components/Divider';
import { Button } from '@/components/buttons';
import { Text, Title } from '@/components/typography';

import IntegrationStatus from '../IntegrationStatus';
import { getProviderLogoById, getProviderShortDescriptionById } from '../providerUtil';
import IntegrationConnectionListItem from './IntegrationConnectionListItem';
import styles from './IntegrationDetailRail.module.scss';

type Props = {
  provider: IntegrationProviderType;
};

const IntegrationDetailRail = ({ provider }: Props) => {
  const { mutate } = useIntegrationProviderDetails(provider.id);
  const [showDemandbaseInstructionsModal, setShowDemandbaseInstructionsModal] = useState(false);

  const numConnections = provider?.connections.length ?? 0;
  const workingConnections = provider?.connections.filter((conn) => conn.status === 'active');
  const hasNoConnections = numConnections === 0;
  const numWorkingConnections = workingConnections?.length ?? 0;
  const numBrokenConnections = numConnections - numWorkingConnections;

  const handleAddConnection = () => {
    if (provider.id === INTEGRATION_PROVIDER_ID.DEMANDBASE_ADS) {
      setShowDemandbaseInstructionsModal(true);
    } else {
      window.location.href = provider?.startUrl;
    }
  };

  const handleAddDemandbaseConnection = () => {
    setShowDemandbaseInstructionsModal(false);
    window.location.href = provider?.startUrl;
  };

  const handleDisconnect = async () => {
    mutate();
  };

  return (
    <div data-testid="integration-detail-rail" className={styles.rail}>
      <div className={styles.railHeading}>
        {getProviderLogoById(provider?.id)}
        <Title level="3" weight="semi-bold">
          {provider?.name}
        </Title>
      </div>
      {provider?.multiConnection ? (
        <>
          {numWorkingConnections > 0 && (
            <IntegrationStatus status="active" count={numWorkingConnections} />
          )}
          {numBrokenConnections > 0 && (
            <IntegrationStatus status="broken" count={numBrokenConnections} />
          )}
          {hasNoConnections && (
            <>
              <IntegrationStatus status="notConnected" />
              <Text variant="body2" color="dark-grey">
                {getProviderShortDescriptionById(provider?.id)}
              </Text>
            </>
          )}
        </>
      ) : (
        <>
          <IntegrationStatus status={numWorkingConnections > 0 ? 'active' : 'notConnected'} />
          <Text variant="body2" color="dark-grey">
            {getProviderShortDescriptionById(provider?.id)}
          </Text>
        </>
      )}
      {provider?.connections.map((conn) => (
        <Fragment key={conn.id}>
          <Divider type="horizontal" orientation="center" />
          <IntegrationConnectionListItem
            provider={provider}
            connection={conn}
            onDisconnect={handleDisconnect}
          />
        </Fragment>
      ))}
      <Divider />
      {(hasNoConnections || provider?.multiConnection) && (
        <Button color="black" variant="secondary" icon={<PlusIcon />} onClick={handleAddConnection}>
          <Trans>{provider?.name} Account</Trans>
        </Button>
      )}
      {showDemandbaseInstructionsModal && (
        <DemandbaseInstructionsModal
          open={showDemandbaseInstructionsModal}
          onClose={() => setShowDemandbaseInstructionsModal(false)}
          onOk={handleAddDemandbaseConnection}
        />
      )}
    </div>
  );
};

export default IntegrationDetailRail;
