import { Trans } from '@lingui/react/macro';
import classNames from 'classnames';
import { CSSProperties, PropsWithChildren, ReactNode, useRef } from 'react';

import { useCurrentInstance } from '@/api/organization';
import InfoPopover from '@/components/InfoPopover';
import WidgetError from '@/components/errors/WidgetError';
import { Text } from '@/components/typography';
import useOnClickOutside from '@/hooks/useOnClickOutside';

import styles from './Panel.module.scss';
import PanelDrawer from './PanelDrawer';

export type PanelProps = PropsWithChildren<{
  className?: string;
  headerClassName?: string | false;
  actionsClassName?: string | false;
  style?: CSSProperties;
  title: string | ReactNode;
  infoPopover?: {
    title?: ReactNode; // use this if you want a different title for this than `title`
    body: ReactNode;
  };
  size?: 'M' | 'L';
  actions?: ReactNode;
  isRounded?: boolean;
  noPadding?: boolean;
  isFullHeight?: boolean;
  verifyC99Tag?: boolean;
  isDrawerOpen?: boolean;
  drawerContent?: ReactNode;
  onDrawerOpenChange?: (isOpen: boolean) => void;
}>;

const Panel = ({
  className = '',
  headerClassName = '',
  actionsClassName = '',
  style = {},
  size = 'M',
  title,
  infoPopover,
  actions,
  isRounded = false,
  noPadding = false,
  isFullHeight = false,
  verifyC99Tag = false,
  isDrawerOpen = false,
  drawerContent,
  children,
  onDrawerOpenChange,
  ...rest
}: PanelProps) => {
  const { instance } = useCurrentInstance();
  const drawerRef = useRef<HTMLDivElement>(null);
  useOnClickOutside(drawerRef.current, () => {
    onDrawerOpenChange?.(false);
  });

  return (
    <div
      {...rest}
      className={classNames(styles.panel, className, {
        [styles.rounded]: isRounded,
        [styles.isFullHeight]: isFullHeight,
        [styles.isDrawerOpen]: isDrawerOpen,
      })}
      style={style}
    >
      {title != null && (
        <div
          className={classNames(styles.header, headerClassName, {
            [styles.large]: size === 'L',
            [styles.hasActions]: !!actions,
          })}
        >
          <Text variant="label" className={styles.title}>
            {title}
            {infoPopover && (
              <InfoPopover title={infoPopover.title ?? title} body={infoPopover.body} />
            )}
          </Text>
          {actions && <div className={classNames(styles.actions, actionsClassName)}>{actions}</div>}
        </div>
      )}
      <div className={classNames(styles.content, { [styles.noPadding]: noPadding })}>
        {verifyC99Tag && !instance?.tagInstallationDate ? (
          <WidgetError
            message={<Trans>Please place the C99 Tag or make this request to your admin.</Trans>}
          />
        ) : (
          children
        )}
      </div>
      {drawerContent && (
        <PanelDrawer ref={drawerRef} isOpen={isDrawerOpen} onOpenChange={onDrawerOpenChange}>
          {drawerContent}
        </PanelDrawer>
      )}
    </div>
  );
};

export default Panel;
