import { t } from '@lingui/core/macro';
import { Trans } from '@lingui/react/macro';

import { InstanceShortDataType } from '@/api/organization';
import { InstantSearchTable } from '@/components/InstantSearch';
import { ColumnsType } from '@/components/Table';
import { MonthDateYearFormat } from '@/constants/formats';
import { formatDate } from '@/helper/dateHelper';

type Props = {
  selectedRow: InstanceShortDataType | undefined;
  data?: InstanceShortDataType[];
  totalResults?: number;
  isLoading?: boolean;
  error?: Error;
  onSelectedRowChange: (org: InstanceShortDataType) => void;
};

const LoginAsTable = ({
  selectedRow,
  data,
  totalResults,
  isLoading,
  error,
  onSelectedRowChange,
}: Props) => {
  const columns: ColumnsType<InstanceShortDataType> = [
    {
      title: t`Customer Instance`,
      key: 'name',
      fixed: 'left',
      sorter: true,
    },
    {
      title: t`Domain`,
      key: 'domain',
    },
    {
      title: t`Instance Id`,
      key: 'id',
    },
    {
      title: t`Created On`,
      key: 'createdAt',
      sorter: true,
      render: (text) => formatDate(text, MonthDateYearFormat),
    },
  ];

  return (
    <InstantSearchTable<InstanceShortDataType>
      columns={columns}
      dataSource={data}
      totalResults={totalResults}
      loading={isLoading}
      emptyMessage={<Trans>No customer instances found</Trans>}
      error={error}
      rowKey={(record) => record.id}
      rowCursor="pointer"
      rowSelection={{
        type: 'radio',
        selectedRowKeys: selectedRow ? [selectedRow.id] : [],
        onSelect: (record) => onSelectedRowChange(record),
      }}
      onRow={(record) => ({ onClick: () => onSelectedRowChange(record) })}
    />
  );
};

export default LoginAsTable;
