import { t } from '@lingui/core/macro';
import { useStytchB2BClient } from '@stytch/react/dist/b2b';
import { PropsWithChildren, useEffect, useState } from 'react';
import { useLocation, useNavigate, useParams, useSearchParams } from 'react-router';

import { FullPage } from '@/components/page';
import { sessionDurationMinutes } from '@/constants/numbers';
import { getStytchAPIErrorMessage, isStytchAPIError } from '@/error/StytchError';
import { useNotification } from '@/providers/Notification';
import { ROUTES } from '@/router';

import ResetPasswordForm from '../ResetPasswordForm';

/**
 * Wraps the TenantLogin page and looks for any search params on the URL placed by Stytch after a
 * redirect. If it finds them, it can either redirect to a different url or render the contents of
 * the resulting form.
 *
 * If no Stytch search params are found on the URL, this component passes through `children`
 * instead.
 */
const TenantAuthenticate = ({ children }: PropsWithChildren) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { orgId: orgSlug } = useParams();
  const [searchParams] = useSearchParams();
  const stytch = useStytchB2BClient();
  const { pushNotification } = useNotification();
  const [passwordResetToken, setPasswordResetToken] = useState<string | undefined>();

  const tokenType = searchParams.get('stytch_token_type');
  const token = searchParams.get('token');

  const clearURLSearchParams = () => {
    navigate(location.pathname, { replace: true });
  };

  const handleMultiTenantPasswords = async (token: string) => {
    setPasswordResetToken(token);
  };

  const handleOAuth = async (token: string) => {
    try {
      await stytch.oauth.authenticate({
        oauth_token: token,
        session_duration_minutes: sessionDurationMinutes,
      });

      await stytch.self.get();

      navigate(ROUTES.app.path, { replace: true });
    } catch (e) {
      pushNotification({
        type: 'error',
        message: getStytchAPIErrorMessage(e, ROUTES.tenantForgotPasswordByOrgSlug(orgSlug!)),
      });
    }
  };

  const authenticate = async () => {
    try {
      if (!token) {
        throw new Error('Malformed redirect link');
      }

      switch (tokenType) {
        case 'multi_tenant_passwords':
          handleMultiTenantPasswords(token);
          clearURLSearchParams();
          break;
        case 'oauth':
          handleOAuth(token);
          clearURLSearchParams();
          break;
      }
    } catch (e) {
      if (isStytchAPIError(e)) {
        pushNotification({
          type: 'error',
          message: getStytchAPIErrorMessage(e, ROUTES.tenantForgotPasswordByOrgSlug(orgSlug!)),
        });
      }
    }
  };

  useEffect(() => {
    if (orgSlug && token && tokenType) {
      authenticate();
    }
  }, [orgSlug, token, tokenType]);

  if (passwordResetToken && orgSlug) {
    return (
      <FullPage title={t`Reset password`}>
        <ResetPasswordForm orgSlug={orgSlug} token={passwordResetToken} />
      </FullPage>
    );
  }

  return <>{children}</>;
};

export default TenantAuthenticate;
