import { Trans } from '@lingui/react/macro';
import classNames from 'classnames';
import Highcharts, { Options } from 'highcharts';
import HighchartsReact from 'highcharts-react-official';
import { PropsWithChildren, useCallback, useMemo, useRef, useState } from 'react';

import AutoSizer, { SizeType } from '@/components/AutoSizer';
import Spin from '@/components/Spin';
import WidgetError from '@/components/errors/WidgetError';
import { VISUALISATION_CHART_COLORS } from '@/constants/colors';
import { COLOR_AQUA_400, COLOR_GREEN_500 } from '@/styles/palette';

import styles from './Flare.module.scss';
import { FlareContextProvider } from './FlareContext';
import FlareEmptyMessage from './FlareEmptyMessage';
import HighchartsRenderer from './HighchartsRenderer';
import { BuildOptionsFunction, FlareChart, FlareProps, FlareSeriesOptions } from './types';
import initializeOptions from './utils/initialize-options';

type ChildrenMapType = Map<string, BuildOptionsFunction>;

const Flare = ({
  style = {},
  className = '',
  callback,
  postRender,
  children,
  data,
  description,
  emptyMessage = <Trans>No Data Available</Trans>,
  subtitle,
  title,
  paddingTop = 0,
  paddingBottom = 0,
  paddingLeft = 0,
  paddingRight = 0,
  marginTop = 0,
  marginBottom = 0,
  marginLeft = 0,
  marginRight = 0,
  height = 'auto',
  width = 'auto',
  defaultOptions,
  parseX = (val) => val,
  parseY = (val) => val,
  colors = VISUALISATION_CHART_COLORS,
  textColors = [COLOR_GREEN_500, COLOR_AQUA_400],
  noPadding = false,
  debug = false,
  isLoading = false,
  error,
  disabledSeriesIds = [],
  'data-testid': dataTestId,
}: PropsWithChildren<FlareProps>) => {
  // Hang on to the last successful response and don't reset the chart when we go to `loading`
  // state and `data == null`. Instead, just display a small spinner over the stale data.
  const stickyDataSource = useRef<FlareSeriesOptions[] | undefined>();
  const stickyOptions = useRef<Options>();

  const chartRef = useRef<HighchartsReact.RefObject>(null);
  const [registeredChildren, setRegisteredChildren] = useState<ChildrenMapType>(new Map());
  const needsAutoSize = width === 'auto' || height === 'auto';

  const registerChild = useCallback(
    (id: string, buildOptions: BuildOptionsFunction) => {
      if (!registeredChildren.has(id)) {
        setRegisteredChildren((prevChildren) => {
          const newChildren = new Map(prevChildren);
          newChildren.set(id, buildOptions);
          return newChildren;
        });
      }
    },
    [setRegisteredChildren],
  );

  if (data) {
    stickyDataSource.current = data;
  }

  if (error) {
    // reset stickyDataSource when error happens so we can show the message on an empty single row
    stickyDataSource.current = undefined;
  }

  const showSkeletonLoader = isLoading && stickyDataSource.current == null;
  const showSpinnerLoader = isLoading && stickyDataSource.current != null;

  const parentProps = {
    data: stickyDataSource.current,
    colors,
    textColors,
    parseX,
    parseY,
    isLoading: showSkeletonLoader,
    disabledSeriesIds,
  };

  const finalOptions = useMemo(() => {
    if (showSpinnerLoader) {
      // "freeze" the chart options to look like the last successful render
      return stickyOptions.current;
    }

    const initialOptions = initializeOptions(defaultOptions, {
      title,
      subtitle,
      description,
      colors,
      postRender,
    });
    const options: Highcharts.Options = [...registeredChildren.values()].reduce(
      (memo, childBuildOptions) => childBuildOptions(memo, parentProps),
      initialOptions,
    );

    if (debug) {
      // eslint-disable-next-line no-console
      console.log(options);
    }

    stickyOptions.current = options;
    return options;
  }, [registeredChildren, data, stickyDataSource.current, showSpinnerLoader]);

  const isEmpty =
    (stickyDataSource.current == null || stickyDataSource.current.length === 0) &&
    !error &&
    !isLoading &&
    !defaultOptions?.series?.length;

  return (
    <Spin wrapperClassName={styles.spinnerWrapper} spinning={!!showSpinnerLoader && !error}>
      <div
        className={classNames(styles.container, className)}
        style={style}
        data-testid={dataTestId}
      >
        <FlareContextProvider
          value={{
            registerChild,
            parentProps,
            options: finalOptions,
            isLoading: showSkeletonLoader,
            chart: chartRef.current?.chart as FlareChart,
          }}
        >
          {error ? (
            <WidgetError />
          ) : isEmpty ? (
            <FlareEmptyMessage>{emptyMessage}</FlareEmptyMessage>
          ) : (
            children
          )}
          {!showSkeletonLoader && !error && (
            <div
              className={classNames(styles.chartWrapper, {
                [styles.autoSize]: needsAutoSize,
                [styles.noPadding]: noPadding,
              })}
              style={{
                paddingLeft,
                paddingRight,
                paddingTop,
                paddingBottom,
                marginLeft,
                marginRight,
                marginTop,
                marginBottom,
              }}
            >
              {needsAutoSize ? (
                <AutoSizer alwaysRenderChildren>
                  {({ width: measuredWidth, height: measuredHeight }: SizeType) => (
                    <HighchartsRenderer
                      ref={chartRef}
                      callback={callback}
                      options={finalOptions}
                      measuredWidth={width === 'auto' ? measuredWidth : width}
                      measuredHeight={height === 'auto' ? measuredHeight : height}
                      disabledSeriesIds={disabledSeriesIds}
                    />
                  )}
                </AutoSizer>
              ) : (
                <HighchartsRenderer
                  ref={chartRef}
                  callback={callback}
                  options={finalOptions}
                  disabledSeriesIds={disabledSeriesIds}
                />
              )}
            </div>
          )}
        </FlareContextProvider>
      </div>
    </Spin>
  );
};

export default Flare;
