import { Trans } from '@lingui/react/macro';

import Notification from '@/components/Notification';

const TagNotFoundNotification = () => {
  return (
    <Notification type="error" size="large">
      <Trans>
        Uh oh! C99 Tag has not been found on your website. Please place the C99 Tag or make this
        request to your admin.
      </Trans>
    </Notification>
  );
};

export default TagNotFoundNotification;
