import { t } from '@lingui/core/macro';
import classNames from 'classnames';
import { FocusEvent, useEffect, useState } from 'react';

import Check from '@/assets/svg/check.svg?react';
import Clone from '@/assets/svg/clone-regular.svg?react';
import Tooltip from '@/components/Tooltip';
import { ActionButton } from '@/components/buttons';

import { TextInput, TextInputProps } from '../TextInput';
import styles from './CopyToClipboardTextInput.module.scss';

export type CopyToClipboardTextInputProps = Omit<TextInputProps, 'error'>;

const CopyToClipboardTextInput = ({
  className,
  isDisabled,
  size = 'large',
  value,
  onFocus,
  ...rest
}: CopyToClipboardTextInputProps) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (copied) {
      setTimeout(() => {
        setCopied(false);
      }, 3000);
    }
  }, [copied]);

  const handleFocus = (e: FocusEvent<HTMLInputElement>) => {
    // Select the text so it can be easily copied
    e.target.select();

    if (onFocus) {
      onFocus(e);
    }
  };

  const handleCopy = () => {
    if (value) {
      setCopied(true);
      navigator.clipboard.writeText(value + '');
    }
  };

  return (
    <div className={styles.wrapper} data-testid="clipboard-copy-input">
      <TextInput
        {...rest}
        className={classNames(styles.input, className)}
        value={value}
        size={size}
        isDisabled={isDisabled}
        onFocus={handleFocus}
        readOnly
      />
      <Tooltip open={copied ? copied : undefined} body={copied ? t`Copied!` : t`Copy to clipboard`}>
        <ActionButton
          className={styles.button}
          color="black"
          size={size}
          aria-label={t`Copy text to clipboard`}
          icon={copied ? <Check /> : <Clone />}
          isDisabled={isDisabled}
          onClick={handleCopy}
        />
      </Tooltip>
    </div>
  );
};

export default CopyToClipboardTextInput;
