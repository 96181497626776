import { t } from '@lingui/core/macro';

import AnalyticsMenuIcon from '@/assets/svg/menu-analytics-icon.svg?react';
import AudiencesMenuIcon from '@/assets/svg/menu-audiences-icon.svg?react';
import CampaignMenuIcon from '@/assets/svg/menu-campaigns-icon.svg?react';
import ChannelsMenuIcon from '@/assets/svg/menu-channels-icon.svg?react';
import DashboardMenuIcon from '@/assets/svg/menu-dashboard-icon.svg?react';
import PixelsMenuIcon from '@/assets/svg/menu-pixels-icon.svg?react';
import SettingsMenuIcon from '@/assets/svg/menu-settings-icon.svg?react';
import VendorsMenuIcon from '@/assets/svg/menu-vendors-icon.svg?react';
import { ROUTES } from '@/router';

import { MenuItemTypeCallback } from './types';

export const getTopMenuItems: MenuItemTypeCallback = () => [
  {
    type: 'item',
    key: ROUTES.dashboard.path,
    icon: <DashboardMenuIcon />,
    label: t`Dashboard`,
  },
  {
    type: 'submenu',
    key: 'analytics',
    icon: <AnalyticsMenuIcon />,
    label: t`Analytics`,
    children: [
      {
        type: 'item',
        key: ROUTES.webTraffic.path,
        label: t`Web Traffic`,
      },
      {
        type: 'item',
        key: ROUTES.webTrafficActivity.path,
        label: t`Activity`,
      },
      {
        type: 'item',
        key: ROUTES.pipeline.path,
        label: t`Pipeline`,
      },
      {
        type: 'item',
        key: ROUTES.closedWon.path,
        label: t`Closed Won`,
      },
      {
        type: 'item',
        key: ROUTES.webTrafficBenchmarks.path,
        label: t`Benchmarks`,
      },
    ],
  },
  {
    type: 'item',
    key: ROUTES.channels.path,
    icon: <ChannelsMenuIcon />,
    label: t`Channels`,
  },
  {
    type: 'item',
    key: ROUTES.vendors.path,
    icon: <VendorsMenuIcon />,
    label: t`Vendors`,
  },
  {
    type: 'item',
    key: ROUTES.campaigns.path,
    icon: <CampaignMenuIcon />,
    label: t`Campaigns`,
  },
  {
    type: 'item',
    key: ROUTES.pixels.path,
    icon: <PixelsMenuIcon />,
    label: t`Pixels`,
    isPaid: true,
  },
  {
    type: 'item',
    key: ROUTES.audiences.path,
    icon: <AudiencesMenuIcon />,
    label: t`Audiences`,
    isPaid: true,
  },
];

export const getBottomMenuItems: MenuItemTypeCallback = () => [
  {
    type: 'submenu',
    key: 'settings',
    icon: <SettingsMenuIcon />,
    label: t`Settings`,
    children: [
      {
        type: 'item',
        key: ROUTES.healthCenter.path,
        label: t`Health Center`,
      },
      {
        type: 'item',
        key: ROUTES.configureVendors.path,
        label: t`Vendor Spend`,
        isAdmin: true,
      },
      {
        type: 'item',
        key: ROUTES.configureChannels.path,
        label: t`Channel Mapping Rules`,
        isAdmin: true,
      },
      {
        type: 'item',
        key: ROUTES.integrations.path,
        label: t`Integrations`,
        isAdmin: true,
      },
      {
        type: 'item',
        key: ROUTES.companySettings.path,
        label: t`Your Company`,
        isAdmin: true,
      },
      {
        type: 'item',
        key: ROUTES.users.path,
        label: t`Users & Roles`,
        isAdmin: true,
      },
      {
        type: 'item',
        key: ROUTES.eventLog.path,
        label: t`Event Log`,
        isAdmin: true,
      },
    ],
  },
];
