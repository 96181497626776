import { Trans } from '@lingui/react/macro';

import { Button } from '@/components/buttons';
import { Link, Text } from '@/components/typography';
import { ROUTES } from '@/router';

import styles from './NotAvailable.module.scss';
import { WidgetNotAvailableType } from './types';

const NotAvailable = ({ cta, docsLink }: WidgetNotAvailableType) => (
  <div className={styles.container}>
    <div className={styles.content}>
      <Text variant="label" color="white">
        {cta}
      </Text>
      <Button color="green" variant="primary" to={ROUTES.pixels.path}>
        <Trans>Take Me There</Trans>
      </Button>
      {docsLink && (
        <Text variant="label" color="white">
          <Trans>or read about it</Trans>{' '}
          <Link color="static-white" to={docsLink}>
            <Trans>here</Trans>
          </Link>
        </Text>
      )}
    </div>
  </div>
);

export default NotAvailable;
