import { t } from '@lingui/core/macro';
import { SortOrder } from 'antd/es/table/interface';
import classNames from 'classnames';
import { Key, ReactNode, useState } from 'react';

import FilterAppliedIcon from '@/assets/svg/bx-filter-alt.svg?react';
import FilterIcon from '@/assets/svg/bx-filter.svg?react';
import SortDownIcon from '@/assets/svg/bx-sort-down.svg?react';
import SortUpIcon from '@/assets/svg/bx-sort-up.svg?react';
import { FilterOperatorValue } from '@/components/InstantSearch';
import Popover from '@/components/Popover';

import styles from './ColumnTitle.module.scss';
import { AlignType, FilterDropdownProps } from './types';

type Props = {
  dataKey?: Key;
  title: string;
  align?: AlignType;
  isSortable: boolean;
  isSorted: boolean;
  isFilterable: boolean;
  sortDirection?: SortOrder;
  filterDropdown: ReactNode | ((props: FilterDropdownProps) => ReactNode);
  filteredValue?: FilterOperatorValue | null;
  onChange?: (filter: FilterOperatorValue, sort: SortOrder) => void;
};

const ColumnTitle = ({
  dataKey,
  title,
  align = 'start',
  isSortable,
  isSorted,
  isFilterable,
  filteredValue,
  sortDirection,
  filterDropdown,
  onChange,
}: Props) => {
  const [isPopoverOpen, setPopoverOpen] = useState(false);
  const [filterValue, setFilterValue] = useState<FilterOperatorValue | undefined>();
  const [sortValue, setSortValue] = useState<SortOrder | undefined>();

  const actualSortDirection = sortValue ?? sortDirection;
  const actualFilterValue = filterValue ?? filteredValue;
  const isFiltered =
    filteredValue != null && (!Array.isArray(filteredValue) || filteredValue.length > 0);

  const handleDropdownClose = () => {
    setPopoverOpen(false);

    // Wait for the modal to close before resetting the form
    setTimeout(() => {
      setSortValue(undefined);
      setFilterValue(undefined);
    }, 300);
  };

  const dropdownContent =
    typeof filterDropdown !== 'function'
      ? filterDropdown
      : filterDropdown({
          isSortable,
          filter: actualFilterValue,
          sortDirection: actualSortDirection,
          visible: isPopoverOpen,
          onClose: () => {
            handleDropdownClose();
          },
          onConfirm: ({ closeDropdown } = { closeDropdown: true }) => {
            if (closeDropdown) {
              handleDropdownClose();
            }

            const filter = filterValue !== undefined ? filterValue : filteredValue;
            onChange?.(filter ?? null, sortValue ?? sortDirection ?? null);
          },
          onFilterChange: (selectedKeys) => {
            setFilterValue(selectedKeys ? selectedKeys : null);
          },
          onSortChange: setSortValue,
        });

  return (
    <Popover
      className={styles.popover}
      arrow={false}
      content={() => dropdownContent}
      open={isPopoverOpen}
      trigger={['click']}
      placement="bottomRight"
      noPadding
      destroyTooltipOnHide
      onOpenChange={(visible) => {
        if (!visible) {
          handleDropdownClose();
        } else {
          setPopoverOpen(true);
        }
      }}
    >
      <div
        className={classNames(styles.title, {
          [styles.clickable]: isSortable || isFilterable,
          [styles.popoverOpen]: isPopoverOpen,
        })}
        style={{
          justifyContent: align,
        }}
        onClick={() => {
          if (!isFilterable && isSortable) {
            // if we don't have any filters, clicking the header will sort
            onChange?.(filterValue, sortDirection === 'ascend' ? 'descend' : 'ascend');
          }
        }}
        data-testid={`${dataKey}-${
          isFilterable ? 'filter' : isSortable ? sortDirection || 'null' : 'none'
        }`}
      >
        <div className={styles.titleLabel}>{title}</div>
        {isSortable && !isFilterable && (
          <div
            className={classNames(styles.columnHeaderIcon, {
              [styles.active]: isSorted,
              [styles.hidden]: !isSortable,
            })}
          >
            {sortDirection === 'ascend' ? (
              <SortUpIcon aria-label={t`sort ascending`} />
            ) : (
              <SortDownIcon aria-label={t`sort descending`} />
            )}
          </div>
        )}
        {isFilterable && (
          <div
            className={classNames(styles.columnHeaderIcon, {
              [styles.active]: isFiltered,
            })}
            data-testid={`${dataKey}-filter`}
          >
            {isFiltered || isSorted ? (
              <FilterAppliedIcon aria-label={t`filter applied`} width="16" height="16" />
            ) : (
              <FilterIcon aria-label={t`filter not applied`} width="16" height="16" />
            )}
          </div>
        )}
      </div>
    </Popover>
  );
};

export default ColumnTitle;
